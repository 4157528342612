import React, { useContext, useState } from "react";
import {
    Popup,
    PopupTitle,
    PopupContent,
    PopupFooter,
    Button,
    Input,
    Toast
} from "@tv2no/ui-react";
import { PortalLayoutContext } from "../Layout";
import { slugify } from "../../../../../../utils/stringUtils";

export const NewPanePrompt = () => {
    const { layout, updateLayout, setShowPrompt, setActivePane } = useContext(PortalLayoutContext);
    const [label, setLabel] = useState(null);
    const [busyCreating, setBusyCreating] = useState(false);

    const handleAddPane = async () => {
        const paneKey = slugify(label);
        const existing = layout.panes.find((pane) => pane.key === paneKey);
        if (existing) {
            return Toast.error("En fane med dette navnet finnes allerede");
        }
        setBusyCreating(true);
        await updateLayout({
            panes: [
                ...layout.panes,
                {
                    key: paneKey,
                    label
                }
            ]
        });
        setActivePane(paneKey);
        setShowPrompt(null);
    };

    return (
        <Popup onClose={() => setShowPrompt(null)}>
            <PopupTitle>Legg til fane</PopupTitle>
            <PopupContent>
                <Input
                    value={label}
                    onChange={setLabel}
                    placeholder="Navnet på fanen"
                    maxLength={20}
                />
            </PopupContent>
            <PopupFooter>
                <Button
                    onClick={handleAddPane}
                    busy={busyCreating}
                    disabled={!label || label?.length < 3}
                >
                    Opprett fane
                </Button>
            </PopupFooter>
        </Popup>
    );
};
