import { Card, CardContent, Row, Col, Text, Icon, Button, Paragraph } from "@tv2no/ui-react";
import React, { useState } from "react";

export const GetStarted = ({ createLayout }) => {
    const [busyCreating, setBusyCreating] = useState(false);

    return (
        <Card>
            <CardContent>
                <Row>
                    <Col md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }}>
                        <Row>
                            <Col span={24} align={["center"]}>
                                <Icon name="structure" color="primary" size="xxLarge" />
                            </Col>
                            <Col span={24} align={["center"]}>
                                <Text size="h3" weight="bold">
                                    Utform en layout
                                </Text>
                            </Col>
                            <Col span={24} align={["center"]}>
                                <Paragraph align="center">
                                    <Text size="medium">
                                        En egendefinert layout gir deg muligheten til å legge inn
                                        valgfrie moduler i portalen og bestemme plasseringen på
                                        disse. Trykk på knappen under for å komme i gang.
                                    </Text>
                                </Paragraph>
                            </Col>
                            <Col span={24} align={["center"]}>
                                <Button
                                    busyCreating={busyCreating}
                                    iconColor="success"
                                    onClick={() => setBusyCreating(true) & createLayout()}
                                >
                                    Lag en layout
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};
