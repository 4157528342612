import { IframeContent } from "../../post_content/IframeContent";
import { DatawrapperContent } from "../../post_content/DatawrapperContent";
import { FacebookContent } from "../../post_content/FacebookContent";
import { InstagramContent } from "../../post_content/InstagramContent";
import { LabradorContent } from "../../post_content/LabradorContent";
import { MarkupContent } from "../../post_content/MarkupContent";
import { PicturesContent } from "../../post_content/PicturesContent";
import { TwitterContent } from "../../post_content/TwitterContent";
import { VideoContent } from "../../post_content/VideoContent";
import { BitsModuleContent } from "../../post_content/BitsModuleContent";

export const POST_CONTENT_TYPES = {
    MARKUP: {
        label: "Tekst",
        icon: "underline",
        component: MarkupContent
    },
    PICTURES: {
        label: "Bilde",
        icon: "image",
        component: PicturesContent,
        editable: true
    },
    VIDEO: {
        label: "Video",
        icon: "video",
        component: VideoContent
    },
    BITS: {
        label: "Bits-modul",
        icon: "menu-squares",
        component: BitsModuleContent
    },
    TWITTER: {
        label: "Twitter",
        icon: "twitter",
        component: TwitterContent
    },
    INSTAGRAM: {
        label: "Instagram",
        icon: "news",
        component: InstagramContent
    },
    FACEBOOK: {
        label: "Facebook",
        icon: "facebook",
        component: FacebookContent
    },
    DATAWRAPPER: {
        label: "Datawrapper",
        icon: "menu-dots",
        component: DatawrapperContent
    },
    LABRADOR: {
        label: "Labrador-henvisning",
        icon: "labrador",
        component: LabradorContent,
        editable: true
    },
    IFRAME: {
        label: "HTML (iframe)",
        icon: "code",
        component: IframeContent,
        editable: true
    }
};
