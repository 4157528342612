import React, { useState } from "react";
import {
    Popup,
    PopupTitle,
    PopupContent,
    PopupFooter,
    Row,
    Col,
    SingleDatePicker,
    TimePicker,
    Button,
    ButtonRow,
    Callout
} from "@tv2no/ui-react";

export const PromptPublish = ({ onClose, onPublish }) => {
    const [publishDate, setPublishDate] = useState(null);
    return (
        <Popup onClose={onClose}>
            <PopupTitle description="Velg publiseringstidspunkt">Publiser meldingen</PopupTitle>
            <PopupContent>
                <Row gutter={["small", "small"]}>
                    <Col span={24}>
                        <Callout color="info">
                            Angi dato og klokkeslett hvis du ønsker å publisere meldingen på et
                            senere tidspunkt:
                        </Callout>
                    </Col>
                    <Col span={12}>
                        <SingleDatePicker
                            placeholder="Dato"
                            value={publishDate}
                            onChange={(val) => setPublishDate(val ? new Date(val) : null)}
                        />
                    </Col>
                    <Col span={12}>
                        <TimePicker
                            placeholder="Klokkeslett"
                            value={publishDate}
                            onChange={setPublishDate}
                        />
                    </Col>
                </Row>
            </PopupContent>
            <PopupFooter>
                <ButtonRow>
                    <Button color="primary" onClick={() => onPublish(publishDate)}>
                        Publiser {publishDate ? "på dato" : "nå"}
                    </Button>
                    <Button onClick={onClose}>Avbryt</Button>
                </ButtonRow>
            </PopupFooter>
        </Popup>
    );
};
