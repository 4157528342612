import { createContext, useEffect, useState } from "react";
import { getPortalList } from "../data/portal/portal";
import { getFeedList } from "../data/feed/feed";

export const GlobalAppContext = createContext({});

export const GlobalAppContextProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState(null);
    const [portalList, setPortalList] = useState(null);
    const [feedList, setFeedList] = useState(null);

    const fetchPortals = () => {
        getPortalList({ page: 1, limit: 10, archived: false }).then((response) => {
            setPortalList((list) => {
                if (response.page > 1) {
                    response.docs = list.docs.concat(response.docs);
                }
                return response;
            });
        });
    };

    useEffect(fetchPortals, []);

    const fetchFeeds = () => {
        getFeedList({ page: 1, limit: 100 }).then((response) => {
            setFeedList((list) => {
                if (response.page > 1) {
                    response.docs = list.docs.concat(response.docs);
                }
                return response;
            });
        });
    };

    useEffect(fetchFeeds, []);

    return (
        <GlobalAppContext.Provider
            value={{
                pageTitle,
                setPageTitle,
                portalList,
                reloadPortals: fetchPortals,
                feedList,
                reloadFeeds: fetchFeeds
            }}
        >
            {children}
        </GlobalAppContext.Provider>
    );
};
