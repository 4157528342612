import { Col, LoaderLine, Row, Spinner } from "@tv2no/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { createLayout, getLayout, updateLayout } from "../../../../../data/portal/layout";
import { PortalEntryContext } from "../../PortalEntry";
import { GetStarted } from "./parts/GetStarted";
import { LayoutControls } from "./parts/LayoutControls";
import { LayoutEditor } from "./parts/LayoutEditor";
import { EditPanePrompt } from "./prompts/EditPanePrompt";
import { MoveModulePrompt } from "./prompts/MoveModulePrompt";
import { NewPanePrompt } from "./prompts/NewPanePrompt";

export const PortalLayoutContext = React.createContext();
export const LAYOUT_PROMPTS = {
    newPane: "NEW_PANE",
    editPane: "EDIT_PANE",
    moveModule: "MOVE_MODULE"
};

export const Layout = () => {
    const { portal } = useContext(PortalEntryContext);
    const [layout, setLayout] = useState(null);
    const [viewDevice, setViewDevice] = useState("desktop");
    const [activePane, setActivePane] = useState("main");
    const [showPrompt, setShowPrompt] = useState({ type: null, data: null });
    const [busyLoading, setBusyLoading] = useState(false);
    const [busyUpdating, setBusyUpdating] = useState(false);

    useEffect(() => {
        setBusyLoading(true);
        getLayout(portal.id)
            .then((response) => {
                setLayout(response);
            })
            .finally(() => {
                setBusyLoading(false);
            });
    }, [portal.id]);

    const handleUpdateLayout = async (changes) => {
        setBusyUpdating(true);
        await updateLayout(portal.id, { ...layout, ...changes })
            .then((response) => {
                setLayout(response);
            })
            .finally(() => {
                setBusyUpdating(false);
            });
    };

    return busyLoading ? (
        <Spinner />
    ) : layout ? (
        <PortalLayoutContext.Provider
            value={{
                layout,
                updateLayout: handleUpdateLayout,
                activePane,
                setActivePane,
                viewDevice,
                setViewDevice,
                showPrompt,
                setShowPrompt
            }}
        >
            {/* LOADING */}
            {busyUpdating && (
                <div style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 10000 }}>
                    <LoaderLine />
                </div>
            )}

            {/* CONTROLS / EDITOR */}
            <Row gutter={["large", "large"]}>
                <Col span={24}>
                    <LayoutControls portal={portal} />
                </Col>
                <Col span={24}>
                    <LayoutEditor portal={portal} />
                </Col>
            </Row>

            {/* PROMPTS */}
            {showPrompt?.type === LAYOUT_PROMPTS.newPane && <NewPanePrompt />}
            {showPrompt?.type === LAYOUT_PROMPTS.editPane && <EditPanePrompt />}
            {showPrompt?.type === LAYOUT_PROMPTS.moveModule && <MoveModulePrompt />}
        </PortalLayoutContext.Provider>
    ) : (
        <GetStarted createLayout={() => createLayout(portal.id).then(setLayout)} />
    );
};
