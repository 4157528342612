import React from "react";
import { createRoot } from "react-dom/client";
import config from "./config";
import { BrowserRouter as Router } from "react-router-dom";
import Root from "./Root";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Router basename={config.basePath}>
        <Root />
    </Router>
);
