export const getNextOrderCount = ({ modules, viewDevice, pane, column }) => {
    const orderCount =
        modules.reduce((max, e) => {
            return e[viewDevice]?.order > max &&
                ((pane && e[viewDevice]?.pane === pane) ||
                    (column && e[viewDevice]?.column === column))
                ? e[viewDevice]?.order
                : max;
        }, 0) || 0;
    return orderCount + 1;
};

export const assignNewOrderCount = ({ modules, viewDevice, pane, column }) => {
    let counter = 0;
    return modules
        .sort((a, b) => sortModuleList(viewDevice, a, b))
        .map((module) => {
            if (
                (pane && module[viewDevice].pane === pane) ||
                (column && module[viewDevice].column === column)
            ) {
                counter++;
                return {
                    ...module,
                    [viewDevice]: {
                        ...module[viewDevice],
                        order: counter
                    }
                };
            } else {
                return module;
            }
        });
};

export const moveModule = ({ modules, module, viewDevice, toPane, toColumn }) => {
    const changedModule = {
        ...module,
        [viewDevice]: {
            ...module[viewDevice]
        }
    };

    // Column / pane
    if (toColumn) {
        changedModule[viewDevice].column = toColumn;
        delete changedModule[viewDevice].pane;
    } else if (toPane) {
        changedModule[viewDevice].pane = toPane;
        delete changedModule[viewDevice].column;
    }

    // Order
    changedModule[viewDevice].order = getNextOrderCount({
        modules,
        viewDevice,
        ...(toColumn ? { column: toColumn } : { pane: toPane })
    });

    // Replace changed module
    let changedModuleList = modules.map((m) => {
        if (m._id === changedModule._id) {
            return changedModule;
        } else {
            return m;
        }
    });

    // Update order in previous column / pane
    changedModuleList = assignNewOrderCount({
        modules: changedModuleList,
        viewDevice,
        ...(module[viewDevice].column
            ? { column: module[viewDevice].column }
            : { pane: module[viewDevice].pane })
    });

    return changedModuleList;
};

export const sortModuleList = (viewDevice, a, b) => {
    const aPane = a[viewDevice]?.pane;
    const bPane = b[viewDevice]?.pane;
    if (aPane === bPane) {
        return a[viewDevice]?.order - b[viewDevice]?.order;
    } else {
        return 0;
    }
};
