import {
    Col,
    Image,
    InfiniteScroll,
    Input,
    ItemList,
    Popup,
    PopupContent,
    PopupTitle,
    Row,
    Spinner,
    Text
} from "@tv2no/ui-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getLabradorArticles } from "../../../../data/external/labrador";
import useDebounce from "../../../../hooks/useDebounce";

export const LabradorSearchPopup = ({ entry, onSelect, onClose }) => {
    const { portalId } = useParams();
    const [articleList, setArticleList] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [page, setPage] = useState(1);
    const [busyLoading, setBusyLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);

    useDebounce(
        () => {
            setBusyLoading(true);
            const articleId =
                !isNaN(searchTerm) && searchTerm?.length > 5 ? parseInt(searchTerm, 10) : null;
            getLabradorArticles({
                page: page,
                portal: portalId,
                ...(articleId ? { id: articleId } : { search: searchTerm })
            })
                .then((res) => {
                    setArticleList(page === 1 || articleId ? res : [...articleList, ...res]);
                    setBusyLoading(false);
                    setHasMore(!articleId);
                })
                .catch(() => {
                    setHasMore(false);
                    setBusyLoading(false);
                });
        },

        [searchTerm, page],
        { delay: 500 }
    );

    return (
        <Popup size="medium" onClose={onClose}>
            <PopupTitle>Labrador artikler</PopupTitle>
            <PopupContent>
                <Row spacing={"large"}>
                    <Col span={12}>
                        <Input
                            value={searchTerm}
                            placeholder="Søkeord eller artikkel ID"
                            onChange={(val) =>
                                setArticleList(null) & setPage(1) & setSearchTerm(val)
                            }
                        />
                    </Col>
                </Row>
                <ItemList
                    onItemClick={(article) =>
                        onSelect({
                            url: article.url,
                            id: article.id,
                            title: article.title,
                            image: article?.labrador_image?.url || null
                        })
                    }
                >
                    {articleList &&
                        articleList.map((article, i) => (
                            <ItemList.Item
                                key={i}
                                index={i}
                                value={article}
                                prepend={
                                    <Image
                                        thumbnail={"medium"}
                                        scale="16:9"
                                        src={article.labrador_image?.url}
                                    />
                                }
                            >
                                <Text block size="medium">
                                    {article.title}
                                </Text>
                                <Text block opacity={50} size="small" truncate>
                                    {article.subtitle}
                                </Text>
                            </ItemList.Item>
                        ))}
                </ItemList>
                {busyLoading ? (
                    <Spinner />
                ) : hasMore ? (
                    <InfiniteScroll onLoadMore={() => setPage(page + 1)} />
                ) : null}
            </PopupContent>
        </Popup>
    );
};
