import { AppNav, Text } from "@tv2no/ui-react";
import React, { useContext } from "react";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";

export const PortalMenu = () => {
    const { portalList } = useContext(GlobalAppContext);

    return (
        <AppNav.Menu>
            {portalList?.docs?.map((portal, i) => (
                <AppNav.MenuItem key={i} link={`/portals/${portal.id}`}>
                    {portal.name}
                </AppNav.MenuItem>
            ))}
            <AppNav.MenuItem icon="plus-small" link="/portals?promptNew=true">
                <Text weight="light">Opprett ny portal</Text>
            </AppNav.MenuItem>
        </AppNav.Menu>
    );
};
