import {
    Button,
    Card,
    CardContent,
    CardTitle,
    Col,
    OnOffSwitch,
    Row,
    Text,
    Toast
} from "@tv2no/ui-react";
import React, { useEffect, useState } from "react";
import { updatePortal } from "../../../../data/portal/portal";

export const Visibility = ({ onUpdatePortal, ...props }) => {
    const [portal, setPortal] = useState(props.portal);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [busySaving, setBusySaving] = useState(false);

    useEffect(() => {
        setPortal(props.portal);
        setUnsavedChanges(false);
    }, [props.portal]);

    const handleChangeProperty = (key, value) => {
        setUnsavedChanges(true);
        setPortal({
            ...portal,
            [key]: value
        });
    };

    const handleSave = async () => {
        setBusySaving(true);
        updatePortal(portal.id, {
            name: portal.name,
            category: portal.category,
            slug: portal.slug,
            description: portal.description,
            archived: portal.archived,
            hidden: portal.hidden,
            theme: portal.theme,
            adUnitPath: portal.adUnitPath,
            baseUrl: portal.baseUrl
        })
            .then((response) => {
                onUpdatePortal(response);
                setUnsavedChanges(false);
                setBusySaving(false);
                Toast.success("Portalen ble lagret");
            })
            .catch((err) => {
                setBusySaving(false);
                Toast.error(err);
            });
    };

    return (
        <Card>
            <CardTitle title="Synlighet" />
            <CardContent>
                <Row gutter={["tiny", "tiny"]}>
                    <Col md={12} align={["left", "top"]}>
                        <OnOffSwitch
                            value={portal.archived}
                            onChange={(val) => handleChangeProperty("archived", val)}
                        >
                            <Text weight="medium" block>
                                Arkivert på Direkte-front
                            </Text>
                        </OnOffSwitch>
                    </Col>
                    <Col md={12}>
                        Når denne bryteren er på, vil portalen flyttes til seksjonen som heter
                        "Arkiv" på Direkte-fronten. Dette gjøres med portaler som det ikke lenger
                        skrives nye meldinger til.
                    </Col>
                </Row>
            </CardContent>
            <CardContent border="top">
                <Row gutter={["tiny", "tiny"]}>
                    <Col md={12} align={["left", "top"]}>
                        <OnOffSwitch
                            value={portal.hidden}
                            onChange={(val) => handleChangeProperty("hidden", val)}
                        >
                            <Text weight="medium">Skjult på Direkte-front</Text>
                        </OnOffSwitch>
                    </Col>
                    <Col md={12}>
                        Når portalen skjules fra Direkte-fronten vil den i utgangspunktet ikke bli
                        mulig å finne for brukere eller av Google. Dette valget brukes f.eks. på
                        portaler man ønsker å klargjøre før den skal synliggjøres for
                        offentligheten.
                    </Col>
                </Row>
            </CardContent>
            <CardContent border="top">
                <Button
                    color="primary"
                    disabled={!unsavedChanges}
                    onClick={() => handleSave(setBusySaving)}
                    busy={busySaving}
                >
                    Lagre endringene
                </Button>
            </CardContent>
        </Card>
    );
};
