import {
    Button,
    Popup,
    PopupContent,
    PopupFooter,
    PopupTitle,
    Form,
    FormItem,
    Select,
    Option
} from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import { PortalLayoutContext } from "../Layout";
import { moveModule } from "../helpers";

export const MoveModulePrompt = () => {
    const { layout, updateLayout, viewDevice, showPrompt, setShowPrompt } =
        useContext(PortalLayoutContext);
    const [selectedPane, setSelectedPane] = useState(showPrompt.data?.[viewDevice]?.pane);

    const handleMoveModule = () => {
        const module = showPrompt.data;
        updateLayout({
            modules: moveModule({
                modules: layout.modules,
                module,
                viewDevice,
                toPane: selectedPane
            })
        });
        setShowPrompt(null);
    };

    return (
        <Popup onClose={() => setShowPrompt(null)}>
            <PopupTitle>Flytt til fane</PopupTitle>
            <PopupContent>
                <Form>
                    <FormItem label="Velg fane">
                        <Select
                            value={selectedPane}
                            onChange={setSelectedPane}
                            placeholder="Velg en fane"
                        >
                            {layout.panes.map((pane) => (
                                <Option key={pane.key} value={pane.key}>
                                    {pane.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </Form>
            </PopupContent>
            <PopupFooter>
                <Button
                    onClick={handleMoveModule}
                    disabled={!selectedPane || showPrompt.data?.[viewDevice]?.pane === selectedPane}
                >
                    Flytt til fane
                </Button>
            </PopupFooter>
        </Popup>
    );
};
