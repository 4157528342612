import React, { createContext, useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    createLabradorArticleFromPost,
    createPost,
    deletePost,
    getPostById,
    publishPost,
    unpublishPost,
    updatePost
} from "../../data/post/post";
import { updatePortalStatus, deletePortalStatus } from "../../data/portal/portal";
import { Toast, AuthContext } from "@tv2no/ui-react";
import { randomString } from "../../utils/stringUtils";
import useStateRef from "../../hooks/useStateRef";
import { addMarkupContentIfNotExist, replacePostContentEntry, createNewDraft } from "./helpers";
import { usePrompt } from "../../hooks/useBlockerAndPrompt";
import { UploadContext } from "../UploadContext";
import { PostListContext } from "../PostListContext";

export const PostEditContext = createContext({});

export const PostEditContextProvider = ({ children }) => {
    const auth = useContext(AuthContext);
    const { createOrUpdateFileData } = useContext(UploadContext);
    const listContext = useContext(PostListContext);
    const updatePortalStatusTimeoutRef = useRef();
    const { portalId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [query, setQuery] = useState({
        postId: null
    });
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [contentErrors, setContentErrors] = useState({});
    const [post, setPost, postRef] = useStateRef(null);

    usePrompt(
        "Du har endringer i denne meldingen som ikke er lagret. Er du sikker på at du vil gå videre uten å lagre endringene?",
        post !== null && !!post._unsaved
    );

    useEffect(() => {
        let paramsMap = {};
        for (let p of new URLSearchParams(location.search)) {
            paramsMap[p[0]] = p[1];
        }
        setQuery(paramsMap);
    }, [location.search]);

    useEffect(() => {
        if (query.postId) {
            handleLoadPost();
        } else {
            setError(null);
            setContentErrors({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.postId]);

    useEffect(() => {
        if (!query.postId && portalId) {
            setPost(createNewDraft({ user: auth.data }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portalId, query.postId]);

    const handleLoadPost = async (callback) => {
        setError(null);
        setContentErrors({});
        if (query.postId !== post.id) {
            setLoading(true);
        }
        await getPostById(query.postId)
            .then((response) => {
                setPost({ ...response, content: addMarkupContentIfNotExist(response.content) });
                setLoading(false);

                // Reload post list if necessary
                if (
                    (listContext.query.published && !response.publishedAt) ||
                    (!listContext.query.published && response.publishedAt)
                ) {
                    listContext.loadPostList({
                        page: 1,
                        published: !!response.publishedAt
                    });
                }
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
                Toast.error("Finner ikke meldingen");
            })
            .finally(() => {
                callback && callback();
            });
    };

    const handleChangePost = (changes) => {
        setPost({
            _unsaved: true,
            ...postRef.current,
            ...changes
        });
        // Portal status (visual cue)
        clearTimeout(updatePortalStatusTimeoutRef.current);
        if (post?._unsaved && !post?.publishedAt) {
            updatePortalStatusTimeoutRef.current = setTimeout(() => {
                updatePortalStatus(portalId);
            }, 2000);
        }
    };

    const handleAddContent = (type, values) => {
        const _tempId = randomString(10);
        handleChangePost({
            content: [
                ...postRef.current.content,
                {
                    _tempId,
                    type,
                    ...(values && { ...values, _changed: true })
                }
            ]
        });
        return _tempId;
    };

    const handleResetPost = () => {
        setPost(createNewDraft({ user: auth.data }));
    };

    const handleCreatePost = async ({ publish } = {}) => {
        setSaving(true);

        const filteredEditorContent = filterEditorContent(post.content);

        const mappedContent = filteredEditorContent.map((entry) => ({
            type: entry.type,
            data: entry.data
        }));

        const dataObject = {
            portalIds: Array.from(new Set([portalId, ...post.portals.map((p) => p.id)])),
            publish: publish || false,
            byline: post.byline,
            title: post.title,
            flags: post.flags?.filter((f) => f !== "pinned"), // TODO: Remove check after a while
            topics: post.topics,
            pinned: post.pinned,
            short: post.short,
            content: mappedContent
        };

        // Create post
        let savedPost = null;
        try {
            savedPost = await createPost(dataObject);
        } catch (e) {
            Toast.error(e?.message || "Feil ved lagring av meldingen");
            return setSaving(false);
        }

        if (publish && !savedPost.publishedAt) {
            await publishPost(savedPost.id);
        }

        await uploadRequiredFiles(savedPost);

        if (publish && !post.publishedAt) {
            Toast.success("Meldingen ble publisert");
            deletePortalStatus(portalId);
        } else {
            Toast.info(`Meldingen ble ${post.id ? "lagret" : "opprettet"}`);
        }

        await reloadPostView(post.id, savedPost.id);

        setSaving(false);
    };

    const handleUpdatePost = async ({ publish }) => {
        setSaving(true);

        const filteredEditorContent = filterEditorContent(post.content);

        const mappedContent = filteredEditorContent.map((entry) => ({
            type: entry.type,
            data: entry.data,
            id: entry.id,
            deleted: entry._deleted,
            changed: entry._changed
        }));

        const dataObject = {
            portalIds: Array.from(new Set([portalId, ...post.portals.map((p) => p.id)])),
            byline: post.byline,
            title: post.title,
            flags: post.flags?.filter((f) => f !== "pinned"), // TODO: Remove check after a while
            topics: post.topics,
            pinned: post.pinned,
            short: post.short,
            content: mappedContent
        };

        let savedPost = null;
        try {
            savedPost = await updatePost(post.id, dataObject);
        } catch (e) {
            Toast.error(e?.message || "Feil ved lagring av meldingen");
            return setSaving(false);
        }

        if (publish && !savedPost.publishedAt) {
            await publishPost(savedPost.id);
        }

        await uploadRequiredFiles(savedPost);

        if (publish && !post.publishedAt) {
            Toast.success("Meldingen ble publisert");
            deletePortalStatus(portalId);
        } else {
            Toast.info(`Meldingen ble lagret`);
        }

        await reloadPostView(post.id, savedPost.id);

        setSaving(false);
    };

    const reloadPostView = async (postId = "", savedPostId = "") => {
        if (postId) {
            await handleLoadPost();
        } else {
            handleResetPost();
            setTimeout(() => {
                updateQueryParam("postId", savedPostId);
            }, 500);
        }
    };

    const uploadRequiredFiles = async (savedPost) => {
        const filteredEditorContent = filterEditorContent(post.content);

        // Loop through all filtered content entries from the editor
        for await (const entry of filteredEditorContent) {
            // Loop through all files in each content entry
            if (!entry.files) continue; // Skip entries without files

            for await (const file of entry.files) {
                if (!file) continue; // Skip any files without data

                // Pick up the entry from the saved post
                const savedEntry = savedPost.content[filteredEditorContent.indexOf(entry)];
                if (!savedEntry) continue; // Skip if entry is not found

                await createOrUpdateFileData({
                    fileData: file,
                    endpoint: `/posts/${savedPost.id}/content/${savedEntry.id}/files`
                });
            }
        }
    };

    const handleDeletePost = async (postId) => {
        setLoading(true);
        await deletePost(postId);
        deleteQueryParam("postId");
        setLoading(false);
        Toast.info("Meldingen ble slettet");
    };

    const handlePublishPost = async (postId, timeStamp) => {
        setSaving(true);
        try {
            const response = await publishPost(postId, timeStamp);
            setPost(response);
            setSaving(false);
            Toast.success("Meldingen ble publisert");
            deletePortalStatus(portalId);
        } catch (err) {
            setError(err);
            setSaving(false);
        }
    };

    const handleUnpublishPost = async (postId) => {
        setSaving(true);
        try {
            const response = await unpublishPost(postId);
            setPost(response);
            setSaving(false);
            Toast.info("Meldingen ble avpublisert");
        } catch (err) {
            setError(err);
            setSaving(false);
        }
    };

    const handleChangeContent = (changedEntry) => {
        handleChangePost({
            content: replacePostContentEntry(post, {
                ...changedEntry,
                _changed: true
            })
        });
    };

    const handleReorderContent = (entry, offset) => {
        // Create a copy of the content array to avoid mutating the original
        const newContent = [...post.content];
        // Find the index of the entry to move
        const fromIndex = newContent.findIndex((e) =>
            e.id ? e.id === entry.id : e._tempId === entry._tempId
        );
        // Find the index to move the entry to
        const toIndex = fromIndex + offset;
        // Remove the entry we are moving from the array
        const [removedElement] = newContent.splice(fromIndex, 1);
        removedElement._changed = true;
        // Insert the entry at the new index
        newContent.splice(toIndex, 0, removedElement);

        handleChangePost({
            content: newContent
        });
    };

    const addContentError = ({ entry, message }) => {
        const id = entry.id || entry._tempId;
        if (!id) return;
        setContentErrors((prev) => ({
            ...prev,
            [id]: message
        }));
    };

    const removeContentError = (entry) => {
        const id = entry.id || entry._tempId;
        if (!id) return;
        setContentErrors(({ [id]: entryId, ...rest }) => rest);
    };

    const handleDeleteContent = (deletedEntry) => {
        const newContent = post.content.filter(
            (entry) => entry.id !== deletedEntry.id || entry._tempId !== deletedEntry._tempId
        );
        handleChangePost({
            content: newContent
        });
        removeContentError(deletedEntry);
    };

    const handleCreateLabradorArticle = async (post, auth) => {
        try {
            const response = await createLabradorArticleFromPost(post.id, {
                byline: {
                    firstname: auth.data.firstName,
                    lastname: auth.data.lastName,
                    public_email: auth.data.email
                }
            });
            setPost(response);
            Toast.success("Labrador-artikkel opprettet!");

            // Open article in window
            if (response?.related?.labrador?.url) {
                window.open(response?.related?.labrador?.url);
            }
        } catch {
            return Toast.error("Opprettelse av artikkel feilet");
        }
    };

    const updateQueryParam = (key, value) => {
        const params = new URLSearchParams(location.search);
        if (!value || !value.trim().length) {
            params.delete(key);
        } else {
            params.set(key, value);
        }
        navigate({ search: params.toString() }, { replace: true });
    };

    const deleteQueryParam = (key) => {
        const params = new URLSearchParams(location.search);
        params.delete(key);
        navigate({ search: params.toString() }, { replace: true });
    };

    const handleUploadFiles = (postId, files = [], contentEntry) => {
        return files.reduce((agg, fileData) => {
            agg.push(
                createOrUpdateFileData({
                    fileData,
                    endpoint: `/posts/${postId}/content/${contentEntry.id}/files`
                })
            );
            return agg;
        }, []);
    };

    const handleSelectPost = (val) => {
        updateQueryParam("postId", val);
    };

    const filterEditorContent = (postContent) => {
        // Filter out deleted entries and placeholder entries
        const filteredContent = postContent.filter(
            (entry) => !entry._deleted && (entry.data || entry.files?.length)
        );
        return filteredContent;
    };

    return (
        <PostEditContext.Provider
            value={{
                loading,
                saving,
                error,
                post,
                setPost,
                addContent: handleAddContent,
                changePost: handleChangePost,
                reloadPost: handleLoadPost,
                resetPost: handleResetPost,
                query,
                contentErrors,
                addContentError,
                removeContentError,

                // Post - actions
                savePost: post?.id ? handleUpdatePost : handleCreatePost,
                deletePost: handleDeletePost,
                publishPost: handlePublishPost,
                unpublishPost: handleUnpublishPost,
                selectPost: handleSelectPost,
                closePost: () => deleteQueryParam("postId"),

                // Post Content - actions
                changeContent: handleChangeContent,
                reorderContent: handleReorderContent,
                deleteContent: handleDeleteContent,
                uploadFiles: handleUploadFiles,

                // Post - Related
                createRelatedLabradorArticle: handleCreateLabradorArticle
            }}
        >
            {children}
        </PostEditContext.Provider>
    );
};
