import { Button, Col, Image, Row } from "@tv2no/ui-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { VideoSearchPopup } from "../../portal/VideoSearchPopup";
import { MediaSearchPopup } from "../../media/MediaSearchPopup";
import { dataURLtoBlob } from "../../../contexts/PostEditContext/helpers";

const Base = styled.div`
    position: relative;
`;

const PlayerWrapper = styled.div`
    position: relative;
    aspect-ratio: 16 / 9;
    background: rgba(0, 0, 0, 0.1);
`;

// Replace with native react player when this is updated to support the latest React version
export const VideoWrapper = ({ assetId, posterUrl }) => {
    const elementId = `player-embed-${Math.random()}`;
    const contextData = {
        appName: "TV2.no",
        appDomain: "TV2",
        allowMutedAutoplay: true,
        pipEnabled: true,
        adsEnabled: false,
        altPlayer: "shaka"
    };

    useEffect(() => {
        if (assetId && window.tv2PlayerInsert) {
            window.tv2PlayerInsert(
                {
                    assetId,
                    autoPlay: false,
                    posterUrl: posterUrl
                },
                contextData,
                elementId,
                {}
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId, posterUrl]);

    return <PlayerWrapper id={elementId}></PlayerWrapper>;
};

export const VideoContent = ({ entry, onChange }) => {
    const [showVideoBrowser, setShowVideoBrowser] = useState(false);
    const [showMediaBrowser, setShowMediaBrowser] = useState(false);

    const [posterUrl, setPosterUrl] = useState(null);

    useEffect(() => {
        if (entry.files && entry.files.length > 0) {
            const file = entry.files[0];
            const url = file._previewData
                ? URL.createObjectURL(dataURLtoBlob(file._previewData))
                : file.url;
            setPosterUrl(url);
        } else if (entry.data && entry.data.thumbSrc) {
            setPosterUrl(entry.data.thumbSrc);
        }
    }, [entry]);

    const onVideoSelect = (data) => {
        setShowVideoBrowser(false);
        onChange({ data });
    };

    const onVideoPosterChanged = async (imageFiles) => {
        onChange({ files: imageFiles.files });
    };

    return (
        <Base>
            {entry.data ? (
                <Row gutter={["tiny", "tiny"]}>
                    <Col span={24}>
                        <VideoWrapper assetId={entry.data.assetId} posterUrl={posterUrl} />
                    </Col>
                    <Col>
                        <Button size="small" onClick={() => setShowVideoBrowser(true)}>
                            Endre video
                        </Button>
                    </Col>
                    <Col>
                        <Button size="small" onClick={() => setShowMediaBrowser(true)}>
                            Endre plakatbilde
                        </Button>
                    </Col>
                    {entry.files && entry.files.length > 0 && (
                        <Col>
                            <Button size="small" onClick={() => onChange({ files: [] })}>
                                Fjern plakatbilde
                            </Button>
                        </Col>
                    )}
                </Row>
            ) : (
                <Image
                    scale="16:9"
                    onClick={() => setShowVideoBrowser(true)}
                    placeholderIcon="video"
                    placeholderText="Trykk for å velge video"
                />
            )}

            {showVideoBrowser && (
                <VideoSearchPopup
                    onClose={() => setShowVideoBrowser(false)}
                    onAssetClick={onVideoSelect}
                    defaultTab="SEARCH"
                />
            )}

            {/* PROMPT: Media browser */}
            {showMediaBrowser && (
                <MediaSearchPopup
                    onClose={() => {
                        setShowMediaBrowser(false);
                    }}
                    onImageClicked={(fileData) =>
                        setShowMediaBrowser(false) & onVideoPosterChanged({ files: [fileData] })
                    }
                />
            )}
        </Base>
    );
};
