import {
    Col,
    FileSelectButton,
    NavPanes,
    Popup,
    PopupFooter,
    PopupTitle,
    Row
} from "@tv2no/ui-react";
import React, { useState } from "react";
import { handleImageClick } from "../FileDataEntry/helpers";
import { BildbyranImageSelector } from "./parts/BildbyranImageSelector";
import { ScanpixImageSelector } from "./parts/ScanpixImageSelector";
import { StillsImageSelector } from "./parts/StillsImageSelector";

export const MediaSearchPopup = ({ onClose, onImageClicked }) => {
    const [searchText, setSearchText] = useState("");
    const imageClick = ({ file, caption, attribution }) => {
        handleImageClick({ file, caption, attribution, onImageClicked, onClose });
    };

    const panes = {
        PANE_STILLS: {
            icon: "image",
            label: "TV 2 Stills",
            content: (
                <StillsImageSelector
                    searchText={searchText}
                    setSearchText={setSearchText}
                    onImageClicked={imageClick}
                />
            )
        },
        PANE_BILDBYRAN: {
            icon: "image",
            label: "Bildbyrån",
            content: (
                <BildbyranImageSelector
                    onImageClicked={imageClick}
                    searchText={searchText}
                    setSearchText={setSearchText}
                />
            )
        },
        PANE_SCANPIX: {
            icon: "image",
            label: "Scanpix",
            content: (
                <ScanpixImageSelector
                    onImageClicked={imageClick}
                    searchText={searchText}
                    setSearchText={setSearchText}
                />
            )
        }
    };

    const [activePane, setActivePane] = useState(Object.keys(panes)[0]);

    return (
        <Popup size="medium" onClose={onClose}>
            <PopupTitle>{panes[activePane].label}</PopupTitle>
            <NavPanes size="embedded" activeKey={activePane} onChange={setActivePane}>
                {Object.keys(panes).map((key) => (
                    <NavPanes.Pane
                        key={key}
                        paneKey={key}
                        label={panes[key].label}
                        icon={panes[key].icon}
                    >
                        {panes[key].content}
                    </NavPanes.Pane>
                ))}
            </NavPanes>
            <PopupFooter>
                <Row>
                    <Col align={["right"]} grow>
                        <FileSelectButton
                            icon="upload"
                            iconColor="primary"
                            multiple={true}
                            onSelect={(files) => imageClick({ file: files?.[0] })}
                        >
                            Velg fra dine filer
                        </FileSelectButton>
                    </Col>
                </Row>
            </PopupFooter>
        </Popup>
    );
};
