import { useEffect, useState } from "react";

export const useModuleBrowser = ({ onAdd, onClose }) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleReceiveMessage = (event) => {
        const data = event.data?.tv2bits;
        if (data) {
            window.removeEventListener("message", handleReceiveMessage);
            setIsOpened(false);
        }
        if (data?.action === "ADD_MODULES") {
            const module = data?.modules?.[0];
            if (module) {
                onAdd && onAdd(module);
            }
        } else if (data?.action === "CLOSE") {
            onClose && onClose();
        }
    };

    const open = () => {
        if (!window["tv2bits"]) return console.log("tv2bits not found");
        if (!isOpened) {
            window.open(
                `${window["tv2bits"]?.config?.webUrl}/browser?limit=1`,
                "_blank",
                "width=1200,height=1000"
            );
            window.addEventListener("message", handleReceiveMessage);
            setIsOpened(true);
        }
    };

    useEffect(() => {
        return () => {
            window.removeEventListener("message", handleReceiveMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        open
    };
};

export default useModuleBrowser;
