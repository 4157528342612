import axios from "axios";
import config from "../../config";

export const getTitleSuggestions = (text) =>
    axios
        .post(`${config.aiToolsUrl}/v1/editorial/title`, {
            text,
            maxLength: 80,
            suggestionCount: 4,
            descriptor: "max 6 words long"
        })
        .then((res) => res.data);
