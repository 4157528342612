import {
    Button,
    Card,
    CardContent,
    CardTitle,
    Checkbox,
    Col,
    FormItem,
    OnOffSwitch,
    Row,
    SingleDatePicker,
    Text,
    Textarea,
    TimePicker,
    Toast
} from "@tv2no/ui-react";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { VideoSearchPopup } from "../../../../../components/portal/VideoSearchPopup";
import { VideoWrapper } from "../../../../../components/post_content/VideoContent/VideoContent";
import { updatePortal } from "../../../../../data/portal/portal";
import useDebounce from "../../../../../hooks/useDebounce";
import { PortalEntryContext } from "../../PortalEntry";

export const Video = () => {
    const { portal, setPortal } = useContext(PortalEntryContext);
    const [busySaving, setBusySaving] = useState(false);
    const [enableSave, setEnableSave] = useState(false);
    const [dateValidation, setDateValidation] = useState({
        text: null,
        startInvalid: false,
        endInvalid: false
    });
    const [videoSearchPopupVisible, setVideoSearchPopupVisible] = useState(false);
    const [videoState, setVideoState] = useState(
        portal?.video || {
            assetId: "",
            visible: false,
            title: "",
            live: false,
            thumbSrc: "",
            autoplay: false,
            showAds: true,
            teaseText: "",
            teaseStartTime: null,
            teaseEndTime: null
        }
    );

    useEffect(() => {
        if (videoState !== portal?.video) {
            setEnableSave(!dateValidation?.endInvalid && !dateValidation?.startInvalid);
        }
    }, [dateValidation, videoState, portal]);

    const handleSave = () => {
        setBusySaving(true);
        updatePortal(portal.id, { video: videoState }).then(() => {
            setBusySaving(false);
            setEnableSave(false);
            setPortal({ ...portal, video: videoState });
            Toast.success("Endringene ble lagret");
        });
    };

    const onPropChange = (key, val) => {
        setVideoState((prev) => ({ ...prev, [key]: val }));
    };

    const handleDateValidation = (data) => {
        setDateValidation((prev) => ({ ...prev, ...data }));
    };

    /**
     * Check if end date is before start date
     * @param {date} start
     * @param {string} val
     */
    const checkEndDate = (key, val) => {
        const start = dayjs(videoState?.teaseStartTime);

        if (dayjs(val).isBefore(start)) {
            handleDateValidation({
                text: "Sluttdato kan ikke være før startdato",
                endInvalid: true
            });
        } else {
            handleDateValidation({ text: null, endInvalid: false, startInvalid: false });
        }

        onPropChange(key, new Date(val));
    };

    /**
     * Check if start date is after end date
     * @param {date} end
     * @param {string} val
     */
    const checkStartDate = (key, val) => {
        const end = dayjs(videoState?.teaseEndTime);

        if (end.isBefore(dayjs(val))) {
            handleDateValidation({
                text: "Startdato kan ikke være før sluttdato",
                startInvalid: true
            });
        } else {
            handleDateValidation({ text: null, endInvalid: false, startInvalid: false });
        }

        onPropChange(key, new Date(val));
    };

    const onVisibleChange = (visible) => {
        onPropChange("visible", visible);
        if (visible && !videoState.assetId) {
            setVideoSearchPopupVisible(true);
        }
    };

    const onAssetClick = (data) => {
        setVideoSearchPopupVisible(false);
        setVideoState((prev) => ({
            ...prev,
            ...data
        }));
        if (data.assetId !== videoState?.assetId) {
            setEnableSave(true);
        }
    };

    const onClearVideoTease = () => {
        setEnableSave(true);
        setVideoState((prev) => ({
            ...prev,
            teaseText: "",
            teaseEndTime: null,
            teaseStartTime: null
        }));
    };

    useDebounce(
        () => {
            if (!videoState?.visible && enableSave) {
                handleSave();
            }
        },
        [videoState?.visible, enableSave, handleSave],
        { delay: 200 }
    );

    return (
        <Card>
            <CardTitle title={`Video på ${portal.name}`} />
            <CardContent background={videoState?.visible ? "success" : "error"}>
                <OnOffSwitch value={videoState?.visible} onChange={onVisibleChange}>
                    {videoState?.visible ? "Video er skrudd på" : "Video er skrudd av"}
                </OnOffSwitch>
            </CardContent>
            {videoState.visible && (
                <React.Fragment>
                    {/* VIDEO PREVIEW */}
                    <CardContent border="top">
                        <Row>
                            <Col span={24}>
                                <Text size="medium" weight="bold">
                                    {videoState?.title || "Ingen tittel"}
                                </Text>
                            </Col>
                            <Col md={14}>
                                <VideoWrapper assetId={videoState.assetId} />
                            </Col>
                            <Col md={10}>
                                <Row gutter={["small", "small"]}>
                                    <Col span={24} fill>
                                        <Button
                                            icon={"video"}
                                            onClick={() => setVideoSearchPopupVisible(true)}
                                            iconColor="primary"
                                        >
                                            Velg videoklipp/strøm
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox
                                            checked={videoState?.autoplay}
                                            onChange={(val) => onPropChange("autoplay", val)}
                                        >
                                            Spill av automatisk
                                        </Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox
                                            checked={videoState?.showAds}
                                            onChange={(val) => onPropChange("showAds", val)}
                                        >
                                            Vis annonser før video
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardContent>

                    {/* VIDEO TEASE */}
                    <CardContent border="top">
                        <Row>
                            <Col span={12}>
                                <Text size="medium" weight="bold">
                                    Video-tease
                                </Text>
                            </Col>
                            <Col span={12} align={["right"]} grow>
                                <Button
                                    icon="trash"
                                    iconColor="error"
                                    size="small"
                                    color="silent"
                                    onClick={onClearVideoTease}
                                    disabled={!videoState?.teaseStartTime && !videoState?.teaseText}
                                >
                                    Nullstill
                                </Button>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            label="Starter"
                                            error={dateValidation.startInvalid}
                                            info={
                                                dateValidation.startInvalid && dateValidation.text
                                            }
                                        >
                                            <Row gutter={["small", "small"]}>
                                                <Col md={12}>
                                                    <SingleDatePicker
                                                        placeholder="Dato"
                                                        error={dateValidation.startInvalid}
                                                        value={
                                                            videoState.teaseStartTime
                                                                ? new Date(
                                                                      videoState.teaseStartTime
                                                                  )
                                                                : null
                                                        }
                                                        onChange={(val) =>
                                                            checkStartDate(
                                                                "teaseStartTime",
                                                                new Date(val)
                                                            )
                                                        }
                                                        isDayBlocked={(date) =>
                                                            dayjs(date).isAfter(
                                                                dayjs(videoState?.teaseEndTime)
                                                                    .set("hour", 23)
                                                                    .set("minute", 59)
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col md={12}>
                                                    <TimePicker
                                                        placeholder="Tidspunkt"
                                                        value={
                                                            videoState?.teaseStartTime
                                                                ? new Date(
                                                                      videoState.teaseStartTime
                                                                  )
                                                                : null
                                                        }
                                                        onChange={(val) =>
                                                            checkStartDate(
                                                                "teaseStartTime",
                                                                new Date(val)
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </FormItem>
                                    </Col>
                                    <Col span={24}>
                                        <FormItem
                                            label="Slutter"
                                            error={dateValidation.endInvalid}
                                            info={dateValidation.endInvalid && dateValidation.text}
                                        >
                                            <Row gutter={["small", "small"]}>
                                                <Col md={12}>
                                                    <SingleDatePicker
                                                        placeholder="Dato"
                                                        error={dateValidation.endInvalid}
                                                        value={
                                                            videoState?.teaseEndTime
                                                                ? new Date(videoState.teaseEndTime)
                                                                : null
                                                        }
                                                        onChange={(val) =>
                                                            checkEndDate(
                                                                "teaseEndTime",
                                                                new Date(val)
                                                            )
                                                        }
                                                        isDayBlocked={(date) =>
                                                            dayjs(date).isBefore(
                                                                dayjs(videoState?.teaseStartTime)
                                                                    .set("hour", 0)
                                                                    .set("minute", 0)
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col md={12}>
                                                    <TimePicker
                                                        placeholder="Tidspunkt"
                                                        value={
                                                            videoState?.teaseEndTime
                                                                ? new Date(videoState.teaseEndTime)
                                                                : null
                                                        }
                                                        onChange={(val) =>
                                                            checkEndDate(
                                                                "teaseEndTime",
                                                                new Date(val)
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12}>
                                <FormItem label="Tekst">
                                    <Textarea
                                        counter
                                        maxLength={38}
                                        placeholder="Skriv en tekst som sier noe om hva som skjer på det angitte tidspunktet."
                                        value={videoState?.teaseText}
                                        onChange={(val) => onPropChange("teaseText", val)}
                                        rows={5}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </CardContent>
                    <CardContent border="top">
                        <Button
                            color="primary"
                            onClick={handleSave}
                            busy={busySaving}
                            disabled={!enableSave || (videoState.visible && !videoState?.title)}
                        >
                            Lagre endringene
                        </Button>
                    </CardContent>
                </React.Fragment>
            )}

            {videoSearchPopupVisible && (
                <VideoSearchPopup
                    onClose={() => setVideoSearchPopupVisible(false)}
                    onAssetClick={onAssetClick}
                />
            )}
        </Card>
    );
};
