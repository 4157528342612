import { Clickable, Text } from "@tv2no/ui-react";
import React from "react";
import styled from "styled-components";
import { LabradorSearchPopup } from "./parts/LabradorSearchPopup";

const Base = styled.div`
    position: relative;
`;

export const LabradorContent = ({ entry, editMode, setEditMode, onChange, onDelete }) => {
    const { url, title } = entry?.data || {};

    return (
        <Base>
            <Text weight="bold">Les også:&nbsp;</Text>
            {url ? (
                <a href={url} target="blank">
                    <Text>{title}</Text>
                </a>
            ) : (
                <Clickable onClick={() => setEditMode(true)}>Klikk for å legge til</Clickable>
            )}
            {(editMode || !url) && (
                <LabradorSearchPopup
                    entry={entry}
                    onSelect={(data) => onChange({ data }) & setEditMode(false)}
                    onClose={() => (!url ? onDelete() : setEditMode(false))}
                />
            )}
        </Base>
    );
};
