import React, { useRef, useState } from "react";
import {
    Col,
    ComponentWrapper,
    Image,
    InfiniteScroll,
    Row,
    Spinner,
    PopupLoader
} from "@tv2no/ui-react";
import { getStillsImages, downloadStillsImage } from "../../../../data/external/stills";
import useDebounce from "../../../../hooks/useDebounce";
import { BrowseControls } from "./BrowseControls";

export const StillsImageSelector = ({ onImageClicked, searchText, setSearchText }) => {
    const [images, setImages] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [errorImages, setErrorImages] = useState(false);
    const [downloadingImage, setDownloadingImage] = useState(false);
    const [page, setPage] = useState(1);
    const [hasNextImagesPage, setHasNextImagesPage] = useState(false);
    const [imageColSpan, setImageColSpan] = useState(8);
    const abortController = useRef(new AbortController());

    useDebounce(
        () => {
            abortController.current.abort();
            abortController.current = new AbortController();
            setErrorImages(false);
            setLoadingImages(true);
            setHasNextImagesPage(false);
            getStillsImages(searchText, page, 32, abortController.current.signal)
                .then((response) => {
                    if (typeof response.data === "string") {
                        setErrorImages(true);
                        setLoadingImages(false);
                    } else {
                        setImages((images) =>
                            page === 1 ? response.data : [...images, ...response.data]
                        );
                        setTimeout(() => setHasNextImagesPage(response.data.length >= 32), 1000);
                        setLoadingImages(false);
                    }
                })
                .catch((e) => {
                    if (e.message !== "canceled") {
                        setLoadingImages(false);
                    }
                });
        },
        [page, searchText],
        { delay: 500 }
    );

    function handleSearchChange(value) {
        setImages([]);
        setLoadingImages(true);
        setPage(1);
        setSearchText(value);
    }

    async function downloadImage(image) {
        setDownloadingImage(true);
        const { id, caption, byline } = image;
        const file = await downloadStillsImage(id);
        onImageClicked({ file, caption, attribution: byline });
    }

    return (
        <div style={{ padding: "1rem" }}>
            <BrowseControls
                searchText={searchText}
                handleSearchChange={handleSearchChange}
                imageColSpan={imageColSpan}
                setImageColSpan={setImageColSpan}
            />
            <Row>
                {images.map((image) => (
                    <Col span={imageColSpan} key={image.id} align={["center", "center"]}>
                        <Image
                            src={image.preview}
                            alt={image.caption}
                            onClick={() => downloadImage(image)}
                        />
                    </Col>
                ))}
                {errorImages ? <Col>En feil har oppstått.</Col> : (!loadingImages && images.length === 0) ? <Col>Ingen bilder funnet.</Col> : null}
            </Row>

            {loadingImages && (
                <ComponentWrapper height="20rem">
                    <Spinner centered />
                </ComponentWrapper>
            )}

            {downloadingImage && <PopupLoader label="Laster bilde..." />}

            {hasNextImagesPage && !loadingImages && (
                <InfiniteScroll onLoadMore={() => setPage((page) => page + 1)} />
            )}
        </div>
    );
};
