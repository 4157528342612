import {
    Button,
    ButtonRow,
    Col,
    Form,
    Image,
    Input,
    Popup,
    PopupContent,
    PopupFooter,
    PopupTitle,
    Row,
    Spinner,
    TabPane,
    Tabs,
    Text
} from "@tv2no/ui-react";
import React, { useEffect, useState } from "react";
import { searchVideo } from "../../data/video/videoSearch";
import useDebounce from "../../hooks/useDebounce";

const PRESELECTABLE_ASSET_IDS = [1153546, 1787926, 1416253];

const Asset = ({ src, title, onClick }) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            cursor: "pointer"
        }}
        onClick={onClick}
    >
        {src ? <Image scale={"16:9"} src={src} /> : null}
        <div
            style={{
                marginTop: "6px",
                height: "70px",
                overflow: "hidden"
            }}
        >
            <Text size="medium" weight="medium">
                {title}
            </Text>
        </div>
    </div>
);

export const VideoSearchPopup = ({ onClose, onAssetClick, defaultTab = "DEFAULT" }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [videoAssets, setVideoAssets] = useState([]);
    const [defaultAssets, setDefaultAssets] = useState([]);
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [search, setSearch] = useState({
        assetId: "",
        text: "",
        page: 0
    });

    useEffect(() => {
        async function fetchDefaultAssets() {
            const assets = await Promise.all(
                PRESELECTABLE_ASSET_IDS.map((assetId) => searchVideo({ assetId }))
            );
            setDefaultAssets(assets.flat());
        }
        fetchDefaultAssets();
    }, []);

    useDebounce(
        async () => {
            const assets = await searchVideo(search);
            setIsLoading(false);
            setVideoAssets(assets);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [search],
        { delay: 500 }
    );

    const onNextPage = () => {
        setSearch({ ...search, page: search.page + 1 });
    };

    const onPrevPage = () => {
        setSearch({ ...search, page: search.page - 1 });
    };

    return (
        <Popup size="medium" onClose={onClose}>
            <PopupTitle>Velg video</PopupTitle>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane tabKey={"DEFAULT"} label="Livestrømmer">
                    <PopupContent>
                        {defaultAssets.length > 0 ? (
                            <Row spacing="regular">
                                {defaultAssets.map((data) => (
                                    <Col xs={{ span: 12 }} md={{ span: 8 }} key={data.assetId}>
                                        <Asset
                                            src={`${data.thumbSrc}?width=200`}
                                            title={data.title}
                                            onClick={() => onAssetClick(data)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Spinner />
                        )}
                    </PopupContent>
                </TabPane>
                <TabPane tabKey={"SEARCH"} label="Søk video" icon="search">
                    <PopupContent>
                        <Row spacing="regular">
                            <Col span={24}>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Input
                                                placeholder="Asset ID for video"
                                                value={search.assetId}
                                                onChange={(assetId) => {
                                                    setSearch((prev) => ({
                                                        ...prev,
                                                        assetId,
                                                        page: 0
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col grow>
                                            <Input
                                                placeholder="Videotekst"
                                                value={search.text}
                                                onChange={(text) => {
                                                    setSearch((prev) => ({
                                                        ...prev,
                                                        text,
                                                        page: 0
                                                    }));
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {!isLoading && videoAssets.length === 0 && (
                                    <div style={{ minHeight: "200px", textAlign: "center" }}>
                                        <Text size="large" weight="bold">
                                            Ingen treff
                                        </Text>
                                    </div>
                                )}
                                {isLoading ? (
                                    <div style={{ minHeight: "500px" }}>
                                        <Spinner centered />
                                    </div>
                                ) : (
                                    <Row>
                                        {videoAssets.length > 0 &&
                                            videoAssets.map((data, i) => {
                                                return (
                                                    <Col
                                                        xs={{ span: 12 }}
                                                        md={{ span: 8 }}
                                                        key={"asset_" + i}
                                                        align={["left", "center"]}
                                                    >
                                                        <Asset
                                                            src={`${data.thumbSrc}?width=200`}
                                                            title={data.title}
                                                            onClick={() => onAssetClick(data)}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </PopupContent>
                </TabPane>
            </Tabs>
            {activeTab === "SEARCH" && (
                <PopupFooter>
                    <Row>
                        <Col span={24} align={["right"]}>
                            <ButtonRow>
                                {search.page > 0 && (
                                    <Button icon={"arrow-left"} onClick={onPrevPage}>
                                        Forrige
                                    </Button>
                                )}
                                {videoAssets.length >= 9 && (
                                    <Button
                                        iconRight={true}
                                        icon={"arrow-right"}
                                        onClick={onNextPage}
                                    >
                                        Neste
                                    </Button>
                                )}
                            </ButtonRow>
                        </Col>
                    </Row>
                </PopupFooter>
            )}
        </Popup>
    );
};
