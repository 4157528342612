import classNames from "classnames";
import React from "react";
import styled from "styled-components";

const Base = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--color_base), 0.05);
    aspect-ratio: 16/9;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: var(--fontWeight_bold);
    color: rgba(var(--color_base), 0.2);

    &.spacingTop {
        margin-top: 1.5rem;
    }

    &.spacingBottom {
        margin-bottom: 1.5rem;
    }
`;

export const ModuleDummy = ({ children, spacing }) => {
    return (
        <Base
            className={classNames({
                spacingTop: spacing?.includes("top"),
                spacingBottom: spacing?.includes("bottom")
            })}
        >
            {children}
        </Base>
    );
};
