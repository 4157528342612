import {
    Button,
    Checkbox,
    Col,
    InfiniteScroll,
    ItemList,
    PopOver,
    PopupYesNo,
    Row,
    ScrollableArea,
    Spinner,
    Text
} from "@tv2no/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { getPortalList } from "../../../../data/portal/portal";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { PortalEntryContext } from "../../../../routes/portals/entry/PortalEntry";
import { PostEditContext } from "../../../../contexts/PostEditContext";

export const PostPortals = () => {
    const { post, changePost } = useContext(PostEditContext);
    const { portal: currentPortal } = useContext(PortalEntryContext);
    const [portalList, setPortalList] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [showSelector, setShowSelector] = useState(false);
    const [confirmRemovePortal, setConfirmRemovePortal] = useState(null);
    const isMountedRef = useIsMounted();

    useEffect(() => {
        setBusyLoading(true);
        getPortalList({ page: pageNumber, limit: 10 }).then((response) => {
            if (!isMountedRef.current) {
                return;
            }
            setPortalList((list) => {
                if (response.page > 1) {
                    response.docs = list.docs.concat(response.docs);
                }
                return response;
            });
            setBusyLoading(false);
        });
    }, [pageNumber, isMountedRef]);

    const togglePortalSelection = (portal) => {
        if (portal.id === currentPortal.id) {
            return;
        }
        const portalIndex = post.portals?.findIndex((p) => p.id === portal.id);
        if (portalIndex >= 0) {
            post.portals.splice(portalIndex, 1);
            changePost({
                portals: post.portals
            });
        } else {
            changePost({
                portals: [...post.portals, portal]
            });
        }
    };

    return (
        <React.Fragment>
            <Row gutter={["tiny", "tiny"]}>
                {/* Add button */}
                <Col>
                    <PopOver
                        visible={showSelector}
                        onClose={() => setShowSelector(false)}
                        padding="regular"
                        content={
                            <div style={{ height: "20rem" }}>
                                <ScrollableArea suppressScrollX>
                                    <Row>
                                        <Col span={24}>
                                            <ItemList onItemClick={togglePortalSelection}>
                                                {portalList?.docs?.map((portal, idx) => (
                                                    <ItemList.Item
                                                        key={portal.id}
                                                        index={idx}
                                                        value={portal}
                                                        prepend={
                                                            <div
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <Checkbox
                                                                    embedded
                                                                    checked={
                                                                        portal.id ===
                                                                            currentPortal.id ||
                                                                        !!post?.portals?.find(
                                                                            (p) =>
                                                                                p.id === portal.id
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    >
                                                        {portal.name}
                                                    </ItemList.Item>
                                                ))}
                                            </ItemList>
                                        </Col>
                                        {busyLoading && <Spinner />}
                                    </Row>
                                    {portalList?.totalPages > 1 && portalList?.hasNextPage && (
                                        <InfiniteScroll
                                            onLoadMore={() => setPageNumber(pageNumber + 1)}
                                        />
                                    )}
                                </ScrollableArea>
                            </div>
                        }
                    >
                        <Button
                            title="Legg til portaler"
                            icon="plus"
                            iconColor="primary"
                            size="small"
                            color="silent"
                            square
                            onClick={() => setShowSelector(!showSelector)}
                        />
                    </PopOver>
                </Col>

                <Col key={currentPortal.id} shrink>
                    <Button
                        icon="structure"
                        size="small"
                        title="Gjeldene portal"
                        color="silent"
                        disabled
                    >
                        {currentPortal.name}
                    </Button>
                </Col>

                {/* All portals */}
                {post?.portals
                    ?.filter((p) => p.id !== currentPortal.id)
                    .map((portal) => (
                        <Col key={portal.id} shrink>
                            <Button
                                icon="structure"
                                color="silent"
                                size="small"
                                onClick={() => setConfirmRemovePortal(portal)}
                            >
                                {portal.name}
                            </Button>
                        </Col>
                    ))}
            </Row>

            {/* PROMPT: Confirm remove portal */}
            {confirmRemovePortal && (
                <PopupYesNo
                    title="Fjerne melding fra portal?"
                    onYes={() =>
                        togglePortalSelection(confirmRemovePortal) & setConfirmRemovePortal(null)
                    }
                    onNo={() => setConfirmRemovePortal(null)}
                >
                    Er du sikker på at du vil fjerne denne meldingen fra portalen:{" "}
                    <Text weight="medium">{confirmRemovePortal.name}</Text>?
                </PopupYesNo>
            )}
        </React.Fragment>
    );
};
