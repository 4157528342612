import React from "react";
import { FileDataEntry } from "../../media/FileDataEntry/FileDataEntry";

export const PicturesContent = ({ entry, onChange, editMode, setEditMode }) => {
    return (
        <FileDataEntry
            fileData={entry?.files?.[0]}
            onChange={(fileEntry) =>
                onChange({
                    files: [fileEntry]
                })
            }
            editMode={editMode}
            setEditMode={setEditMode}
        />
    );
};
