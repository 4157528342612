import React, { useEffect, useState } from "react";
import { Col, Row, Input, PopOver, Menu, MenuItem, Button } from "@tv2no/ui-react";
import { getStorageData, setStorageData, STORAGE_KEYS } from "../../../../utils/storageUtils";

export const BrowseControls = ({
    searchText,
    handleSearchChange,
    imageColSpan,
    setImageColSpan,
    disabled
}) => {
    const [visible, setVisible] = useState(null);

    useEffect(() => {
        const storage = getStorageData(STORAGE_KEYS.THUMB_SIZE);
        if (storage) {
            setImageColSpan(storage);
        }
    }, [setImageColSpan]);

    const saveImageSpan = (val) => {
        setImageColSpan(val);
        setVisible(false);
        setStorageData(STORAGE_KEYS.THUMB_SIZE, val);
    };
    return (
        <Row spacing="regular" gutter={["small", "small"]}>
            <Col grow>
                <Input
                    icon="search"
                    placeholder="Søk"
                    value={searchText}
                    onChange={handleSearchChange}
                    disabled={disabled}
                />
            </Col>
            <Col shrink>
                <PopOver
                    padding="regular"
                    visible={visible}
                    content={
                        <Menu>
                            <MenuItem
                                label="To bilder per rad"
                                selected={imageColSpan === 12}
                                onClick={() => {
                                    saveImageSpan(12);
                                }}
                            />
                            <MenuItem
                                label="Tre bilder per rad"
                                selected={imageColSpan === 8}
                                onClick={() => {
                                    saveImageSpan(8);
                                }}
                            />
                            <MenuItem
                                label="Fire bilder per rad"
                                selected={imageColSpan === 6}
                                onClick={() => {
                                    saveImageSpan(6);
                                }}
                            />
                        </Menu>
                    }
                    onClose={() => setVisible(null)}
                >
                    <Button
                        square
                        icon="resolution"
                        iconColor="primary"
                        onClick={() => setVisible(!visible)}
                    ></Button>
                </PopOver>
            </Col>
        </Row>
    );
};
