import { Input, Col, Row, Text } from "@tv2no/ui-react";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { loadScript } from "../../../utils/scriptUtils";
import { PostEditContext } from "../../../contexts/PostEditContext";

const Base = styled.div`
    position: relative;
`;

const Wrapper = styled.div`
    iframe {
        margin: 0 !important;
    }
`;

const getInstagramData = (url) => {
    let requestUrl = `https://graph.facebook.com/v13.0/instagram_oembed?url=${url}&omitscript=true&maxwidth=600&access_token=831337716926834|mb7E1d5slUqEghQ52SjBbT1sbJo`;
    return fetch(requestUrl).then((res) => res.json());
};

export const InstagramContent = ({ entry, onChange }) => {
    const [previewHtml, setPreviewHtml] = useState("");
    const { addContentError, removeContentError, contentErrors } = useContext(PostEditContext);
    const error = contentErrors[entry._tempId || entry.id];

    useEffect(() => {
        const url = entry.data?.url;
        if (url) {
            const match = url.match(
                /^((?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)/
            );
            if (!match) {
                addContentError({ entry, message: "Ugyldig Instagram-lenke" });
            } else {
                removeContentError(entry);
                getInstagramData(url).then((data) => {
                    if (!data?.html) {
                        addContentError({ entry, message: "Kunne ikke hente Instagram-innlegg" });
                    } else {
                        setPreviewHtml(data?.html ?? "");
                        if (window.instgrm) {
                            setTimeout(() => window.instgrm?.Embeds.process(), 100);
                        } else {
                            loadScript("//www.instagram.com/embed.js").then(() =>
                                window.instgrm?.Embeds.process()
                            );
                        }
                    }
                });
            }
        } else {
            setPreviewHtml("");
            removeContentError(entry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry.data?.url]);

    return (
        <Base>
            <Row gutter={["tiny", "tiny"]}>
                {previewHtml && previewHtml !== "" && (
                    <Col span={24}>
                        <Wrapper dangerouslySetInnerHTML={{ __html: previewHtml }} />
                    </Col>
                )}
                <Col span={24}>
                    <Input
                        value={entry.data?.url}
                        type="text"
                        error={!!error}
                        size="small"
                        icon="link"
                        placeholder="Instagram URL"
                        onChange={(val) =>
                            onChange({
                                data: { url: val }
                            })
                        }
                    />
                    {error ? <Text color="error">{error}</Text> : null}
                </Col>
            </Row>
        </Base>
    );
};
