import React, { useRef, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import {
    Col,
    ComponentWrapper,
    Image,
    InfiniteScroll,
    Row,
    Spinner,
    PopupLoader
} from "@tv2no/ui-react";
import { downloadBildbyranImage, getBildbyranImages } from "../../../../data/external/bildbyran";
import { BrowseControls } from "./BrowseControls";

export const BildbyranImageSelector = ({ onImageClicked, searchText, setSearchText }) => {
    const [images, setImages] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [downloadingImage, setDownloadingImage] = useState(false);
    const [page, setPage] = useState(1);
    const [hasNextImagesPage, setHasNextImagesPage] = useState(false);
    const [imageColSpan, setImageColSpan] = useState(8);
    const abortController = useRef(new AbortController());

    useDebounce(
        () => {
            abortController.current.abort();
            abortController.current = new AbortController();
            setLoadingImages(true);
            setHasNextImagesPage(false);
            getBildbyranImages(searchText, page, 32, abortController.current.signal)
                .then((response) => {
                    setImages((images) =>
                        page === 1 ? response.data : [...images, ...response.data]
                    );
                    setTimeout(() => setHasNextImagesPage(response.data.length >= 32), 1000);
                    setLoadingImages(false);
                })
                .catch((e) => {
                    if (e.message !== "canceled") {
                        setLoadingImages(false);
                    }
                });
        },
        [page, searchText],
        { delay: 500 }
    );

    function handleSearchChange(value) {
        setImages([]);
        setLoadingImages(true);
        setPage(1);
        setSearchText(value);
    }

    async function downloadImage(id, caption, byline) {
        setDownloadingImage(true);
        const file = await downloadBildbyranImage(id);
        onImageClicked({ file: file, caption, attribution: byline });
    }

    return (
        <div style={{ padding: "1rem" }}>
            <BrowseControls
                searchText={searchText}
                handleSearchChange={handleSearchChange}
                imageColSpan={imageColSpan}
                setImageColSpan={setImageColSpan}
            />
            <Row>
                {images &&
                    images.map((image) => (
                        <Col span={imageColSpan} key={image.id} align={["center", "center"]}>
                            <Image
                                src={image.preview}
                                alt={image.caption}
                                onClick={() => downloadImage(image.id, image.caption, image.byline)}
                            />
                        </Col>
                    ))}
                {!loadingImages && images.length === 0 && <Col>Ingen bilder funnet.</Col>}
            </Row>

            {loadingImages && (
                <ComponentWrapper height="20rem">
                    <Spinner centered />
                </ComponentWrapper>
            )}

            {downloadingImage && <PopupLoader label="Laster bilde..." />}

            {hasNextImagesPage && !loadingImages && (
                <InfiniteScroll onLoadMore={() => setPage((page) => page + 1)} />
            )}
        </div>
    );
};
