import { Button, ButtonGroup } from "@tv2no/ui-react";
import React, { useContext } from "react";
import { PostEditContext } from "../../../../contexts/PostEditContext";

export const PostFlags = () => {
    const { post, changePost } = useContext(PostEditContext);

    const handleToggleFlag = (flag) => {
        if (post.flags?.includes(flag)) {
            changePost({ flags: post.flags.filter((f) => f !== flag) });
        } else {
            changePost({ flags: [...(post.flags || []), flag] });
        }
    };

    const handleTogglePinned = () => {
        changePost({ pinned: !post.pinned });
    };

    return (
        <React.Fragment>
            <ButtonGroup fill>
                <Button
                    color={post.pinned ? "info" : "silent"}
                    icon="tack"
                    onClick={handleTogglePinned}
                    size="small"
                >
                    Festet
                </Button>
                <Button
                    color={post.flags?.includes("important") ? "info" : "silent"}
                    onClick={() => handleToggleFlag("important")}
                    icon="warning"
                    size="small"
                >
                    Viktig
                </Button>
                <Button
                    color={post.flags?.includes("tip") ? "info" : "silent"}
                    icon="chat"
                    onClick={() => handleToggleFlag("tip")}
                    size="small"
                >
                    Tips oss
                </Button>
            </ButtonGroup>
        </React.Fragment>
    );
};
