import { Button, CardContent, Col, Row } from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import config from "../../../../config";
import { PostEditContext } from "../../../../contexts/PostEditContext";
import { PromptShortMessage } from "./PromptShortMessage";

export const PostRelated = () => {
    const { post } = useContext(PostEditContext);
    const [promptShort, setPromptShort] = useState(false);

    return (post.related && Object.keys(post.related).length) || post?.short ? (
        <React.Fragment>
            <CardContent border="top" background="muted">
                <Row gutter={["tiny", "tiny"]}>
                    {post?.related?.push && (
                        <Col>
                            <Button
                                href={config.pushAdminUrl}
                                target="blank"
                                size="small"
                                icon="bell"
                                iconColor="info"
                            >
                                Pushmelding
                            </Button>
                        </Col>
                    )}
                    {post?.related?.labrador && (
                        <Col>
                            <Button
                                href={post.related.labrador.url}
                                target="blank"
                                size="small"
                                icon="labrador"
                                iconColor="info"
                            >
                                Labrador-artikkel
                            </Button>
                        </Col>
                    )}
                    {post?.related?.ntb && (
                        <Col>
                            <Button size="small" icon="news">
                                NTB-melding
                            </Button>
                        </Col>
                    )}
                    {post?.short && (
                        <Col>
                            <Button
                                size="small"
                                iconColor="info"
                                icon="lightning"
                                onClick={() => setPromptShort(true)}
                            >
                                Short
                            </Button>
                        </Col>
                    )}
                </Row>
            </CardContent>{" "}
            {/* Create/edit short TODO: Refactor deuplicates */}
            {promptShort && <PromptShortMessage onClose={() => setPromptShort(false)} />}
        </React.Fragment>
    ) : null;
};
