import { TabPane, Tabs } from "@tv2no/ui-react";
import React from "react";
import { NavButton } from "./NavButton";
import styled from "styled-components";

const Base = styled.div`
    height: 50px;
    margin-top: -10px;
    margin-bottom: 10px;
`;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 47px;
    background-color: #fafafa;
    z-index: 1;
`;

export const PageNav = ({ activeKey, children }) => {

    const handleChange = (key) => {
        React.Children.map(children, (child) => {
            if(child.props.tabKey === key){
                child.props.onClick && child.props.onClick();
            }
        });
    }

    return (
        <Base>
            <Wrapper>
                <Tabs activeKey={activeKey} onChange={handleChange}>
                    {React.Children.map(children, (child) => {
                        if (child?.type === NavButton) {
                            return (
                                <TabPane {...child.props}>
                                    <span />
                                </TabPane>
                            );
                        }
                    })}
                </Tabs>
            </Wrapper>
        </Base>
    );
};
