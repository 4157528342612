import { toSearchParamsString } from "../../utils/urlUtils";
import { getRequest, postRequest } from "../fetch";

export const getLabradorArticles = ({
    category,
    page = 1,
    limit = 20,
    portal,
    id = null,
    search
}) => {
    const queryParams = toSearchParamsString({
        limit,
        page: page,
        ...(id && { id }),
        ...(search && { search }),
        ...(category && { category }),
        ...(portal && { portal })
    });
    return getRequest(`/external/labrador/articles?${queryParams}`);
};

export const createPostFromArticle = async ({ articleId, portalId, publish }) => {
    return await postRequest(`/external/labrador/articles/${articleId}`, {
        portalId,
        publish
    });
};
