import React, { useContext, useState } from "react";
import { Row, Col, ContentWrapper, AppContext, Button, ButtonGroup } from "@tv2no/ui-react";
import { PostEditor } from "../../../../../components/post/PostEditor";
import { PostList } from "../../../../../components/post/PostList";
import { PostEditContextProvider } from "../../../../../contexts/PostEditContext";
import { PostListContextProvider } from "../../../../../contexts/PostListContext";
import { SourceList } from "./parts/SourceList";

export const Messages = () => {
    const { pageInfo } = useContext(AppContext);
    const isMobile = ["mobile", "tablet"].indexOf(pageInfo.breakpoint) >= 0;
    const [showSourceList, setShowSourceList] = useState(false);

    return (
        <PostListContextProvider>
            <PostEditContextProvider>
                <Row gutter={["large", "large"]}>
                    {isMobile && (
                        <Col span={24}>
                            <ContentWrapper tablet="down">
                                <ButtonGroup fill>
                                    <Button
                                        active={!showSourceList}
                                        color={!showSourceList ? "primary" : "silent"}
                                        size="small"
                                        onClick={() => {
                                            setShowSourceList(false);
                                        }}
                                    >
                                        Skriv melding
                                    </Button>
                                    <Button
                                        active={showSourceList}
                                        color={showSourceList ? "primary" : "silent"}
                                        size="small"
                                        onClick={() => {
                                            setShowSourceList(true);
                                        }}
                                    >
                                        Hent fra kilder
                                    </Button>
                                </ButtonGroup>
                            </ContentWrapper>
                        </Col>
                    )}
                    <Col xs={24} lg={13} md={12}>
                        <Row gutter={["large", "large"]}>
                            <Col span={24}>
                                {showSourceList && isMobile ? (
                                    <SourceList onClose={() => setShowSourceList(false)} />
                                ) : (
                                    <PostEditor />
                                )}
                            </Col>
                            <Col spacing={"tiny"} span={24}>
                                <PostList hideSourceList={() => setShowSourceList(false)} />
                            </Col>
                        </Row>
                    </Col>

                    {!isMobile && (
                        <Col xs={24} lg={11} md={12}>
                            <SourceList onClose={() => setShowSourceList(false)} />
                        </Col>
                    )}
                </Row>
            </PostEditContextProvider>
        </PostListContextProvider>
    );
};
