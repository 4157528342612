import {
    Card,
    CardAdder,
    CardContent,
    Col,
    Row,
    TabPane,
    Tabs,
    Text,
    PopOver,
    Menu,
    MenuItem,
    Button
} from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import { LAYOUT_PROMPTS, PortalLayoutContext } from "../Layout";
import { ModuleEntryList } from "./ModuleEntryList";
import { ModuleDummy } from "./ModuleDummy";
import useModuleBrowser from "../../../../../../hooks/useModuleBrowser";
import { getNextOrderCount } from "../helpers";

export const LayoutEditor = ({ portal }) => {
    const { layout, updateLayout, activePane, setActivePane, viewDevice, setShowPrompt } =
        useContext(PortalLayoutContext);
    const [showOptions, setShowOptions] = useState(false);
    const [toColumn, setToColumn] = useState(null);

    const { open } = useModuleBrowser({
        onAdd: (module) => {
            const orderCountDesktop = getNextOrderCount({
                modules: layout.modules,
                viewDevice: "desktop",
                ...(toColumn ? { column: toColumn } : { pane: activePane })
            });
            const orderCountMobile = getNextOrderCount({
                modules: layout.modules,
                viewDevice: "mobile",
                pane: activePane
            });
            updateLayout({
                modules: [
                    ...layout.modules,
                    ...[module].map((entry, idx) => ({
                        key: entry.useKey,
                        version: entry.version,
                        instanceId: entry.instanceId,
                        fields: entry.fields,
                        visible: !layout.enabled,
                        mobile: { order: orderCountMobile + idx, pane: activePane },
                        desktop: {
                            order: orderCountDesktop + idx,
                            ...(toColumn ? { column: toColumn } : { pane: activePane })
                        }
                    }))
                ]
            });
            setShowPrompt(null);
        }
    });

    return (
        <Row gutter={["large", "large"]}>
            <Col span={24}>
                <Row gutter={["regular", "large"]}>
                    {/* MAIN COLUMN */}
                    <Col
                        md={
                            viewDevice === "desktop"
                                ? { span: 16, offset: 0 }
                                : { span: 18, offset: 3 }
                        }
                        lg={
                            viewDevice === "desktop"
                                ? { span: 13, offset: 2 }
                                : { span: 10, offset: 7 }
                        }
                    >
                        <Row>
                            <Col span={24}>
                                <Row>
                                    <Col grow>
                                        <Text size="large" weight="bold">
                                            Hovedkolonne{" "}
                                            <Text size="small" weight="medium" opacity={30}>
                                                (desktop/mobil)
                                            </Text>
                                        </Text>
                                    </Col>
                                    <Col align={["right"]}>
                                        <PopOver
                                            visible={showOptions}
                                            onClose={() => setShowOptions(false)}
                                            padding="regular"
                                            content={
                                                <Menu>
                                                    {layout.panes?.length < 3 && (
                                                        <MenuItem
                                                            icon="plus-small"
                                                            label="Ny fane"
                                                            onClick={() =>
                                                                setShowPrompt({
                                                                    type: LAYOUT_PROMPTS.newPane
                                                                }) & setShowOptions(false)
                                                            }
                                                        />
                                                    )}
                                                    <MenuItem
                                                        icon="edit"
                                                        label="Rediger fane"
                                                        onClick={() =>
                                                            setShowPrompt({
                                                                type: LAYOUT_PROMPTS.editPane
                                                            }) & setShowOptions(false)
                                                        }
                                                    />
                                                </Menu>
                                            }
                                        >
                                            <div style={{ position: "absolute", right: 0 }}>
                                                <Button
                                                    circle
                                                    size="small"
                                                    icon="gear"
                                                    iconColor={showOptions ? null : "primary"}
                                                    color={showOptions ? "primary" : "silent"}
                                                    onClick={() => setShowOptions(!showOptions)}
                                                />
                                            </div>
                                        </PopOver>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Row>
                                        <Col grow>
                                            <Tabs activeKey={activePane} onChange={setActivePane}>
                                                {layout.panes?.map((pane) => (
                                                    <TabPane
                                                        key={pane.key}
                                                        tabKey={pane.key}
                                                        label={pane.label}
                                                    >
                                                        <CardContent>
                                                            <ModuleEntryList
                                                                filter={({ module }) =>
                                                                    module[viewDevice].pane ===
                                                                        pane.key &&
                                                                    ((viewDevice === "desktop" &&
                                                                        module[viewDevice]
                                                                            .column !== "RIGHT") ||
                                                                        viewDevice === "mobile")
                                                                }
                                                            />

                                                            <CardAdder
                                                                onClick={() => {
                                                                    setToColumn(null);
                                                                    open();
                                                                }}
                                                            />

                                                            {pane.key === "main" ? (
                                                                <ModuleDummy spacing="top">
                                                                    Meldingsfeed
                                                                </ModuleDummy>
                                                            ) : null}
                                                        </CardContent>
                                                    </TabPane>
                                                ))}

                                                {/* New pane */}
                                                {layout.panes?.length < 3 && (
                                                    <TabPane
                                                        icon="plus-small"
                                                        label={
                                                            <Text opacity={50} weight="regular">
                                                                Ny fane
                                                            </Text>
                                                        }
                                                        onClick={() =>
                                                            setShowPrompt({
                                                                type: LAYOUT_PROMPTS.newPane
                                                            })
                                                        }
                                                    />
                                                )}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    {/* RIGHT COLUMN (desktop) */}
                    {viewDevice === "desktop" && (
                        <Col lg={7} md={8}>
                            <Row>
                                <Col span={24}>
                                    <Text size="large" weight="bold">
                                        Sidekolonne{" "}
                                        <Text size="small" weight="medium" opacity={30}>
                                            (desktop)
                                        </Text>
                                    </Text>
                                </Col>
                                <Col span={24}>
                                    <Card>
                                        <CardContent>
                                            {portal?.video?.visible && (
                                                <ModuleDummy spacing="bottom">Video</ModuleDummy>
                                            )}
                                            <ModuleEntryList
                                                filter={({ module }) =>
                                                    viewDevice === "desktop" &&
                                                    module[viewDevice].column === "RIGHT"
                                                }
                                            />
                                            <CardAdder
                                                onClick={() => {
                                                    setToColumn("RIGHT");
                                                    open();
                                                }}
                                            />
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};
