import config from "../config";

export const toSearchParamsString = (params) =>
    new URLSearchParams([...Object.entries(params)]).toString();

const slugifyTitle = (title) =>
    title
        ? title
              .toLowerCase()
              .replace(/æ/g, "ae")
              .replace(/ø/g, "o")
              .replace(/å/g, "a")
              .normalize("NFD")
              .replace(/[^a-z0-9 ]/g, "")
              .replace(/ +/g, " ")
              .trim()
              .replace(/ /g, "-")
        : "";

export const getPublishedUrl = ({ portal, post }) =>
    portal.baseUrl
        ? `${portal.baseUrl}/${portal.key}/${post.id}/${slugifyTitle(post.title)}`
        : `${config.frontAppUrl}/${portal.key}/${portal.slug}/${post.id}/${slugifyTitle(
              post.title
          )}`;

export const getPushUrl = ({ portal, post }) => {
    const searchParams = toSearchParamsString({
        postId: post.id,
        postUrl: getPublishedUrl({
            portal,
            post
        })
    });
    return `${config.frontAppUrl}/open-push?${searchParams}`;
};

export const getResizedUrl = (file, size) =>
    file.url.replace(file.filename, `resized/${size}/${file.filename}`);
