import React, { useContext } from "react";
import { PortalLayoutContext } from "../Layout";
import { ModuleEntry } from "./ModuleEntry";
import styled from "styled-components";
import classNames from "classnames";
import { sortModuleList } from "../helpers";

const Base = styled.div`
    margin: calc(var(--spacing_medium) / 2 * -1) calc(var(--spacing_medium) * -1)
        calc(var(--spacing_medium) / 2);
`;

export const ModuleEntryList = ({ filter }) => {
    const { layout, viewDevice } = useContext(PortalLayoutContext);
    const moduleList = layout.modules
        .filter((module) => filter({ module }))
        .sort((a, b) => sortModuleList(viewDevice, a, b));
    return moduleList?.length ? (
        <Base className={classNames({ empty: !moduleList?.length })}>
            {moduleList.map((module, idx) => (
                <ModuleEntry
                    key={idx}
                    module={module}
                    isFirst={idx === 0}
                    isLast={idx >= moduleList?.length - 1}
                />
            ))}
        </Base>
    ) : null;
};
