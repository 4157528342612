import React, { useEffect, useState, useContext } from "react";
import {
    Popup,
    PopupTitle,
    PopupContent,
    PopupFooter,
    Form,
    FormItem,
    Input,
    Button,
    ConfirmActionButton,
    Row,
    Col,
    Select,
    Option,
    Toast,
    Callout,
    Text
} from "@tv2no/ui-react";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../../utils/stringUtils";
import { TENANT_KEYS } from "../../../constants";
import { updateFeed, deleteFeed } from "../../../data/feed/feed";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";
import { FeedPortals } from "./FeedPortals";

export const EditFeed = ({ onClose, ...props }) => {
    const [feed, setFeed] = useState(props.feed);
    const navigate = useNavigate();
    const { reloadFeeds } = useContext(GlobalAppContext);
    const [busyEditing, setBusyEditing] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [error, setError] = useState(null);
    const [existingFeed, setExistingFeed] = useState(null);

    const handleDelete = async () => {
        setBusyEditing(true);
        await deleteFeed(feed.id).then(() => {
            Toast.success("Feed ble slettet");
            reloadFeeds();
            navigate("/feeds");
        });
        setBusyEditing(false);
    };

    useEffect(() => {
        setFeed(props.feed);
        setUnsavedChanges(false);
    }, [props.feed]);

    const handleChangeProperty = (key, value) => {
        setUnsavedChanges(true);
        setError(null);
        setFeed({
            ...feed,
            [key]: value
        });
    };

    const handleSave = async () => {
        setError(null);
        setExistingFeed(null);

        setBusyEditing(true);
        const portalIds = Array.from(new Set([...feed.autoPortals.map((p) => p.id)]));
        updateFeed(feed.id, {
            tenantKey: feed.tenantKey,
            channelId: feed.channelId,
            name: feed.name,
            slug: slugify(feed.name),
            tags: feed.tags,
            autoPortals: portalIds
        })
            .then((response) => {
                reloadFeeds();
                setUnsavedChanges(false);
                setBusyEditing(false);
                navigate("/feeds");
            })
            .catch((response) => {
                if (response.statusCode === 409) {
                    setError("En feed med dette Feed-ID og denne kilden eksisterer allerede:");
                    setExistingFeed(response.target);
                } else {
                    setError("Det oppstod en feil ved opprettelse av feed.");
                }
                setBusyEditing(false);
            });
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle title="Feed innstillinger">Feed innstillinger</PopupTitle>
            <PopupContent>
                <Form>
                    <Row>
                        <Col span={24}>
                            <FormItem label="Navn">
                                <Input
                                    value={feed.name}
                                    onChange={(val) => handleChangeProperty("name", val)}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="Feed ID" info="Norkon Channel-ID.">
                                <Input
                                    value={feed.channelId}
                                    onChange={(val) => handleChangeProperty("channelId", val)}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="Kilde" info="Norkon Tenant-Key.">
                                <Select
                                    value={feed.tenantKey}
                                    onChange={(val) => handleChangeProperty("tenantKey", val)}
                                    placeholder="Velg kilde"
                                >
                                    {TENANT_KEYS.map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        {error ? (
                            <Col span={24}>
                                <Callout color="error">
                                    {error}
                                    {existingFeed && (
                                        <Text weight="bold"> &quot;{existingFeed.name}&quot;</Text>
                                    )}
                                </Callout>
                            </Col>
                        ) : null}
                        <Col span={24}>
                            <Callout color="silent">
                                <Text block weight="bold">
                                    Automatisk publisering
                                </Text>
                                <Text size="small">
                                    Her kan du velge hvilke portaler som skal få innlegg publisert
                                    automatisk.
                                </Text>
                            </Callout>
                        </Col>
                        <Col span={24}>
                            <FeedPortals feed={feed} />
                        </Col>
                    </Row>
                </Form>
            </PopupContent>
            <PopupFooter>
                <Row gutter={["small", "small"]}>
                    <Col>
                        <Button
                            color="primary"
                            disabled={!unsavedChanges}
                            onClick={() => handleSave(setBusyEditing)}
                            busy={busyEditing}
                        >
                            Lagre endringene
                        </Button>
                    </Col>
                    <Col>
                        <ConfirmActionButton
                            popup
                            fill={false}
                            busy={busyEditing}
                            label="Slett feed"
                            question="Er du sikker på at du vil slette denne feed?"
                            onConfirm={handleDelete}
                        />
                    </Col>
                </Row>
            </PopupFooter>
        </Popup>
    );
};
