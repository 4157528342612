import React from "react";
import { CardContent, Option, Select } from "@tv2no/ui-react";

export const SourceCategorySelector = ({ category, onChangeCategory, values, showAll = true }) => {
    return (
        <CardContent border="top">
            <Select value={category} onChange={onChangeCategory}>
                {showAll ? <Option value={""}>Alle kategorier</Option> : null}
                {values.map((val, i) => (
                    <Option key={i} value={val.toUpperCase()}>
                        {val}
                    </Option>
                ))}
            </Select>
        </CardContent>
    );
};
