import React, { useState, useRef, useEffect } from "react";
import { Tags } from "@tv2no/ui-react";
import { createTopic, getTopicList } from "../../../../data/post/topic";

export const PostTopics = ({ post, onChange }) => {
    const typeTimeoutRef = useRef();
    const [latestSuggestions, setLatestSuggestions] = useState(null);
    const [termSuggestions, setTermSuggestions] = useState(null);

    useEffect(() => {
        getTopicList().then((response) => {
            let counter = 1;
            setLatestSuggestions(
                response.docs.reduce((agg, entry) => {
                    agg.push({
                        id: counter,
                        name: entry.name,
                        objId: entry.id
                    });
                    counter++;
                    return agg;
                }, [])
            );
        });
    }, []);

    const handleAddTopic = (tag) => {
        if(!tag?.name){
            return;
        }
        createTopic({ name: tag.name.toLowerCase() }).then((response) => {
            // Add if not exists
            if (!post.topics.find((t) => t === response.name)) {
                onChange([...post.topics, response.name]);
            }
        });
    };

    const handleRemoveTopic = (index) => {
        onChange(post.topics.filter((_, i) => i !== index));
    };

    const handleTyping = (term) => {
        clearTimeout(typeTimeoutRef.current);
        typeTimeoutRef.current = setTimeout(() => {
            getTopicList({ term }).then((response) => {
                let counter = 1;
                const suggestions = response.docs.reduce((agg, entry) => {
                    agg.push({
                        id: counter,
                        name: entry.name,
                        objId: entry.id
                    });
                    counter++;
                    return agg;
                }, []);
                setTermSuggestions(suggestions);
            });
        }, 500);
    };

    return (
        <Tags
            value={post.topics?.map((t, idx) => {
                return { id: idx, name: t };
            })}
            placeholder="Legg til emneknagger..."
            suggestions={(termSuggestions || latestSuggestions || []).filter(
                (s) => post.topics?.find((t) => t === s.name) === undefined
            )}
            onAdd={handleAddTopic}
            onRemove={handleRemoveTopic}
            onInput={handleTyping}
            minQueryLength={0}
        />
    );
};
