import React, { useContext, useState } from "react";
import {
    AuthContext,
    Button,
    CardContent,
    Col,
    Menu,
    MenuItem,
    PopOver,
    PopupContent,
    PopupLoader,
    PopupYesNo,
    Row,
    Spinner,
    Text,
    Toast
} from "@tv2no/ui-react";
import { PostEditContext } from "../../../../contexts/PostEditContext";
import { PromptPublish } from "./PromptPublish";
import { PromptPushMessage } from "./PromptPushMessage";
import useKeyPress from "../../../../hooks/useKeyPress";
import { PortalEntryContext } from "../../../../routes/portals/entry/PortalEntry";
import { getPublishedUrl } from "../../../../utils/urlUtils";
import { PostListContext } from "../../../../contexts/PostListContext";
import { PromptShortMessage } from "./PromptShortMessage";

export const PostControls = () => {
    const {
        post,
        contentErrors,
        loading,
        saving,
        savePost,
        reloadPost,
        resetPost,
        deletePost,
        publishPost,
        unpublishPost,
        createRelatedLabradorArticle
    } = useContext(PostEditContext);
    const auth = useContext(AuthContext);
    const { portal } = useContext(PortalEntryContext);
    const { loadPostList } = useContext(PostListContext);
    const [showMenu, setShowMenu] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [promptPublish, setPromptPublish] = useState(false);
    const [promptUnpublish, setPromptUnpublish] = useState(false);
    const [promptCreateLabradorArticle, setPromptCreateLabradorArticle] = useState(false);
    const [promptPushMessage, setPromptPushMessage] = useState(false);
    const [promptReset, setPromptReset] = useState(false);
    const [busyMessage, setBusyMessage] = useState(null);
    const [promptShort, setPromptShort] = useState(false);
    const hasContentErrors = Object.keys(contentErrors).length > 0;

    useKeyPress({
        key: "p",
        withCommandOrControl: true,
        action: () => {
            if (!post.title?.trim()) {
                Toast.error("Meldingen mangler tittel");
                return;
            }
            if ((post._unsaved || (post.id && !post.publishedAt)) && !saving) {
                handleSaveChanges({ publish: true });
            }
        }
    });
    useKeyPress({
        key: "s",
        withCommandOrControl: true,
        action: () => {
            if (!post.title?.trim()) {
                Toast.error("Meldingen mangler tittel");
                return;
            }
            if (post._unsaved && !saving) {
                handleSaveChanges({ publish: !!post.publishedAt });
            }
        }
    });
    useKeyPress({
        key: "p",
        action: () => !!post.publishedAt && openPublishedUrl()
    });

    const handleSaveChanges = (params) => {
        savePost(params).then(() => {
            reloadPostList({ published: params.publish });
        });
    };

    const handleDeletePost = () => {
        setShowDeleteConfirm(false);
        deletePost(post.id).then(() => {
            reloadPostList();
        });
    };

    const handlePublish = (timeStamp) => {
        setPromptPublish(false);
        publishPost(post.id, timeStamp?.toISOString()).then(() => {
            reloadPostList({ published: true });
        });
    };

    const handleUnpublish = () => {
        setPromptUnpublish(false);
        unpublishPost(post.id).then(() => {
            reloadPostList({ published: false });
        });
    };

    const handleCreateLabradorArticle = () => {
        setPromptCreateLabradorArticle(false);
        setBusyMessage("Oppretter Labrador-artikkel...");
        createRelatedLabradorArticle(post, auth).then(() => {
            reloadPostList();
            setBusyMessage(null);
        });
    };

    const handlePushComplete = () => {
        setPromptPushMessage(false);
        // TODO: Få respons fra kallet som kan oppdatere posten
        reloadPost();
        reloadPostList();
    };

    const handleResetPost = () => {
        resetPost();
        setPromptReset(false);
    };

    const reloadPostList = ({ published } = {}) => {
        loadPostList({
            page: 1,
            published: published !== undefined ? published : post.publishedAt ? true : false
        });
    };

    const openPublishedUrl = () => window.open(getPublishedUrl({ post, portal }));

    return (
        <React.Fragment>
            <CardContent border="top" background={post._unsaved && post.id ? "striped" : null}>
                {saving || loading ? (
                    <Spinner />
                ) : !post.id ? (
                    <Row gutter={["small", "small"]}>
                        <Col span={24}>
                            <Button
                                fill
                                color="primary"
                                onClick={() => handleSaveChanges({ publish: true })}
                                disabled={!post.title?.trim().length || hasContentErrors}
                            >
                                Lagre og publiser meldingen
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                fill
                                icon="save"
                                iconColor="primary"
                                size="small"
                                onClick={handleSaveChanges}
                                disabled={!post.title?.trim().length || hasContentErrors}
                            >
                                Lagre til senere
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                fill
                                size="small"
                                icon="trash"
                                iconColor="error"
                                onClick={() => setPromptReset(true)}
                                disabled={!post._unsaved}
                            >
                                Tøm skjemaet
                            </Button>
                        </Col>
                    </Row>
                ) : post._unsaved ? (
                    post.publishedAt ? (
                        <Row gutter={["small", "small"]}>
                            <Col span={24}>
                                <Button
                                    fill
                                    color="primary"
                                    disabled={hasContentErrors}
                                    onClick={handleSaveChanges}
                                >
                                    Lagre endringene
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row gutter={["small", "small"]}>
                            <Col span={24} lg={14}>
                                <Button
                                    fill
                                    color="primary"
                                    onClick={() => handleSaveChanges({ publish: true })}
                                    disabled={hasContentErrors}
                                >
                                    Lagre og publiser meldingen
                                </Button>
                            </Col>
                            <Col span={24} lg={10}>
                                <Button
                                    fill
                                    icon="save"
                                    iconColor="primary"
                                    disabled={hasContentErrors}
                                    onClick={handleSaveChanges}
                                >
                                    Lagre endringer
                                </Button>
                            </Col>
                        </Row>
                    )
                ) : (
                    <Row gutter={["small", "small"]}>
                        {/* Publish / unpublish */}
                        <Col grow span={10} md={10}>
                            {post.publishedAt ? (
                                <Button
                                    fill
                                    iconColor="primary"
                                    icon="visit"
                                    iconRight
                                    onClick={openPublishedUrl}
                                >
                                    Åpne publisert melding
                                </Button>
                            ) : (
                                <Button fill color="primary" onClick={() => handlePublish()}>
                                    Publiser meldingen
                                </Button>
                            )}
                        </Col>

                        {/* Options */}
                        <Col align={["right"]}>
                            <PopOver
                                visible={showMenu}
                                onClose={() => setShowMenu(false)}
                                padding="regular"
                                content={
                                    <Menu>
                                        {post.publishedAt ? (
                                            <MenuItem
                                                icon="link"
                                                iconColor="primary"
                                                label="Kopier meldingslenken"
                                                onClick={() => {
                                                    setShowMenu(false);
                                                    if (navigator.clipboard) {
                                                        navigator.clipboard?.writeText(
                                                            getPublishedUrl({ post, portal })
                                                        );
                                                        Toast.success("Meldingslenken er kopiert");
                                                    } else {
                                                        Toast.error("Kopiering støttes ikke");
                                                    }
                                                }}
                                            />
                                        ) : null}

                                        {/* Create short */}
                                        {post.publishedAt && (
                                            <MenuItem
                                                icon="lightning"
                                                iconColor="primary"
                                                label={
                                                    post.short ? "Rediger short" : "Opprett short"
                                                }
                                                onClick={() =>
                                                    setShowMenu(false) | setPromptShort(true)
                                                }
                                            />
                                        )}

                                        {/* Unpublish */}
                                        {post.publishedAt ? (
                                            <MenuItem
                                                icon="forbidden"
                                                iconColor="primary"
                                                label="Avpubliser melding"
                                                onClick={() =>
                                                    setShowMenu(false) | setPromptUnpublish(true)
                                                }
                                            />
                                        ) : null}

                                        {/* Publish later */}
                                        {post.id && !post._unsaved && !post.publishedAt ? (
                                            <MenuItem
                                                icon="clock"
                                                iconColor="primary"
                                                label="Publiser senere"
                                                onClick={() =>
                                                    setShowMenu(false) | setPromptPublish(true)
                                                }
                                            />
                                        ) : null}

                                        {/* Push message */}
                                        {post.publishedAt ? (
                                            <MenuItem
                                                icon="bell"
                                                iconColor="primary"
                                                label="Send push"
                                                onClick={() =>
                                                    setShowMenu(false) | setPromptPushMessage(true)
                                                }
                                            />
                                        ) : null}

                                        {/* Labrador article */}
                                        {!post.related?.labrador && (
                                            <MenuItem
                                                icon="labrador"
                                                iconColor="primary"
                                                label="Opprett Labrador-artikkel"
                                                onClick={() => setPromptCreateLabradorArticle(true)}
                                            />
                                        )}

                                        {/* Delete */}
                                        <MenuItem
                                            icon="trash"
                                            iconColor="error"
                                            label="Slett melding"
                                            onClick={() => setShowDeleteConfirm(true)}
                                        />
                                    </Menu>
                                }
                            >
                                <Button
                                    square
                                    icon="more"
                                    color={showMenu ? "primary" : null}
                                    iconColor={!showMenu ? "primary" : null}
                                    onClick={() => setShowMenu(!showMenu)}
                                />
                            </PopOver>
                        </Col>
                    </Row>
                )}
            </CardContent>

            {/* Busy message */}
            {busyMessage ? <PopupLoader label={busyMessage} /> : null}

            {/* PROMPT: Reset post form */}
            <PopupYesNo
                visible={promptReset}
                title="Tømme meldingsskjemaet?"
                onNo={() => setPromptReset(false)}
                onYes={handleResetPost}
            >
                Er du sikker på at du vil tømme alle felter i meldingeskjemaet?
            </PopupYesNo>

            {/* PROMPT: Confirm deletion */}
            <PopupYesNo
                visible={showDeleteConfirm}
                title="Slette melding?"
                onNo={() => setShowDeleteConfirm(false)}
                onYes={handleDeletePost}
            >
                Er du sikker på at du vil slette denne meldingen?
            </PopupYesNo>

            {/* PROMPT: Publish */}
            {promptPublish && (
                <PromptPublish onClose={() => setPromptPublish(false)} onPublish={handlePublish} />
            )}

            {/* PROMPT: Unpublish */}
            <PopupYesNo
                visible={promptUnpublish}
                title="Avpublisere melding?"
                onYes={handleUnpublish}
                onNo={() => setPromptUnpublish(false)}
            >
                Er du sikker på at du vil avpublisere denne meldingen?
            </PopupYesNo>

            {/* PROMPT: Convert article */}
            <PopupYesNo
                visible={promptCreateLabradorArticle}
                title="Opprette artikkel i Labrador?"
                onYes={handleCreateLabradorArticle}
                onNo={() => setPromptCreateLabradorArticle(false)}
            >
                <PopupContent>
                    <Text>
                        Er du sikker på at du vil opprette en artikkel i Labrador basert på
                        innholdet i meldingen?
                    </Text>
                </PopupContent>
            </PopupYesNo>

            {/* Create/edit short TODO: Refactor deuplicates */}
            {promptShort && <PromptShortMessage onClose={() => setPromptShort(false)} />}

            {/* PROMPT: Push notification */}
            {promptPushMessage ? (
                <PromptPushMessage
                    post={post}
                    portal={portal}
                    onClose={() => setPromptPushMessage(false)}
                    onComplete={handlePushComplete}
                />
            ) : null}
        </React.Fragment>
    );
};
