import {
    Button,
    Col,
    Input,
    Popup,
    PopupContent,
    PopupFooter,
    PopupTitle,
    Row,
    ConfirmActionButton,
    Toast
} from "@tv2no/ui-react";
import React, { useState, useContext } from "react";
import { PortalLayoutContext } from "../Layout";
import { slugify } from "../../../../../../utils/stringUtils";

export const EditPanePrompt = () => {
    const { layout, updateLayout, activePane, setActivePane, setShowPrompt } =
        useContext(PortalLayoutContext);
    const [label, setLabel] = useState(layout.panes.find((pane) => pane.key === activePane)?.label);

    const handleDeletePane = async () => {
        if (activePane === "main") return;

        // Check if pane has modules
        if (
            layout.modules.some(
                (module) => module.mobile.pane === activePane || module.desktop.pane === activePane
            )
        ) {
            return Toast.error("Du kan ikke slette en fane med moduler");
        }

        await updateLayout({
            panes: layout.panes.filter((p) => p.key !== activePane)
        });
        setActivePane("main");
        setShowPrompt(null);
    };

    const handleUpdatePane = async () => {
        const newKey = activePane === "main" ? "main" : slugify(label);
        await updateLayout({
            modules: layout.modules.map((module) => {
                return {
                    ...module,
                    ...(module.mobile.pane === activePane && {
                        mobile: {
                            ...module.mobile,
                            pane: newKey
                        }
                    }),
                    ...(module.desktop.pane === activePane && {
                        desktop: {
                            ...module.desktop,
                            pane: newKey
                        }
                    })
                };
            }),
            panes: layout.panes.map((pane) => {
                if (pane.key === activePane) {
                    return {
                        ...pane,
                        label,
                        key: newKey
                    };
                } else {
                    return pane;
                }
            })
        });
        setActivePane(newKey);
        setShowPrompt(null);
    };

    return (
        <Popup onClose={() => setShowPrompt(null)}>
            <PopupTitle>Rediger fane</PopupTitle>
            <PopupContent>
                <Row>
                    <Col span={24}>
                        <Input
                            value={label}
                            onChange={setLabel}
                            placeholder="Navnet på fanen"
                            maxLength={20}
                        />
                    </Col>
                    {activePane !== "main" && (
                        <Col span={24}>
                            <ConfirmActionButton
                                label="Slett fanen"
                                question="Er du sikker på at du vil fjerne denne fanen?"
                                onConfirm={handleDeletePane}
                            />
                        </Col>
                    )}
                </Row>
            </PopupContent>
            <PopupFooter>
                <Button onClick={handleUpdatePane} disabled={!label || label?.length < 3}>
                    Lagre fane
                </Button>
            </PopupFooter>
        </Popup>
    );
};
