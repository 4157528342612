import {
    AuthRestrict,
    Button,
    Card,
    CardContent,
    CardTitle,
    Col,
    Form,
    FormItem,
    Input,
    Option,
    Row,
    Select,
    Text,
    Textarea,
    Toast
} from "@tv2no/ui-react";
import React, { useEffect, useState } from "react";
import { POST_CATEGORIES } from "../../../../constants";
import { updatePortal } from "../../../../data/portal/portal";
import { isValidSlug, validateBaseUrl } from "../../../../utils/stringUtils";

export const Details = ({ onUpdatePortal, ...props }) => {
    const [portal, setPortal] = useState(props.portal);
    const [busySaving, setBusySaving] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setPortal(props.portal);
        setUnsavedChanges(false);
    }, [props.portal]);

    const handleChangeProperty = (key, value) => {
        setUnsavedChanges(true);
        setError(null);
        setPortal({
            ...portal,
            [key]: value
        });
    };

    const handleSave = async () => {
        setError(null);

        // Validate slug
        if (!isValidSlug(portal.slug)) {
            return setError({
                field: "slug",
                message:
                    "Ikke en gyldig lenke. Lenker skal ikke inneholde mellomrom, store bokstaver eller spesialtegn som æøå"
            });
        }

        // Validate base URL
        const baseUrlValidationError = portal?.baseUrl && validateBaseUrl(portal.baseUrl);
        if (baseUrlValidationError) {
            setError({ field: "baseUrl", message: baseUrlValidationError });
            Toast.error(baseUrlValidationError);
            return;
        }

        // Override theme if missing or not matching with category
        const themeChoices = POST_CATEGORIES[portal.category].themes;
        if (!portal.theme || !themeChoices.find((t) => t.key === portal.theme)) {
            portal.theme = themeChoices[0].key;
        }

        setBusySaving(true);
        updatePortal(portal.id, {
            name: portal.name,
            category: portal.category,
            slug: portal.slug,
            description: portal.description,
            theme: portal.theme,
            adUnitPath: portal.adUnitPath,
            baseUrl: portal.baseUrl
        })
            .then((response) => {
                onUpdatePortal(response);
                setUnsavedChanges(false);
                setBusySaving(false);
                Toast.success("Portalen ble lagret");
            })
            .catch((err) => {
                setBusySaving(false);
                if (err.statusCode === 409) {
                    setError({
                        field: "slug",
                        message: "Det finnes allerede en portal med denne lenken."
                    });
                } else {
                    Toast.error("Det oppstod en feil ved lagring");
                }
            });
    };

    return (
        <Card>
            <CardTitle title="Detaljer" />
            <CardContent>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="Navn">
                                        <Input
                                            value={portal.name}
                                            onChange={(val) => handleChangeProperty("name", val)}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="Beskrivelse">
                                        <Textarea
                                            value={portal.description}
                                            onChange={(val) =>
                                                handleChangeProperty("description", val)
                                            }
                                            maxLength={200}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="Kategori">
                                        <Select
                                            value={portal.category}
                                            placeholder="Velg kategori"
                                            onChange={(val) =>
                                                handleChangeProperty("category", val)
                                            }
                                        >
                                            {Object.keys(POST_CATEGORIES).map((key) => (
                                                <Option key={key} value={key}>
                                                    {POST_CATEGORIES[key].label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        label="Lenke"
                                        error={error?.field === "slug" ? error?.message : null}
                                    >
                                        <Input
                                            prepend={<Text opacity={60}>/</Text>}
                                            value={portal.slug}
                                            onChange={(val) => handleChangeProperty("slug", val)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </CardContent>
            <AuthRestrict userRole="admin">
                <CardContent border="top">
                    <Row>
                        <Col md={12}>
                            <Row gutter={["small", "small"]}>
                                <Col span={24}>
                                    <FormItem
                                        label="Base URL"
                                        error={error?.field === "baseUrl" ? error?.message : null}
                                    >
                                        <Input
                                            value={portal.baseUrl}
                                            onChange={(val) => handleChangeProperty("baseUrl", val)}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <Text opacity={60}>
                                        For portaler som skal bo utenfor Direktesenteret oppgir du
                                        absolutt-URL til der meldingsfeeden for portalen ligger.
                                        Dette styrer hvor push-meldinger og meldingers delelenker
                                        peker til.
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row gutter={["small", "small"]}>
                                <Col span={24}>
                                    <FormItem label="adUnitPath">
                                        <Input
                                            value={portal.adUnitPath}
                                            onChange={(val) =>
                                                handleChangeProperty("adUnitPath", val)
                                            }
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <Text opacity={60}>
                                        Verdien angir hvilke annonser som skal benyttes i portalen.
                                        Ikke endre hvis du ikke vet hva den brukes til.
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardContent>
            </AuthRestrict>
            <CardContent border="top">
                <Button
                    color="primary"
                    disabled={!unsavedChanges}
                    onClick={() => handleSave(setBusySaving)}
                    busy={busySaving}
                >
                    Lagre endringene
                </Button>
            </CardContent>
        </Card>
    );
};
