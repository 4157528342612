import React, { ReactNode, createContext, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getPostList } from "../data/post/post";
import useDebounce from "../hooks/useDebounce";
import { searchPosts } from "../data/search/posts";

const initialQuery = {
    page: 1,
    limit: 20,
    published: true,
    sort: "pinned",
    search: "",
    searching: false
};

export const PostListContext = createContext({});

export const PostListContextProvider = ({ children }: { children: ReactNode }) => {
    const { portalId } = useParams();
    const [search, setSearch] = useSearchParams();
    const [currentPortalId, setCurrentPortalId] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<unknown>([]);
    const [query, setQuery] = useState(initialQuery);
    const [allSearchResults, setAllSearchResults] = useState<unknown[]>([]);
    const [searchResults, setSearchResults] = useState<null | unknown[]>(null);
    const [searchResultPage, setSearchResultPage] = useState(0);
    const [hasMoreSearchResults, setHasMoreSearchResults] = useState(false);

    const SEARCH_RESULT_PAGE_LIMIT = 10;
    const SEARCH_RESULT_MAX_PAGES = 3;
    const SEARCH_QUERY_LENGTH_THRESHOLD = 3;
    const SEARCH_QUERY_MAX_LENGTH = 100;

    const loadPostList = (changes: { page: number; published?: boolean; search?: string }) => {
        setError(null);
        setLoading(true);
        if (changes.page === 1) {
            setData(null);
            resetSearchResults();
        }
        setQuery((query) => ({
            ...query,
            ...changes
        }));
    };

    const loadAdditionalSearchResults = () => {
        if (searchResultPage >= SEARCH_RESULT_MAX_PAGES) return;
        if (!allSearchResults || !searchResults) return;
        const currentPage = searchResultPage + 1;
        const start = currentPage * SEARCH_RESULT_PAGE_LIMIT;
        const nextSearchResults = allSearchResults.slice(start, start + SEARCH_RESULT_PAGE_LIMIT);
        setSearchResults(searchResults.concat(nextSearchResults));
        setSearchResultPage(currentPage);
        setHasMoreSearchResults(allSearchResults.length > start + SEARCH_RESULT_PAGE_LIMIT);
    };

    const resetSearchResults = () => {
        setSearchResults(null);
        setAllSearchResults([]);
        setSearchResultPage(0);
        setHasMoreSearchResults(false);
    };

    useDebounce(
        async () => {
            setLoading(true);
            setError(null);

            if (currentPortalId !== portalId) {
                setData(null);
                if (portalId) {
                    setCurrentPortalId(portalId);
                }
                setQuery(initialQuery);
                return;
            }

            try {
                if (!query.search) {
                    const postList = await getPostList({
                        portalId: portalId,
                        page: query.page,
                        limit: query.limit,
                        published: query.published,
                        sort: query.sort,
                        search: query.search
                    });

                    setData((existingData: { docs: unknown[] }) => {
                        if (postList?.page > 1) {
                            postList.docs = existingData.docs.concat(postList.docs);
                        }
                        return postList;
                    });
                } else {
                    if (query.search.length < SEARCH_QUERY_LENGTH_THRESHOLD) {
                        setSearchResults(null);
                        return;
                    }
                    if (query.search.length <= SEARCH_QUERY_MAX_LENGTH) {
                        const postList = await searchPosts(query.search, query.published, portalId);
                        setAllSearchResults(postList);
                        setSearchResults(postList?.slice(0, SEARCH_RESULT_PAGE_LIMIT));
                        if (postList?.length > SEARCH_RESULT_PAGE_LIMIT) {
                            setHasMoreSearchResults(true);
                        }
                    }
                }
            } catch (error) {
                setData(null);
                setError(error);
            } finally {
                setLoading(false);
            }
        },
        [query, portalId, currentPortalId],
        { delay: 200 }
    );

    return (
        <PostListContext.Provider
            value={{
                loading,
                error,
                data,
                search,
                setSearch,
                query,
                loadPostList,
                searchResults,
                loadAdditionalSearchResults,
                hasMoreSearchResults,
                SEARCH_QUERY_LENGTH_THRESHOLD,
                SEARCH_QUERY_MAX_LENGTH
            }}
        >
            {children}
        </PostListContext.Provider>
    );
};
