import {
    Textarea,
    Col,
    Row,
    Popup,
    PopupTitle,
    PopupContent,
    PopupFooter,
    ButtonRow,
    Button,
    Clickable
} from "@tv2no/ui-react";
import React, { useState } from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";

const Base = styled.div`
    position: relative;
`;

export const IframeContent = ({ entry, onChange, editMode, setEditMode, onDelete }) => {
    const [markup, setMarkup] = useState(entry?.data?.markup || "");

    const sanitizeIframe = (dirtyHtml) =>
        sanitizeHtml(dirtyHtml, {
            allowedTags: ["iframe"],
            allowedAttributes: {
                iframe: [
                    "title",
                    "src",
                    "data-*",
                    "width",
                    "height",
                    "scrolling",
                    "frameborder",
                    "style"
                ]
            },
            parseStyleAttributes: false,
            textFilter: (_text) => ""
        });

    const handleChange = (markup) => {
        onChange({
            data: { markup }
        });
        setEditMode(false);
    };

    const handleClose = () => {
        setMarkup(entry?.data?.markup || "");
        if (entry.data?.markup) {
            setEditMode(false);
        } else {
            onDelete();
        }
    };

    return (
        <Base>
            <Row gutter={["tiny", "tiny"]}>
                {entry.data?.markup ? (
                    <Col span="24">
                        <div dangerouslySetInnerHTML={{ __html: entry.data?.markup }}></div>
                    </Col>
                ) : (
                    <Clickable onClick={() => setEditMode(true)}>
                        Klikk for å legge til iframe-innhold
                    </Clickable>
                )}
            </Row>

            {(editMode || !entry.data?.markup) && (
                <Popup size="medium" onClose={handleClose}>
                    <PopupTitle>Sett inn HTML (iframe)</PopupTitle>
                    <PopupContent>
                        <Row gutter={["small", "small"]}>
                            <Col span="24">
                                <div dangerouslySetInnerHTML={{ __html: markup }}></div>
                            </Col>

                            <Col span="24">
                                <Textarea
                                    value={markup}
                                    placeholder="Lim inn <iframe></iframe> her"
                                    onChange={(x) => setMarkup(sanitizeIframe(x))}
                                />
                            </Col>
                        </Row>
                    </PopupContent>
                    <PopupFooter>
                        <ButtonRow>
                            <Button disabled={!markup} onClick={() => handleChange(markup)}>
                                Lagre
                            </Button>
                        </ButtonRow>
                    </PopupFooter>
                </Popup>
            )}
        </Base>
    );
};
