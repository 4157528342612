import React, { useContext, useState } from "react";
import {
    Popup,
    PopupTitle,
    PopupContent,
    PopupFooter,
    Form,
    FormItem,
    Input,
    Button,
    Row,
    Col,
    Select,
    Option,
    Callout,
    Text
} from "@tv2no/ui-react";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../../utils/stringUtils";
import { TENANT_KEYS } from "../../../constants";
import { createFeed } from "../../../data/feed/feed";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";

export const NewFeed = ({ onClose }) => {
    const navigate = useNavigate();
    const { reloadFeeds } = useContext(GlobalAppContext);
    const [name, setName] = useState(null);
    const [slug, setSlug] = useState(null);
    const [channelId, setChannelId] = useState(null);
    const [tenantKey, setTenantKey] = useState(null);
    const [busyCreating, setBusyCreating] = useState(false);
    const [error, setError] = useState(null);
    const [existingFeed, setExistingFeed] = useState(null);

    const handleNameChange = (name) => {
        setName(name);
        setSlug(slugify(name));
    };

    const handleCreate = () => {
        setError(null);
        setExistingFeed(null);
        setBusyCreating(true);
        createFeed({
            name,
            slug,
            tenantKey,
            channelId,
            tags: [],
            autoPortals: []
        })
            .then((response) => {
                setBusyCreating(false);
                onClose();
                navigate("/feeds");
                reloadFeeds();
            })
            .catch((response) => {
                if (response.statusCode === 409) {
                    setError("En feed med dette Feed-ID og denne kilden eksisterer allerede:");
                    setExistingFeed(response.target);
                } else {
                    setError("Det oppstod en feil ved opprettelse av feed.");
                }
                setBusyCreating(false);
            });
    };
    return (
        <Popup onClose={onClose}>
            <PopupTitle title="Ny feed">Ny feed</PopupTitle>
            <PopupContent>
                <Form>
                    <Row>
                        <Col span={24}>
                            <FormItem label="Navn">
                                <Input value={name} onChange={handleNameChange} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="Feed ID" info="Norkon Channel-ID.">
                                <Input value={channelId} onChange={setChannelId} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="Kilde" info="Norkon Tenant-Key.">
                                <Select
                                    value={tenantKey}
                                    onChange={setTenantKey}
                                    placeholder="Velg kilde"
                                >
                                    {TENANT_KEYS.map((key) => (
                                        <Option key={key} value={key}>
                                            {key}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        {error ? (
                            <Col span={24}>
                                <Callout color="error">
                                    {error}
                                    {existingFeed && (
                                        <Text weight="bold"> &quot;{existingFeed.name}&quot;</Text>
                                    )}
                                </Callout>
                            </Col>
                        ) : null}
                    </Row>
                </Form>
            </PopupContent>
            <PopupFooter>
                <Button
                    color="primary"
                    type="submit"
                    onClick={handleCreate}
                    busy={busyCreating}
                    disabled={!channelId?.trim().length || !name?.trim().length || !tenantKey}
                >
                    Opprett ny feed
                </Button>
            </PopupFooter>
        </Popup>
    );
};
