import {
    Badge,
    Button,
    Card,
    CardContent,
    Col,
    Row,
    ScrollableArea,
    Spinner,
    Text
} from "@tv2no/ui-react";
import React, { useContext } from "react";
import { PostEditContext } from "../../../contexts/PostEditContext";
import { formatTitle } from "../../../utils/stringUtils";
import { PostByline } from "./parts/PostByline";
import { PostContentAdder } from "./parts/PostContentAdder";
import { PostContentEntry } from "./parts/PostContentEntry";
import { PostContentList } from "./parts/PostContentList";
import { PostControls } from "./parts/PostControls";
import { PostDetails } from "./parts/PostDetails";
import { PostNotFoundMessage } from "./parts/PostNotFoundMessage";
import { PostPortals } from "./parts/PostPortals";
import { PostRelated } from "./parts/PostRelated";
import { PostTopics } from "./parts/PostTopics";
import { PostFlags } from "./parts/PostFlags";
import { TitleSuggestions } from "./parts/TitleSuggestions";

export const PostEditor = () => {
    const debug = !!new URL(document.location).searchParams.get("debug");
    const { post, addContent, closePost, changePost, loading, error } = useContext(PostEditContext);

    return (
        <React.Fragment>
            <Row>
                <Col span={24}>
                    <Row gutter={["tiny", "tiny"]}>
                        <Col span={12}>
                            <Text size="large" weight="bold">
                                {post?.id ? "Rediger melding" : "Ny melding"}
                            </Text>
                            {/* To fix alignment */}
                            <span style={{ display: "inline-block", maxWidth: "1px", opacity: 0 }}>
                                <Button size="small" />
                            </span>
                        </Col>

                        {post?.id && (
                            <Col span={12} align={["right", "right"]}>
                                <Button
                                    size="small"
                                    icon="close"
                                    iconColor="primary"
                                    color="silent"
                                    onClick={closePost}
                                >
                                    Lukk
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>

                {/* DEBUG: Post object */}
                {debug && (
                    <Col span={24}>
                        <Card>
                            <ScrollableArea>
                                <CardContent>
                                    <pre>{JSON.stringify(post, null, 2)}</pre>
                                </CardContent>
                            </ScrollableArea>
                        </Card>
                    </Col>
                )}

                <Col span={24}>
                    {error ? (
                        <PostNotFoundMessage closePost={closePost} />
                    ) : loading || !post ? (
                        <Card>
                            <CardContent>
                                <Spinner />
                            </CardContent>
                        </Card>
                    ) : (
                        <Card>
                            {/* Title */}
                            <CardContent>
                                <Row gutter={["small", "small"]}>
                                    {post?.id && !post?.publishedAt && (
                                        <Col span={24}>
                                            <Badge color="error" weight="bold">
                                                Upublisert
                                            </Badge>
                                        </Col>
                                    )}

                                    <Col span={24}>
                                        <Text
                                            block
                                            size="h3"
                                            weight="bold"
                                            value={post.title}
                                            placeholder="Tittel på meldingen..."
                                            onChange={(val) =>
                                                changePost({
                                                    title: formatTitle(val, 100)
                                                })
                                            }
                                        />

                                        {/*  Byline */}
                                        <PostByline />
                                    </Col>
                                </Row>
                            </CardContent>

                            <CardContent padding="none">
                                <TitleSuggestions
                                    post={post}
                                    onTitleSelect={(title) => changePost({ title })}
                                />
                            </CardContent>

                            {/* Content */}
                            <PostContentList>
                                {post.content
                                    ?.filter((e) => !e._deleted)
                                    .map((entry, i) => (
                                        <PostContentEntry
                                            key={entry.id || entry._tempId}
                                            entry={entry}
                                            isFirst={i === 0}
                                            isLast={
                                                i ===
                                                post.content?.filter((c) => !c._deleted).length - 1
                                            }
                                        />
                                    ))}
                            </PostContentList>

                            {/* Adder */}
                            <PostContentAdder onAddContent={addContent} />

                            <CardContent border="top">
                                <Row gutter={["small", "small"]}>
                                    <Col span={24}>
                                        <PostTopics
                                            post={post}
                                            onChange={(topics) => {
                                                changePost({
                                                    topics
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <PostPortals />
                                    </Col>
                                    <Col span={24}>
                                        <PostFlags />
                                    </Col>
                                </Row>
                            </CardContent>

                            <PostControls />
                            <PostRelated />
                            <PostDetails />
                        </Card>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};
