/**
 * Keys for local storage
 */
export const STORAGE_KEYS = {
    THUMB_SIZE: "tv2no-breaking_media-thumb_size"
};

/**
 * Set data in local storage
 * @param {*} key
 * @param {*} value
 */
export const setStorageData = (key, value) => {
    let _localStorage;
    try {
        _localStorage = localStorage || window.localStorage;

        if (_localStorage) {
            if (value) {
                _localStorage.setItem(key, JSON.stringify(value));
            } else {
                _localStorage.removeItem(key);
            }
        }
    } catch (ex) {
        console.warn("localStorage not available, using fallback storage.", ex);
    }
};

/**
 * Get data from local storage
 * @param {*} key
 * @returns
 */
export const getStorageData = (key) => {
    let value = undefined;
    try {
        let _localStorage = localStorage || window.localStorage;

        if (_localStorage && _localStorage[key]) {
            value = JSON.parse(_localStorage[key]);
        }
    } catch (ex) {
        console.log("Problem getting %s from localStorage", key, localStorage);
    }

    return value;
};
