import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
    Action,
    Badge,
    Card,
    CardAdder,
    CardContent,
    Col,
    Page,
    PageActions,
    Row,
    Text
} from "@tv2no/ui-react";
import { NewFeed } from "./parts/NewFeed";
import { EditFeed } from "./parts/EditFeed";
import { GlobalAppContext } from "../../contexts/GlobalAppContext";

export const FeedSettings = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { feedList } = useContext(GlobalAppContext);
    const { setPageTitle } = useContext(GlobalAppContext);

    useEffect(() => {
        setPageTitle("Feed innstillinger");
        return () => {
            setPageTitle(null);
        };
    }, [setPageTitle]);

    const [feed, setFeed] = useState(null);

    const handleNavigate = (feed) => {
        setFeed(feed);
        setSearchParams({ promptEdit: true });
    };

    return (
        <Page>
            <PageActions extended={false} title="Handlinger">
                <Action
                    icon="plus-small"
                    label="Ny feed"
                    onClick={() => setSearchParams({ promptNew: true })}
                />
                <Action icon="info" label="Hjelp">
                    Opprett en ny Norkon Live-Feed.
                </Action>
            </PageActions>
            <Row>
                {feedList?.docs?.map((feed) => (
                    <Col key={feed.id} xs={24} sm={12} md={8} lg={6}>
                        <Card fill onClick={() => handleNavigate(feed)}>
                            <CardContent grow>
                                <Text block size="large" weight="bold">
                                    {feed.name}
                                </Text>
                                <Text block opacity={60}>
                                    {feed.channelId}
                                </Text>
                            </CardContent>
                            <CardContent border="top">
                                <Badge fill customColor="#EB0E2A">
                                    {feed.tenantKey}
                                </Badge>
                            </CardContent>
                        </Card>
                    </Col>
                ))}
                {/* New Feed */}
                <Col xs={24} sm={12} md={8} lg={6}>
                    <CardAdder
                        fill
                        minHeight="10em"
                        onClick={() => setSearchParams({ promptNew: true })}
                    />
                </Col>
            </Row>
            {/* PROMPT: New feed */}
            {searchParams.get("promptNew") && (
                <NewFeed onClose={() => setSearchParams(searchParams.delete("promptNew"))} />
            )}
            {/* PROMPT: Edit feed */}
            {searchParams.get("promptEdit") && (
                <EditFeed
                    feed={feed}
                    onClose={() => setSearchParams(searchParams.delete("promptEdit"))}
                />
            )}
        </Page>
    );
};
