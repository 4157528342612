import {
    AppBar,
    AppContent,
    AppContext,
    AppLayout,
    AppNav,
    AuthContext,
    Clickable,
    MenuItem,
    Text,
    UserMenu
} from "@tv2no/ui-react";
import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { GlobalAppContext } from "../../contexts/GlobalAppContext";
import { UploadContextProvider } from "../../contexts/UploadContext";
import { PortalEntry } from "../portals/entry/PortalEntry";
import { PortalList } from "../portals/list/PortalList";
import { PortalMenu } from "./parts/PortalMenu";
import { FeedSettings } from "../feeds/FeedSettings";

export const App = () => {
    const auth = useContext(AuthContext);
    const { pageTitle } = useContext(GlobalAppContext);
    const { pageInfo } = useContext(AppContext);
    const isMobile = ["mobile", "tablet"].indexOf(pageInfo?.breakpoint) >= 0;

    return (
        <UploadContextProvider>
            <AppLayout>
                <AppBar title={(isMobile && pageTitle) || "Direkte"}>
                    {!isMobile && (
                        <AppBar.Item placeLeft>
                            <h3 style={{ margin: 0 }}>{pageTitle || "Direkte"}</h3>
                        </AppBar.Item>
                    )}
                    {auth?.data ? (
                        <AppBar.Item placeRight>
                            <UserMenu name={auth.data.fullName} about={auth.data.email}>
                                <MenuItem icon="sign-out" label="Logg ut" onClick={auth.logout} />
                            </UserMenu>
                        </AppBar.Item>
                    ) : null}
                </AppBar>
                <AppNav
                    title={
                        <Clickable link="/portals">
                            <Text block size="large" weight="bold" color="contrast">
                                Direkte
                            </Text>
                        </Clickable>
                    }
                >
                    <PortalMenu />
                    <AppNav.MenuItem icon="gear" link="/feeds">
                        <Text weight="light">Feed innstillinger</Text>
                    </AppNav.MenuItem>
                </AppNav>
                <AppContent>
                    <Routes>
                        <Route path="/portals" element={<PortalList />} />
                        <Route path="/portals/:portalId/*" element={<PortalEntry />} />
                        <Route path="/feeds" element={<FeedSettings />} />
                        <Route path="/" element={<Navigate replace to="/portals" />} />
                    </Routes>
                </AppContent>
            </AppLayout>
        </UploadContextProvider>
    );
};
