export const POST_CATEGORIES = {
    NYHETER: {
        label: "Nyheter",
        color: "#EB0E2A",
        mobileApp: {
            name: "TV 2.no-appen"
        },
        themes: [
            {
                key: "NYHETER",
                label: "Nyheter - mørk",
                color: "#EB0E2A"
            },
            {
                key: "NYHETER_LIGHT",
                label: "Nyheter - lys",
                color: "#EB0E2A"
            },
            {
                key: "CRISIS",
                label: "Krise",
                color: "#FFFF2B"
            },
            {
                key: "MOURNING",
                label: "Sorg",
                color: "#E5C165"
            },
            {
                key: "ELECTION",
                label: "Valg",
                color: "#B2AEFF"
            }
        ]
    },
    SPORT: {
        label: "Sport",
        color: "#212DFF",
        mobileApp: {
            name: "Sportsappen"
        },
        themes: [
            {
                key: "SPORT",
                label: "Sport (mørk)",
                color: "#212DFF"
            },
            {
                key: "SPORT_LIGHT",
                label: "Sport (lys)",
                color: "#212DFF"
            }
        ]
    },
    UNDERHOLDNING: {
        label: "Underholdning",
        color: "#6f03ff",
        mobileApp: {
            name: "TV 2.no-appen"
        },
        themes: [
            {
                key: "UNDERHOLDNING",
                label: "Underholdning (mørk)",
                color: "#6f03ff"
            },
            {
                key: "UNDERHOLDNING_LIGHT",
                label: "Underholdning (lys)",
                color: "#6f03ff"
            }
        ]
    }
};
export const TENANT_KEYS = ["NTB"];
