import { useCallback, useEffect } from "react";

const commandOrCtrlIsPressed = (e) =>
    navigator?.platform?.toLowerCase().includes("mac") ? e.metaKey : e.ctrlKey;

const useKeyPress = ({ key, withCommandOrControl = false, action = () => {} }) => {
    const onKeyDown = useCallback(
        (e) => {
            if (
                !withCommandOrControl &&
                document.activeElement &&
                document.activeElement.tagName !== "BODY"
            ) {
                return;
            }

            if (e.key === key) {
                if (
                    (!commandOrCtrlIsPressed(e) && withCommandOrControl) ||
                    (commandOrCtrlIsPressed(e) && !withCommandOrControl)
                ) {
                    return;
                } else if (commandOrCtrlIsPressed(e) && withCommandOrControl) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                return action();
            }
        },
        [key, withCommandOrControl, action]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);
};

export default useKeyPress;
