import { Row, Col, Input, Text } from "@tv2no/ui-react";
import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { PostEditContext } from "../../../contexts/PostEditContext";

const Base = styled.div`
    position: relative;
`;

const DatawrapperContainer = styled.div`
    border-radius: 16px;
    padding: 12px 16px;
`;

export const DatawrapperContent = ({ entry, onChange }) => {
    const { addContentError, removeContentError, contentErrors } = useContext(PostEditContext);
    const error = contentErrors[entry._tempId || entry.id];
    const darkMode = entry?.data?.url?.includes("?dark=true");

    useEffect(() => {
        const handleMessage = (e) => {
            const height = e.data["datawrapper-height"];

            if (height) {
                Object.keys(height).forEach((key) => {
                    const element = Array.from(document.getElementsByTagName("iframe")).find(
                        (iframe) => iframe.contentWindow === e.source
                    );
                    element.style.height = `${height[key]}px`;
                });
            }
        };

        window.addEventListener("message", handleMessage);

        return () => window.removeEventListener("message", handleMessage);
    }, []);

    const validateUrl = (url) => {
        const match = /^https:\/\/datawrapper.dwcdn.net\/(.*)\/(\d+)\/(\?dark=true)?$/.test(
            url ?? ""
        );

        if (match) {
            removeContentError(entry);
            onChange({
                data: { url }
            });
        } else {
            addContentError({
                entry,
                message:
                    'Ugyldig URL. Pass på å velge "Visualization only" før du kopierer fra Datawrapper'
            });
            onChange({
                data: { url: "" }
            });
        }
    };

    return (
        <Base>
            <Row gutter={["tiny", "tiny"]}>
                {!!entry?.data?.url && (
                    <Col span={24}>
                        <DatawrapperContainer
                            style={{
                                background: darkMode ? "#3a2f5c" : "#d9d8ed",
                                colorScheme: darkMode ? "dark" : "light"
                            }}
                        >
                            <iframe
                                title="Visualisering"
                                src={entry.data.url}
                                scrolling="no"
                                width="100%"
                                height="500"
                                frameBorder="0"
                            />
                        </DatawrapperContainer>
                    </Col>
                )}
                <Col span="24">
                    <Input
                        value={entry.data?.url}
                        type="text"
                        size="small"
                        icon="menu-dots"
                        placeholder='URL til "Visualization only" i Datawrapper'
                        onChange={validateUrl}
                    />

                    {error && <Text color="error">{error}</Text>}
                </Col>
            </Row>
        </Base>
    );
};
