import { BitsModuleView, Button, Menu, MenuItem, PopOver } from "@tv2no/ui-react";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { LAYOUT_PROMPTS, PortalLayoutContext } from "../Layout";
import { assignNewOrderCount, moveModule } from "../helpers";

const Base = styled.div`
    position: relative;
    display: flex;
    padding: 0.75rem 0.2rem 0.75rem 1.5rem;

    &.active {
        background: repeating-linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.02),
            rgba(0, 0, 0, 0.02) 10px,
            rgba(0, 0, 0, 0.05) 10px,
            rgba(0, 0, 0, 0.05) calc(10px * 2)
        );
    }

    &.disabled {
        box-shadow: inset 5px 0 rgba(var(--color_error), 1);
        background: repeating-linear-gradient(
            45deg,
            rgba(var(--color_error), 0.08),
            rgba(var(--color_error), 0.08) 10px,
            rgba(var(--color_error), 0.15) 10px,
            rgba(var(--color_error), 0.15) calc(10px * 2)
        );
    }
`;

const Content = styled.div`
    position: relative;
    flex-grow: 1;
    padding: 0;
`;

const Actions = styled.div`
    padding: 0.2rem;
    display: flex;
    align-items: center;
`;

export const ModuleEntry = ({ module, isFirst, isLast }) => {
    const { layout, viewDevice, activePane, updateLayout, setShowPrompt } =
        useContext(PortalLayoutContext);
    const [showMenu, setShowMenu] = useState(false);

    const handleUpdateModule = (changes) => {
        updateLayout({
            modules: layout.modules.map((m) => {
                if (m._id === module._id) {
                    return {
                        ...m,
                        ...(changes?.instanceId
                            ? {
                                  instanceId: changes.instanceId,
                                  fields: null
                              }
                            : {
                                  instanceId: null,
                                  fields: changes.fields
                              })
                    };
                } else {
                    return m;
                }
            })
        });
        setShowMenu(false);
    };

    const handleDeleteModule = () => {
        updateLayout({
            modules: assignNewOrderCount({
                modules: layout.modules.filter((m) => m._id !== module._id),
                viewDevice,
                ...(module[viewDevice]?.column
                    ? { column: module[viewDevice]?.column }
                    : { pane: activePane })
            })
        });
        setShowMenu(false);
    };

    const handleMoveModule = () => {
        setShowPrompt({ type: LAYOUT_PROMPTS.moveModule, data: module });
        setShowMenu(false);
    };

    const handleSwapColumn = () => {
        updateLayout({
            modules: moveModule({
                modules: layout.modules,
                module,
                viewDevice,
                toColumn: module[viewDevice]?.column === "RIGHT" ? null : "RIGHT",
                toPane: activePane
            })
        });
        setShowPrompt(null);
    };

    const handleToggleVisibility = (visible) => {
        updateLayout({
            modules: layout.modules.map((m) => {
                if (m._id === module._id) {
                    return {
                        ...m,
                        visible
                    };
                } else {
                    return m;
                }
            })
        });
        setShowMenu(false);
    };

    const handleMoveUpOrDown = (up) => {
        setShowMenu(false);
        const updatedModules = layout.modules.map((m) => {
            if (m._id === module._id) {
                return {
                    ...m,
                    [viewDevice]: {
                        ...m[viewDevice],
                        order: up ? m[viewDevice].order - 1.5 : m[viewDevice].order + 1.5
                    }
                };
            } else {
                return m;
            }
        });
        updateLayout({
            modules: assignNewOrderCount({
                modules: updatedModules,
                viewDevice,
                ...(module[viewDevice]?.column
                    ? { column: module[viewDevice]?.column }
                    : { pane: activePane })
            })
        });
    };

    return (
        <Base
            className={classNames({ first: isFirst, active: showMenu, disabled: !module.visible })}
        >
            <Content>
                <BitsModuleView
                    editable
                    useKey={module.key}
                    version={module.version}
                    instanceId={module.instanceId}
                    fields={module.fields}
                    onChange={(changes) => handleUpdateModule(changes)}
                />
            </Content>
            <Actions>
                <PopOver
                    visible={showMenu}
                    onClose={() => setShowMenu(false)}
                    padding="regular"
                    content={
                        <Menu>
                            {module.visible ? (
                                <MenuItem
                                    icon="close"
                                    iconColor="error"
                                    label="Skjul modul"
                                    onClick={() => handleToggleVisibility(false)}
                                />
                            ) : (
                                <MenuItem
                                    icon="check"
                                    iconColor="success"
                                    label="Aktiver modul"
                                    onClick={() => handleToggleVisibility(true)}
                                />
                            )}
                            {!isFirst ? (
                                <MenuItem
                                    icon="arrow-up"
                                    label="Flytt opp"
                                    onClick={() => handleMoveUpOrDown(true)}
                                />
                            ) : null}
                            {!isLast ? (
                                <MenuItem
                                    icon="arrow-down"
                                    label="Flytt ned"
                                    onClick={() => handleMoveUpOrDown(false)}
                                />
                            ) : null}
                            <MenuItem
                                icon="bookmark"
                                label="Flytt til fane"
                                onClick={handleMoveModule}
                            />
                            {viewDevice === "desktop" && (
                                <MenuItem
                                    icon={module["desktop"]?.column === "RIGHT" ? "undo" : "redo"}
                                    label="Bytt kolonne"
                                    onClick={handleSwapColumn}
                                />
                            )}
                            <MenuItem
                                icon="trash"
                                iconColor="error"
                                label="Slett modulen"
                                onClick={handleDeleteModule}
                            />
                        </Menu>
                    }
                >
                    <Button
                        circle
                        icon="dot-matrix"
                        color={showMenu ? "primary" : "transparent"}
                        iconColor={showMenu ? null : module.visible ? "primary" : "error"}
                        size="small"
                        onClick={() => setShowMenu(!showMenu)}
                    />
                </PopOver>
            </Actions>
        </Base>
    );
};
