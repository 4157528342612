import {
    Button,
    Col,
    Input,
    ItemList,
    PopOver,
    Popup,
    PopupContent,
    PopupFooter,
    PopupTitle,
    Row,
    Spinner,
    Text,
    Toast
} from "@tv2no/ui-react";
import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { PostEditContext } from "../../../../contexts/PostEditContext";
import { searchInternalUsers } from "../../../../data/external/internal-user";
import useDebounce from "../../../../hooks/useDebounce";
import { moveArrayItem } from "../../../../utils/arrayUtils";
import { isValidEmail, randomString } from "../../../../utils/stringUtils";

const Base = styled.div`
    position: relative;
    margin-top: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.85rem;

    &:hover {
        color: inherit;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const PostByline = () => {
    const { post, changePost } = useContext(PostEditContext);
    const [edit, setEdit] = useState(false);
    return (
        <React.Fragment>
            <Base onClick={() => setEdit(true)}>
                <Row gutter={["tiny", "tiny"]}>
                    <Col>Av:</Col>
                    {post?.byline?.map((entry, index) => (
                        <Col key={index}>{`${
                            entry?.lastName
                                ? `${entry.firstName} ${entry.lastName}`
                                : entry.firstName
                        }${index < post?.byline.length - 1 ? ", " : ""}`}</Col>
                    ))}
                </Row>
            </Base>
            {edit && (
                <EditBylineForm
                    byline={post?.byline}
                    onSave={(byline) => changePost({ byline })}
                    onClose={() => setEdit(false)}
                />
            )}
        </React.Fragment>
    );
};

const EditBylineForm = ({ onClose, onSave, ...rest }) => {
    const [byline, setByline] = useState(assignBylineKeys(rest.byline || [{}]));
    const [searchTerm, setSearchTerm] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [busySearching, setBusySearching] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        setHasErrors(
            byline?.filter((e) => e._invalidFirstName || e._invalidLastName || e._invalidEmail)
                ?.length > 0
        );
    }, [byline]);

    const handleChange = (index, key, value) => {
        setUnsavedChanges(true);
        setByline(
            byline.map((entry, idx) => {
                if (index === idx) {
                    return validateBylineEntry({
                        ...entry,
                        [key]: value
                    });
                } else {
                    return entry;
                }
            })
        );

        setSearchResults(null);
        if ((key === "firstName" || key === "lastName") && value?.length >= 3) {
            setSearchTerm(value);
            setActiveIndex(index);
        } else {
            setSearchTerm(null);
            setActiveIndex(null);
        }
    };

    const validateBylineEntry = (entry) => {
        return {
            ...entry,
            _invalidFirstName: !entry.firstName || entry.firstName?.trim().length < 2,
            _invalidLastName: entry.lastName !== "" && entry.lastName?.trim().length < 2,
            _invalidEmail: entry.email?.length > 0 && !isValidEmail(entry.email)
        };
    };

    useDebounce(
        () => {
            if (searchTerm?.length) {
                setBusySearching(true);
                searchInternalUsers({ term: searchTerm }).then((response) => {
                    setSearchResults(response.data);
                    setBusySearching(false);
                });
            } else {
                setActiveIndex(null);
                setSearchResults(null);
            }
        },
        [searchTerm],
        { delay: 500 }
    );

    const handleRemove = (index) => {
        const updated = [...byline];
        updated.splice(index, 1);
        setByline(updated);
        setUnsavedChanges(true);
    };

    const handleAddEntry = () => {
        const updated = [...byline];
        updated.push({});
        setByline(assignBylineKeys(updated));
    };

    const handleSelectUser = (user) => {
        const updated = [...byline];
        updated[activeIndex] = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
        };
        setByline(updated);
        setSearchTerm(null);
        setSearchResults(null);
        setUnsavedChanges(true);
    };

    const handleSave = () => {
        const formattedList = byline
            ?.filter((entry) => entry.firstName)
            .map((entry) => ({
                firstName: entry.firstName,
                lastName: entry.lastName,
                email: entry.email
            }));

        if (!formattedList?.length) {
            return Toast.error("Må ha minimum en person i byline");
        }

        onSave(formattedList);
        onClose();
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle description="Velg hvem som skal krediteres meldingen">Byline</PopupTitle>
            <PopupContent>
                <Row>
                    <Col span={24}>
                        <ItemList
                            emptyMessage="Byline er tom."
                            onSort={({ oldIndex, newIndex }) =>
                                setUnsavedChanges(true) &
                                setByline(moveArrayItem(byline, oldIndex, newIndex))
                            }
                        >
                            {byline?.map((entry, index) => (
                                <ItemList.Item
                                    key={index}
                                    index={index}
                                    append={
                                        <Button
                                            square
                                            size="small"
                                            icon="close"
                                            iconColor="error"
                                            color="silent"
                                            onClick={() => handleRemove(index)}
                                        />
                                    }
                                >
                                    <PopOver
                                        visible={activeIndex === index && searchResults?.length > 0}
                                        padding="medium"
                                        content={
                                            busySearching ? (
                                                <Spinner />
                                            ) : (
                                                <ItemList onItemClick={handleSelectUser}>
                                                    {searchResults?.map((user, idx) => (
                                                        <ItemList.Item
                                                            key={user.id}
                                                            value={user}
                                                            index={idx}
                                                        >
                                                            <Text>{user.fullName}</Text>
                                                            <br />
                                                            <Text opacity={50} size="small">
                                                                {user.email}
                                                            </Text>
                                                        </ItemList.Item>
                                                    ))}
                                                </ItemList>
                                            )
                                        }
                                    >
                                        <Row gutter={["tiny", "tiny"]}>
                                            <Col span={12}>
                                                <Input
                                                    size="small"
                                                    placeholder="Fornavn"
                                                    value={entry.firstName}
                                                    error={entry._invalidFirstName}
                                                    onChange={(val) =>
                                                        handleChange(index, "firstName", val)
                                                    }
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Input
                                                    size="small"
                                                    placeholder="Etternavn"
                                                    value={entry.lastName}
                                                    error={entry._invalidLastName}
                                                    onChange={(val) =>
                                                        handleChange(index, "lastName", val)
                                                    }
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <Input
                                                    size="small"
                                                    placeholder="E-post"
                                                    value={entry.email}
                                                    type="email"
                                                    error={entry._invalidEmail}
                                                    onChange={(val) =>
                                                        handleChange(index, "email", val)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </PopOver>
                                </ItemList.Item>
                            ))}
                        </ItemList>
                    </Col>
                    <Col span={24}>
                        <Button
                            fill
                            color="silent"
                            icon="plus-small"
                            iconColor="primary"
                            onClick={handleAddEntry}
                            disabled={byline?.length >= 10}
                        >
                            Legg til person
                        </Button>
                    </Col>
                </Row>
            </PopupContent>
            <PopupFooter>
                <Button
                    color="primary"
                    disabled={!unsavedChanges || !byline?.length || hasErrors}
                    onClick={handleSave}
                >
                    Bekreft endringer
                </Button>
            </PopupFooter>
        </Popup>
    );
};

const assignBylineKeys = (byline) =>
    byline.map((entry) => {
        if (!entry._key) {
            entry._key = randomString(10);
        }
        return entry;
    });
