import { Input, Col, Row, Text } from "@tv2no/ui-react";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { loadScript } from "../../../utils/scriptUtils";
import { PostEditContext } from "../../../contexts/PostEditContext";

const Base = styled.div`
    position: relative;
`;

const getFacebookData = (url) => {
    let requestUrl = `https://graph.facebook.com/v15.0/oembed_post?url=${url}&access_token=831337716926834|mb7E1d5slUqEghQ52SjBbT1sbJo`;
    return fetch(requestUrl).then((res) => res.json());
};

export const FacebookContent = ({ entry, onChange }) => {
    const [previewHtml, setPreviewHtml] = useState("");
    const { addContentError, removeContentError, contentErrors } = useContext(PostEditContext);
    const error = contentErrors[entry._tempId || entry.id];
    const isVideo = entry?.data?.url.includes("watch") || false;
    const isMobile = window.matchMedia("only screen and (max-width: 480px)").matches;

    useEffect(() => {
        const url = entry?.data?.url;
        if (url) {
            const match = url.match(
                /^((?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.watch|m.facebook.com)/
            );
            if (!match) {
                addContentError({ entry, message: "Ugyldig Facebook-lenke" });
            } else {
                removeContentError(entry);
                getFacebookData(url).then((data) => {
                    if (!data?.html) {
                        addContentError({ entry, message: "Kunne ikke hente Facebook-innlegg" });
                    } else {
                        setPreviewHtml(data?.html);
                        if (window.FB) {
                            setTimeout(() => window.FB?.XFBML.parse(), 100);
                        } else {
                            loadScript(
                                "https://connect.facebook.net/en_GB/sdk.js#xfbml=0&version=v15.0",
                                "piVjiwOs"
                            ).then(() => !isVideo && window.FB?.XFBML.parse());
                        }
                    }
                });
            }
        } else {
            removeContentError(entry);
            setPreviewHtml("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry?.data?.url]);

    return (
        <Base>
            <Row>
                <Col span={24}>
                    <Row gutter={["tiny", "tiny"]}>
                        {previewHtml && previewHtml !== "" && (
                            <Col span={22}>
                                {isVideo ? (
                                    <div
                                        className="fb-video"
                                        data-href={entry?.data?.url}
                                        data-show-text="false"
                                    />
                                ) : (
                                    <div
                                        style={
                                            isMobile ? { maxHeight: "440px" } : { height: "100%" }
                                        }
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
                                    </div>
                                )}
                            </Col>
                        )}
                        <Col span={24}>
                            <Input
                                value={entry.data?.url}
                                type="text"
                                size="small"
                                error={!!error}
                                icon="facebook"
                                placeholder="Facebook URL"
                                onChange={(val) =>
                                    onChange({
                                        data: { url: val }
                                    })
                                }
                            />
                            {error ? <Text color="error">{error}</Text> : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Base>
    );
};
