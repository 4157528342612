import React from "react";
import { Callout, Clickable, Col, Image, Row, Text } from "@tv2no/ui-react";
import { formatPostTimestamp } from "../../../utils/dateUtils";
import styled from "styled-components";

const StyledTextContent = styled.div`
    overflow: hidden;
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    flex-grow: 1;
    text-overflow: ellipsis;
    max-height: 10rem;
    min-height: 6rem;

    p:first-child {
        margin: 0;
    }
`;

export const ArticlePreview = ({ image, published, text, article, info, setDetailedArticle }) => {
    return (
        <Clickable onClick={() => setDetailedArticle({ ...article, image })}>
            <Row gutter={["small", "small"]}>
                <Col span={24}>
                    <Row gutter={["tiny", "tiny"]}>
                        <Col span={24} align={["left", "center"]}>
                            <Text size="large" weight="medium">
                                {article.title}
                            </Text>
                        </Col>
                        {published && (
                            <Col span={24}>
                                <Text opacity={40} size="small">
                                    Publisert {formatPostTimestamp(published)}
                                </Text>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        {image && (
                            <Col span={8} align={["left", "top"]}>
                                <Image scale="16:9" src={image?.url} />
                            </Col>
                        )}
                        <Col span={image ? 16 : 24} align={["left", "top"]}>
                            <StyledTextContent>{text}</StyledTextContent>
                        </Col>
                    </Row>
                </Col>
                {info && info !== "" && (
                    <Col span={24}>
                        <Callout color="info">
                            <Text size="small">{info}</Text>
                        </Callout>
                    </Col>
                )}
            </Row>
        </Clickable>
    );
};
