import { BitsModuleView, Callout, Col, Row } from "@tv2no/ui-react";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useModuleBrowser from "../../../hooks/useModuleBrowser";

const Base = styled.div`
    position: relative;
`;

export const BitsModuleContent = ({ entry, onChange, onDelete }) => {
    const [_, setIsOpened] = useState(false);

    const { open } = useModuleBrowser({
        onAdd: (module) => {
            onChange({ data: module });
        },
        onClose: () => {
            if (!entry?.data) {
                onDelete();
            }
        }
    });

    const handleChange = async (changes) => {
        let moduleData = { ...entry.data };
        if (changes?.instanceId) {
            delete moduleData.fields;
            moduleData.instanceId = changes.instanceId;
        } else if (changes?.fields) {
            delete moduleData.instanceId;
            moduleData.fields = changes.fields;
        }
        onChange({ data: moduleData });
    };

    useEffect(() => {
        if (!entry?.data) {
            setIsOpened((val) => {
                if (!val) {
                    open();
                }
                return true;
            });
        }
    }, [entry.data, open]);

    return (
        <Base>
            {entry?.data?.useKey ? (
                <Row gutter={["tiny", "tiny"]}>
                    <Col span="24">
                        <BitsModuleView
                            editable
                            useKey={entry.data.useKey}
                            version={entry.data.version}
                            instanceId={entry.data.instanceId}
                            fields={entry.data.fields}
                            onChange={(changes) => handleChange(changes)}
                        />
                    </Col>
                </Row>
            ) : (
                <Callout>Velg en Bits-modul...</Callout>
            )}
        </Base>
    );
};
