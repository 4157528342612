import React, { useContext, useState } from "react";
import {
    Popup,
    PopupTitle,
    PopupContent,
    PopupFooter,
    Form,
    FormItem,
    Input,
    Textarea,
    Button,
    Row,
    Col,
    Select,
    Option,
    Callout
} from "@tv2no/ui-react";
import { POST_CATEGORIES } from "../../../../constants";
import { createPortal } from "../../../../data/portal/portal";
import { Link, useNavigate } from "react-router-dom";
import { isValidSlug, slugify } from "../../../../utils/stringUtils";
import { GlobalAppContext } from "../../../../contexts/GlobalAppContext";

export const NewPortal = ({ onClose }) => {
    const navigate = useNavigate();
    const { reloadPortals } = useContext(GlobalAppContext);
    const [name, setName] = useState(null);
    const [slug, setSlug] = useState(null);
    const [description, setDescription] = useState(null);
    const [category, setCategory] = useState(null);
    const [busyCreating, setBusyCreating] = useState(false);
    const [error, setError] = useState(null);
    const [existingPortal, setExistingPortal] = useState(null);

    const handleCreate = () => {
        if (!isValidSlug(slug)) {
            setError(
                "Ikke en gyldig lenke. Lenker skal ikke inneholde mellomrom, store bokstaver eller spesialtegn som æøå"
            );
            return;
        }
        setExistingPortal(null);
        setBusyCreating(true);
        createPortal({
            name,
            slug,
            description,
            category,
            theme: category
        })
            .then((response) => {
                setBusyCreating(false);
                onClose();
                navigate(`/portals/${response.id}`);
                reloadPortals();
            })
            .catch((response) => {
                if (response.statusCode === 409) {
                    setError(
                        "Det finnes allerede en portal med denne lenken. Gi portalen en ny og unik lenke eller ta i bruk den eksisterende portalen: "
                    );
                    setExistingPortal(response.target);
                } else {
                    setError("Det oppstod en feil ved opprettelse av portalen.");
                }
                setBusyCreating(false);
            });
    };

    const handleNameChange = (name) => {
        setName(name);
        setSlug(slugify(name));
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle title="Ny portal">Ny portal</PopupTitle>
            <PopupContent>
                <Form>
                    <Row>
                        <Col span={24}>
                            <FormItem label="Navn">
                                <Input value={name} onChange={handleNameChange} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label="Lenke"
                                info={
                                    "Lenken genereres automatisk basert på navn, men kan editeres. Må være unik for hver portal"
                                }
                                error={!!error}
                            >
                                <Input value={slug} onChange={setSlug} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="Beskrivelse">
                                <Textarea
                                    value={description}
                                    onChange={setDescription}
                                    maxLength={200}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="Kategori">
                                <Select
                                    value={category}
                                    onChange={setCategory}
                                    placeholder="Velg kategori"
                                >
                                    {Object.keys(POST_CATEGORIES).map((key) => (
                                        <Option key={key} value={key}>
                                            {POST_CATEGORIES[key].label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        {error ? (
                            <Col span={24}>
                                <Callout color="error">
                                    {error}
                                    {existingPortal && (
                                        <Link to={`/portals/${existingPortal.id}`}>
                                            &nbsp;{existingPortal.name}
                                        </Link>
                                    )}
                                </Callout>
                            </Col>
                        ) : null}
                    </Row>
                </Form>
            </PopupContent>
            <PopupFooter>
                <Button
                    color="primary"
                    type="submit"
                    onClick={handleCreate}
                    busy={busyCreating}
                    disabled={!name?.trim().length || !description?.trim().length || !category}
                >
                    Opprett portalen
                </Button>
            </PopupFooter>
        </Popup>
    );
};
