import { Col, Row } from "@tv2no/ui-react";
import React, { useContext } from "react";
import { PortalEntryContext } from "../entry/PortalEntry";
import { Appearance } from "./parts/Appearance";
import { Delete } from "./parts/Delete";
import { Details } from "./parts/Details";
import { Visibility } from "./parts/Visibility";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";

export const Settings = () => {
    const { portal, setPortal } = useContext(PortalEntryContext);
    const { reloadPortals } = useContext(GlobalAppContext);
    const handleUpdatePortal = (updatedPortal) => {
        setPortal(updatedPortal);
        reloadPortals();
    };
    return (
        <Row>
            <Col span={24}>
                <Details portal={portal} onUpdatePortal={handleUpdatePortal} />
            </Col>
            <Col span={24}>
                <Appearance portal={portal} onUpdatePortal={handleUpdatePortal} />
            </Col>
            <Col span={24}>
                <Visibility portal={portal} onUpdatePortal={handleUpdatePortal} />
            </Col>
            <Col span={24}>
                <Delete portal={portal} />
            </Col>
        </Row>
    );
};
