import React from "react";
import {
    Card,
    CardContent,
    Text,
    Row,
    Col,
    Icon,
    Badge,
    ResponsiveImage,
    Image
} from "@tv2no/ui-react";
import styled from "styled-components";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { formatPostTimestamp } from "../../../utils/dateUtils";
import { decodeHtml } from "../../../utils/stringUtils";

export const PostListEntry = ({ entry, onClick, selected }) => {
    let pictures = [];
    let videoThumbs = [];
    const contentTypes = entry?.content.reduce((agg, entry) => {
        if (entry.type === "PICTURES") {
            pictures = pictures.concat(entry.files);
        } else if (entry.type === "VIDEO") {
            if (entry.files?.length > 0 && entry.files[0].url) {
                videoThumbs.push(entry.files[0].url);
            } else if (entry.data?.thumbSrc) {
                videoThumbs.push(entry.data?.thumbSrc);
            }
        }
        if (agg.indexOf(entry.type) < 0) {
            agg.push(entry.type);
        }
        return agg;
    }, []);

    return (
        <Card onClick={onClick}>
            <CardContent background={selected ? "attention" : null}>
                <Row gutter={["small", "small"]}>
                    <Col align={["left", "center"]}>
                        {(entry.publishedAt || entry.createdAt) &&
                            (dayjs(entry.publishedAt).diff() > 0 ? (
                                <Countdown
                                    date={new Date(entry.publishedAt) || new Date(entry.createdAt)}
                                    renderer={({ days, hours, minutes, seconds, completed }) => {
                                        return (
                                            <Text size="small" color="primary">
                                                {completed
                                                    ? "Publisert"
                                                    : `(om ${days > 0 ? `${days}d` : ""} ${
                                                          hours > 0 ? `${hours}t` : ""
                                                      } ${
                                                          minutes > 0 ? `${minutes}m` : ""
                                                      } ${seconds}s)`}
                                            </Text>
                                        );
                                    }}
                                />
                            ) : (
                                <Text size="small" opacity={40}>
                                    {formatPostTimestamp(entry.publishedAt || entry.createdAt)}
                                </Text>
                            ))}
                    </Col>
                    {!entry.publishedAt && (
                        <Col align={["left", "center"]}>
                            <Badge color="error">Upublisert</Badge>
                        </Col>
                    )}
                    {entry.portals?.length > 1 && (
                        <Col align={["left", "center"]}>
                            <Badge color="silent">{entry.portals.length} portaler</Badge>
                        </Col>
                    )}
                    <Col span={24}>
                        <Text block size="large" weight="bold">
                            {decodeHtml(entry.title)}
                        </Text>
                    </Col>

                    {/* Pictures */}
                    {pictures.length || videoThumbs.length ? (
                        <Col span={24}>
                            <Row gutter={["tiny", "tiny"]}>
                                {pictures?.map((pic) => (
                                    <Col key={pic.id}>
                                        <ResponsiveImage
                                            src={pic.url}
                                            scale="16:9"
                                            thumbnail="medium"
                                            focalPoint={pic?.focalPoint}
                                        />
                                    </Col>
                                ))}
                                {videoThumbs?.map((thumbUrl) => (
                                    <Col key={thumbUrl}>
                                        <VideoThumbWrapper>
                                            <PlayIcon>
                                                <Icon name="play" color="contrast" />
                                            </PlayIcon>
                                            <Image src={thumbUrl} scale="16:9" thumbnail="medium" />
                                        </VideoThumbWrapper>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    ) : null}

                    {/* Topics / types */}
                    <Col span={24}>
                        <Row gutter={["tiny", "tiny"]}>
                            {contentTypes.indexOf("TWITTER") >= 0 && (
                                <Col compact>
                                    <Badge fill size="small" customColor="#1DA1F2">
                                        Tweet
                                    </Badge>
                                </Col>
                            )}
                            {contentTypes.indexOf("INSTAGRAM") >= 0 && (
                                <Col compact>
                                    <Badge fill size="small" customColor="#C13584">
                                        Instagram
                                    </Badge>
                                </Col>
                            )}
                            {contentTypes.indexOf("FACEBOOK") >= 0 && (
                                <Col compact>
                                    <Badge fill size="small" customColor="#4267B2">
                                        Facebook
                                    </Badge>
                                </Col>
                            )}
                            {contentTypes.indexOf("DATAWRAPPER") >= 0 && (
                                <Col compact>
                                    <Badge fill size="small" customColor="#1d81a2">
                                        Datawrapper
                                    </Badge>
                                </Col>
                            )}
                            {entry.topics?.map((topic, i) => (
                                <Col key={i} compact>
                                    <Badge fill size="small" color="silent">
                                        {topic}
                                    </Badge>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>

                {/* Pinned */}
                <CornerLabels>
                    {entry.short && (
                        <CornerEntry title="Short">
                            <Icon name="lightning" color="info" size="small" />
                        </CornerEntry>
                    )}
                    {entry.flags?.includes("tip") && (
                        <CornerEntry title="Tips oss">
                            <Icon name="chat" color="info" size="small" />
                        </CornerEntry>
                    )}
                    {entry.flags?.includes("important") && (
                        <CornerEntry title="Viktig">
                            <Icon name="warning" color="error" size="small" />
                        </CornerEntry>
                    )}
                    {entry.pinned && (
                        <CornerEntry title="Festet" className="pinned">
                            <Icon name="tack" color="contrast" size="small" />
                        </CornerEntry>
                    )}
                </CornerLabels>
            </CardContent>
        </Card>
    );
};

const CornerLabels = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 1px;
`;

const CornerEntry = styled.div`
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &.pinned {
        background-color: rgba(var(--color_info), 1);
        border: none;
    }
`;

const VideoThumbWrapper = styled.div`
    position: relative;
`;

const PlayIcon = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
