import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { PopOver, Spinner, Button, Row, Col, Text, Tooltip, ItemList } from "@tv2no/ui-react";
import { stripHtml } from "../../../../utils/stringUtils";
import { getTitleSuggestions } from "../../../../data/external/ai";

const Content = styled.div`
    position: relative;
    flex-grow: 1;
    padding: 0 1rem;
    margin: -0.5rem 0 0.5rem 0;
`;

export const TitleSuggestions = ({ post, onTitleSelect }) => {
    const [isVisible, setIsVisble] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [busySearching, setBusySearching] = useState(false);

    const text = useMemo(() => {
        return post?.content
            ?.filter((content) => content.type === "MARKUP" && !content._deleted)
            ?.reduce((acc, curr) => `${acc} ${stripHtml(curr?.data || "")}`, "");
    }, [post]);

    const getSuggestions = () => {
        setBusySearching(true);
        getTitleSuggestions(text).then((response) => {
            setSuggestions(response);
            setBusySearching(false);
        });
    };

    const handleToggle = () => {
        setIsVisble(!isVisible);

        if (!suggestions?.length) {
            getSuggestions();
        }
    };

    const handleTitleSelect = (title) => {
        onTitleSelect(title);
        setIsVisble(false);
    };

    return (
        <Content>
            <PopOver
                size="large"
                padding="medium"
                visible={isVisible}
                onClose={() => setIsVisble(false)}
                content={
                    busySearching ? (
                        <Spinner />
                    ) : (
                        <Row gutter={["regular", "regular"]}>
                            <Col span={24}>
                                <ItemList
                                    onItemClick={(suggestion) => handleTitleSelect(suggestion)}
                                >
                                    {suggestions?.map((suggestion, i) => (
                                        <ItemList.Item key={i} index={i} value={suggestion}>
                                            <Text>{suggestion}</Text>
                                        </ItemList.Item>
                                    ))}
                                </ItemList>
                            </Col>

                            <Col span={24} align={["center"]}>
                                <Button
                                    pill
                                    icon="refresh"
                                    iconColor="primary"
                                    onClick={getSuggestions}
                                    size="small"
                                >
                                    Lag nye forslag
                                </Button>
                            </Col>
                        </Row>
                    )
                }
            >
                <Tooltip
                    title="Trykk for å generere forslag til overskrift basert på teksten i meldingen."
                    trigger="mouseenter"
                >
                    <Button
                        pill
                        icon="robot"
                        iconColor="primary"
                        size="tiny"
                        disabled={(busySearching && isVisible) || text?.length < 100}
                        onClick={handleToggle}
                    >
                        Forslag til overskrift
                    </Button>
                </Tooltip>
            </PopOver>
        </Content>
    );
};
