import axios from "axios";
import config from "../config";

// Create
const fetch = axios.create({
    baseURL: config.apiUrl,
    timeout: 60000,
    headers: {
        "Content-Type": "application/json; charset=UTF-8"
    }
});

// Request interceptor
fetch.interceptors.request.use(
    (requestConfig) => {
        // Remove headers if requesting externally
        if (requestConfig.url.startsWith("http") && !requestConfig.url.startsWith(config.apiUrl)) {
            delete requestConfig.headers.common["Authorization"];
        }

        return requestConfig;
    },
    function (error) {
        console.log("Request error:", error);
    }
);

// Response interceptor
fetch.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        // Reset token
        if (error.response && error.response.status === 401) {
            // Redirect to login form
            const url = new URL(window.location.href);
            window.location.href = `${
                config.authLoginUrl
            }?appId=BREAKING&callback=${url.toString()}`;
        }

        return Promise.reject(error.response);
    }
);

function call(method, path, payload, options) {
    return new Promise((resolve, reject) => {
        fetch(config.apiUrl + path, {
            method,
            credentials: "same-origin",
            [method === "GET" ? "params" : "data"]: payload,
            ...options
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error?.data);
            });
    });
}

export function setHeader(name, value) {
    fetch.defaults.headers.common[name] = value;
}

export function postRequest(url, payload, options) {
    return call("POST", url, payload, options);
}

export function putRequest(url, payload, options) {
    return call("PUT", url, payload, options);
}

export function deleteRequest(url, options) {
    return call("DELETE", url, undefined, options);
}

export function getRequest(url, payload, options) {
    return call("GET", url, payload, options);
}

export { fetch };
