import { getRequest, postRequest } from "../fetch";
import { toSearchParamsString } from "../../utils/urlUtils";

export const getPosts = ({ tenantKey, channelId, portal, lastPostId, lastChangesId }) => {
    const queryParams = toSearchParamsString({
        ...(tenantKey && { tenantKey }),
        ...(channelId && { channelId }),
        ...(portal && { portal }),
        ...(lastPostId && { lastPostId }),
        ...(lastChangesId && { lastChangesId })
    });
    return getRequest(`/external/norkon-feeds/posts?${queryParams}`);
};

export const createPostFromArticle = async ({ livecenterPost, portalId, publish }) => {
    return await postRequest(`/external/norkon-feeds/posts/${livecenterPost.id}`, {
        portalId,
        publish,
        livecenterPost
    });
};
