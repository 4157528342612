import React from "react";
// @ts-expect-error - no types for this package yet
import { Button, Card, CardContent, Col, Row, Spinner, Text } from "@tv2no/ui-react";
import { PostListEntry } from "../PostListEntry";

type SearchResult = {
    id: string;
};

type SearchResultsProps = {
    searchResults: SearchResult[];
    loading: boolean;
    listQuery: {
        search: string;
    };
    queryLengthThreshold: number;
    hasMoreSearchResults: boolean;
    onEntryClick: (postId: object) => void;
    onLoadMoreClick: () => void;
};

const SearchResults = ({
    searchResults,
    loading,
    listQuery,
    queryLengthThreshold,
    hasMoreSearchResults,
    onEntryClick,
    onLoadMoreClick
}: SearchResultsProps) => {
    return (
        <Row gutter={["small", "small"]}>
            {searchResults?.map((p) => (
                <Col span={24} key={p.id}>
                    <PostListEntry entry={p} selected={false} onClick={() => onEntryClick(p)} />
                </Col>
            ))}

            {loading ? (
                <Col span={24}>
                    <Card>
                        <CardContent background="muted">
                            <Spinner />
                        </CardContent>
                    </Card>
                </Col>
            ) : searchResults?.length === 0 || listQuery.search.length < queryLengthThreshold ? (
                <Col span={24}>
                    <Card>
                        <CardContent background="muted">
                            <Text opacity={40}>
                                Vi fant ingen nyhetsmeldinger som passet søket ditt.
                            </Text>
                            <br />
                            <Text>Søk må bestå av minst tre tegn.</Text>
                        </CardContent>
                    </Card>
                </Col>
            ) : null}

            {!loading && hasMoreSearchResults ? (
                <Col span={24} align={["center"]}>
                    <Button
                        fill
                        color="transparent"
                        icon="arrow-down"
                        iconColor="primary"
                        onClick={() => onLoadMoreClick()}
                    >
                        Last inn flere
                    </Button>
                </Col>
            ) : null}
        </Row>
    );
};

export { SearchResults };
