import {
    Action,
    Badge,
    Button,
    Card,
    CardAdder,
    CardContent,
    Col,
    Image,
    Page,
    PageActions,
    ResponsiveImage,
    Row,
    Spinner,
    Text
} from "@tv2no/ui-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import defaultPortalImage from "../../../assets/images/default-portal.jpeg";
import { NavButton, PageNav } from "../../../components/page/PageNav";
import { POST_CATEGORIES } from "../../../constants";
import { getPortalList } from "../../../data/portal/portal";
import { NewPortal } from "./parts/NewPortal";

export const PortalList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [archived, setArchived] = useState(false);
    const [busyLoading, setBusyLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [portalList, setPortalList] = useState(null);

    const fetchPortals = (callback) => {
        setBusyLoading(true);
        getPortalList({ page: pageNumber, limit: 15, archived }).then((response) => {
            setPortalList((list) => {
                if (response.page > 1) {
                    response.docs = list.docs.concat(response.docs);
                }
                return response;
            });
            callback && callback();
            setBusyLoading(false);
        });
    };

    useEffect(fetchPortals, [pageNumber, archived]);

    return (
        <Page>
            <PageActions extended={false} title="Handlinger">
                <Action
                    icon="plus-small"
                    label="Ny portal"
                    onClick={() => setSearchParams({ promptNew: true })}
                />
                <Action icon="info" label="Hjelp">
                    Opprett en ny portal når du ønsker å starte et nytt sakskompleks.
                </Action>
            </PageActions>
            <PageNav activeKey={!archived ? "1" : "2"}>
                <NavButton
                    tabKey="1"
                    icon="list"
                    label="Aktive"
                    active={!archived}
                    onClick={() => setArchived(false) | setPageNumber(1) | setPortalList(null)}
                />
                <NavButton
                    tabKey="2"
                    icon="archive"
                    label="Arkiverte"
                    active={archived}
                    onClick={() => setArchived(true) | setPageNumber(1) | setPortalList(null)}
                />
            </PageNav>
            <Row>
                {portalList?.docs?.map((portal) => (
                    <Col key={portal.id} xs={24} sm={12} md={8} lg={6}>
                        <Card fill onClick={() => navigate(`/portals/${portal.id}`)}>
                            {portal.cover ? (
                                <ResponsiveImage
                                    src={portal.cover?.url}
                                    scale="16:9"
                                    mod={portal.hidden ? "gray" : null}
                                />
                            ) : (
                                <Image
                                    src={defaultPortalImage}
                                    scale="16:9"
                                    mod={portal.hidden ? "gray" : null}
                                />
                            )}

                            <CardContent grow>
                                <Text block size="large" weight="bold">
                                    {portal.name}
                                </Text>
                                <Text opacity={60}>{portal.description}</Text>
                            </CardContent>
                            <CardContent border="top">
                                <Row>
                                    <Col span={18}>
                                        {portal.hidden ? (
                                            <Badge fill color="silent">
                                                Skjult
                                            </Badge>
                                        ) : (
                                            <Badge
                                                fill
                                                customColor={POST_CATEGORIES[portal.category].color}
                                                spacing="right"
                                            >
                                                {POST_CATEGORIES[portal.category].label}
                                            </Badge>
                                        )}
                                    </Col>

                                    {portal.newCount ? (
                                        <Col span={6} align={["right", "center"]}>
                                            <Text opacity={40}>
                                                {portal.newCount}{" "}
                                                {portal.newCount > 1 ? "nye" : "ny"}
                                            </Text>
                                        </Col>
                                    ) : null}
                                </Row>
                            </CardContent>
                        </Card>
                    </Col>
                ))}

                {/* New portal */}
                {!archived && !busyLoading ? (
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <CardAdder
                            fill
                            minHeight="22em"
                            onClick={() => setSearchParams({ promptNew: true })}
                        />
                    </Col>
                ) : null}

                {portalList?.hasNextPage && !busyLoading ? (
                    <Col md={{ span: 12, offset: 6 }}>
                        <Button
                            fill
                            color="transparent"
                            icon="arrow-down"
                            iconColor="primary"
                            busy={busyLoading}
                            onClick={() => setPageNumber(pageNumber + 1)}
                        >
                            Last inn flere
                        </Button>
                    </Col>
                ) : busyLoading ? (
                    <Col span={24} align={["center", "center"]}>
                        <Spinner />
                    </Col>
                ) : null}
            </Row>

            {/* PROMPT: New portal */}
            {searchParams.get("promptNew") && (
                <NewPortal onClose={() => setSearchParams(searchParams.delete("promptNew"))} />
            )}
        </Page>
    );
};
