import { randomString } from "../../utils/stringUtils";

export const addMarkupContentIfNotExist = (content = []) => {
    if (!content.some(({ type }) => type === "MARKUP")) {
        return [
            ...content,
            {
                _tempId: randomString(10),
                type: "MARKUP"
            }
        ];
    }
    return content;
};

export const replacePostContentEntry = (post, changedEntry) => {
    return post.content.map((entry) =>
        (entry._tempId && entry._tempId === changedEntry._tempId) ||
        (entry.id && entry.id === changedEntry.id)
            ? changedEntry
            : entry
    );
};

export const dataURLtoBlob = (dataUrl) => {
    var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export const createNewDraft = ({ user }) => {
    return {
        category: "NYHETER",
        title: "",
        byline: [
            {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email
            }
        ],
        topics: [],
        portals: [],
        content: [
            {
                _tempId: randomString(10),
                type: "PICTURES"
            },
            {
                _tempId: randomString(10),
                type: "MARKUP"
            }
        ]
    };
};
