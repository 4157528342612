import { Button, Col, Row, Text } from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostListContext } from "../../../contexts/PostListContext";
import { publishPost } from "../../../data/post/post";

export const SourceControls = ({ newsItem, importArticle, onClose, reloadArticleList }) => {
    const navigate = useNavigate();
    const { loadPostList } = useContext(PostListContext);
    const [pendingActions, setPendingActions] = useState([]);

    const removePendingAction = (action) => {
        setPendingActions((prev) => prev.filter((a) => a !== action));
    };

    const hasPendingImportForEdit = () => pendingActions.some((a) => a.includes("EDIT"));

    const onPublishClicked = async (newsItem) => {
        const action = `PUBLISH:${newsItem.id}`;
        setPendingActions((prev) => [...prev, action]);
        if (newsItem.post) {
            await publishPost(newsItem.post.id);
            reloadArticleList();
            await loadPostList({ page: 1, published: true });
        } else {
            await importArticle(newsItem, true);
        }
        removePendingAction(action);
    };

    const onEditClicked = async (newsItem) => {
        if (newsItem.post) {
            onClose();
            loadPostList({
                page: 1,
                published: !!newsItem.post.publishedAt
            });
            navigate(`/portals/${newsItem.post.portals?.[0].id}?postId=${newsItem.post.id}`);
        } else {
            const action = `EDIT:${newsItem.id}`;
            setPendingActions((prev) => [...prev, action]);
            loadPostList({
                page: 1,
                published: false
            });
            await importArticle(newsItem, false);
            removePendingAction(action);
        }
    };

    return (
        <Row>
            <Col span={24}>
                <Row gutter={["small", "small"]}>
                    {!newsItem.post && (
                        <Col>
                            <Button
                                pill
                                busy={pendingActions.includes(`PUBLISH:${newsItem.id}`)}
                                disabled={hasPendingImportForEdit()}
                                size="small"
                                color="primary"
                                onClick={() => onPublishClicked(newsItem)}
                            >
                                Publisér
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button
                            icon={"edit"}
                            busy={pendingActions.includes(`EDIT:${newsItem.id}`)}
                            disabled={pendingActions.length > 0}
                            size="small"
                            pill
                            circle
                            onClick={() => onEditClicked(newsItem)}
                        />
                    </Col>

                    {newsItem.post && (
                        <Col align={["left", "center"]}>
                            <Text color={newsItem.post.publishedAt ? "success" : "error"}>
                                {newsItem.post.publishedAt ? "Publisert" : "Upublisert"}
                            </Text>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};
