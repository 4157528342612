import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Button, Callout, CardContent, Menu, MenuItem, PopOver, PopupYesNo } from "@tv2no/ui-react";
import styled from "styled-components";
import { PostEditContext } from "../../../../contexts/PostEditContext";
import { POST_CONTENT_TYPES } from "../types";

const Base = styled.div`
    position: relative;
    display: flex;
`;

const Content = styled.div`
    position: relative;
    flex-grow: 1;
    padding: 0.75rem 0 0.75rem 1.5rem;
`;

const Actions = styled.div`
    padding: 0.2rem;
    display: flex;
    align-items: center;
`;

export const PostContentEntry = (props) => {
    const { isFirst, isLast } = props;
    const { reorderContent, changeContent, deleteContent } = useContext(PostEditContext);
    const [entry, setEntry] = useState(props.entry);
    const [showMenu, setShowMenu] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [type, setType] = useState(POST_CONTENT_TYPES[props.entry.type]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setEntry(props.entry);
        setType(props.entry?.type ? POST_CONTENT_TYPES[props.entry.type] : null);
    }, [props.entry]);

    const handleDelete = () => {
        setShowDeleteConfirm(false);
        deleteContent(entry);
    };

    const handleMove = (offset) => {
        setShowMenu(false);
        reorderContent(entry, offset);
    };

    return (
        <CardContent padding="none" background={showMenu ? "striped" : null}>
            <Base>
                <Content
                    className={classNames({
                        first: isFirst,
                        last: isLast
                    })}
                >
                    {React.createElement(type?.component || MissingType, {
                        entry,
                        editMode,
                        setEditMode,
                        onDelete: () => deleteContent(entry),
                        onChange: (change) => changeContent({ ...entry, ...change })
                    })}
                </Content>
                <Actions>
                    <PopOver
                        visible={showMenu}
                        onClose={() => setShowMenu(false)}
                        padding="regular"
                        content={
                            <Menu>
                                {type.editable && (
                                    <MenuItem
                                        icon="edit"
                                        label="Rediger"
                                        onClick={() => setEditMode(true)}
                                    />
                                )}
                                {!isFirst ? (
                                    <MenuItem
                                        icon="arrow-up"
                                        label="Flytt opp"
                                        onClick={() => handleMove(-1)}
                                    />
                                ) : null}
                                {!isLast ? (
                                    <MenuItem
                                        icon="arrow-down"
                                        label="Flytt ned"
                                        onClick={() => handleMove(1)}
                                    />
                                ) : null}
                                <MenuItem
                                    icon="trash"
                                    iconColor="error"
                                    label="Slett elementet"
                                    onClick={() =>
                                        entry.id ? setShowDeleteConfirm(true) : handleDelete()
                                    }
                                />
                            </Menu>
                        }
                    >
                        <Button
                            circle
                            icon="dot-matrix"
                            color={showMenu ? "primary" : "transparent"}
                            iconColor={!showMenu ? "primary" : null}
                            size="small"
                            onClick={() => setShowMenu(!showMenu)}
                        />
                    </PopOver>
                </Actions>

                {/* PROMPT: Delete confirm */}
                <PopupYesNo
                    title="Slette innholdselement?"
                    visible={showDeleteConfirm}
                    onYes={handleDelete}
                    onNo={() => setShowDeleteConfirm(false)}
                >
                    Er du sikker på at du vil slette dette innholdselementet?
                </PopupYesNo>
            </Base>
        </CardContent>
    );
};

const MissingType = ({ entry }) => {
    return <Callout color="error">Ugyldig innholdstype: "{entry?.type}"</Callout>;
};
