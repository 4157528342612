import { Button, ButtonGroup, Col, OnOffSwitch, Row, PopupYesNo } from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import { PortalLayoutContext } from "../Layout";
import config from "../../../../../../config";

export const LayoutControls = ({ portal }) => {
    const { layout, updateLayout, viewDevice, setViewDevice } = useContext(PortalLayoutContext);
    const [enableChange, setEnableChange] = useState(null);

    return (
        <React.Fragment>
            <Row gutter={["small", "small"]}>
                <Col grow>
                    <ButtonGroup>
                        <Button
                            icon="desktop"
                            size="small"
                            pill
                            onClick={() => setViewDevice("desktop")}
                            color={viewDevice === "desktop" ? "primary" : "silent"}
                        >
                            Desktop
                        </Button>
                        <Button
                            icon="mobile"
                            size="small"
                            pill
                            onClick={() => setViewDevice("mobile")}
                            color={viewDevice === "mobile" ? "primary" : "silent"}
                        >
                            Mobil
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col align={["right", "center"]}>
                    <Row gutter={["small", "small"]}>
                        <Col align={["right", "center"]}>
                            <OnOffSwitch
                                value={layout.enabled}
                                onChange={setEnableChange}
                                labelLeft
                            >
                                Aktivert
                            </OnOffSwitch>
                        </Col>
                        <Col>
                            <Button
                                href={`${config.frontAppUrl}/${portal.key}/${portal.slug}`}
                                size="small"
                                icon="visit"
                                iconColor="primary"
                            >
                                Åpne portal
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* PROMPT: Visibility */}
            {enableChange !== null ? (
                <PopupYesNo
                    title="Aktivere/deaktivere portal-layout"
                    onYes={() => updateLayout({ enabled: enableChange }) && setEnableChange(null)}
                    onNo={() => setEnableChange(null)}
                >
                    {enableChange
                        ? "Er du sikker på at du vil aktivere layout for denne portalen? Husk å sjekke at alt ser greit ut i både desktop- og mobilvisning."
                        : "Er du sikker på at du vil deaktivere layout for denne portalen?"}
                </PopupYesNo>
            ) : null}
        </React.Fragment>
    );
};
