import { Card, CardContent, Row, Col, Callout, Button } from "@tv2no/ui-react";
import React from "react";

export const PostNotFoundMessage = ({closePost}) => {
    return (
        <Card>
            <CardContent>
                <Row>
                    <Col span={24}>
                        <Callout title="Ooops!" color="error">
                            Klarte ikke å finne meldingen du forsøker å redigere.
                        </Callout>
                    </Col>
                    <Col span={24}>
                        <Button icon="plus-small" iconColor="primary" onClick={closePost}>
                            Lag ny melding
                        </Button>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
};
