import { deleteRequest, getRequest, postRequest, putRequest } from "../fetch";

export const createPost = (postData) => postRequest("/posts", postData);
export const getPostList = (params) => getRequest(`/posts`, params);
export const getPostById = (postId) => getRequest(`/posts/${postId}`);
export const updatePost = (postId, postData) => putRequest(`/posts/${postId}`, postData);
export const deletePost = (postId) => deleteRequest(`/posts/${postId}`);
export const publishPost = (postId, timeStamp = new Date().toISOString()) =>
    putRequest(`/posts/${postId}/publish?timeStamp=${timeStamp}`);
export const unpublishPost = (postId) => putRequest(`/posts/${postId}/unpublish`);
export const createLabradorArticleFromPost = (postId, postData) =>
    postRequest(`/posts/${postId}/related/labrador_article`, postData);
