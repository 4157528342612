import dayjs from "dayjs";
import "dayjs/locale/nb";

dayjs.locale("nb");

export function isToday(date: string) {
    return dayjs().isSame(dayjs(date), "day");
}

export function isYesterday(date: string) {
    return dayjs().subtract(1, "day").isSame(dayjs(date), "day");
}

export function formatPostTimestamp(date: string) {
    if (!date) {
        return null;
    } else if (isToday(date)) {
        return dayjs(date).format("HH:mm");
    } else if (isYesterday(date)) {
        return dayjs(date).format("[I går] HH:mm");
    } else {
        return dayjs(date).format("D. MMMM HH:mm");
    }
}
