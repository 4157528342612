export const loadScript = (src, nonce = null) =>
    new Promise((resolve, reject) => {
        if ([...document.head.children].find((x) => x.src === src)) {
            reject();
            return;
        }

        const scriptEl = document.createElement("script");
        scriptEl.src = src;
        scriptEl.onload = () => resolve(true);
        scriptEl.nonce = nonce;

        document.head.appendChild(scriptEl);
    });
