import { getRequest, postRequest } from "../fetch";
import { toSearchParamsString } from "../../utils/urlUtils";

export const getArticleList = ({ limit = 20, page = 0, category, portal }) => {
    const queryParams = toSearchParamsString({
        limit,
        offset: limit * page,
        ...(category && { category }),
        ...(portal && { portal })
    });
    return getRequest(`/external/ntb/articles?${queryParams}`);
};

export const createPostFromArticle = async ({ ntbArticleId, portalId, publish }) => {
    return await postRequest(`/external/ntb/articles/${ntbArticleId}`, {
        portalId,
        publish
    });
};

export const searchImages = ({ query = "", page = 1, limit = 50 }) =>
    getRequest(
        `/external/ntb/images/search?limit=${limit}&offset=${limit * (page - 1)}&query=${query}`
    );

export const downloadImage = ({ imageRef }) =>
    getRequest(`/external/ntb/images/download/${imageRef}`, null, {
        responseType: "blob"
    }).then((response) => new File([response], `${imageRef}.jpg`, { type: response.type }));
