import { Button, CardContent, Toast, Col, Row, PopupLoader } from "@tv2no/ui-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostListContext } from "../../contexts/PostListContext";
import { createPostFromArticle, getLabradorArticles } from "../../data/external/labrador";
import { PortalEntryContext } from "../../routes/portals/entry/PortalEntry";
import { ArticlePopup } from "./parts/ArticlePopup";
import { ArticlePreview } from "./parts/ArticlePreview";
import { SourceCategorySelector } from "./parts/SourceCategorySelector";
import { SourceControls } from "./parts/SourceControls";

export const LabradorSource = ({ onClose }) => {
    const { portal } = useContext(PortalEntryContext);
    const { loadPostList } = useContext(PostListContext);
    const [category, setCategory] = useState(portal.category === "NYHETER" ? "NYHETER" : "");
    const [labradorArticles, setLabradorArticles] = useState({});
    const [detailedLabradorArticle, setDetailedLabradorArticle] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);
    const [busyImporting, setBusyImporting] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    const fetchArticleList = useCallback(
        ({ limit = 20, page = 1 }) => {
            setBusyLoading(true);
            getLabradorArticles({ limit, page, category, portal: portal?.id }).then(
                (articleList) => {
                    setLabradorArticles((prevState) =>
                        articleList.reduce((prev, curr) => {
                            return { ...prev, [curr.id]: curr };
                        }, prevState)
                    );
                    setBusyLoading(false);
                }
            );
        },
        // eslint-disable-next-line
        [category, portal.id]
    );

    useEffect(() => {
        fetchArticleList({ page, limit: 30 });
        const pollArticles = setInterval(() => {
            fetchArticleList({ page: 1, limit: 30 });
        }, 20000);
        return () => {
            clearInterval(pollArticles);
        };
    }, [fetchArticleList, page, category]);

    const onChangeCategory = (category) => {
        setLabradorArticles({});
        setCategory(category);
        setPage(1);
    };

    const importArticle = async (labradorData, publish) => {
        setBusyImporting(true);
        try {
            const newPost = await createPostFromArticle({
                articleId: labradorData.id,
                portalId: portal.id,
                publish
            });
            fetchArticleList({ page, limit: 30 });
            loadPostList({
                page: 1,
                published: publish
            });
            onClose && onClose();
            navigate(`/portals/${portal.id}?postId=${newPost.id}`);
            publish && Toast.success("Meldingen ble publisert");
        } catch (e) {
            console.error(e);
            Toast.error("Feilet å hente ut innhold fra Labrador-artikkelen for redigering");
        }
        setBusyImporting(false);
    };

    return (
        <React.Fragment>
            <SourceCategorySelector
                category={category}
                onChangeCategory={onChangeCategory}
                values={["Nyheter", "Sport", "Underholdning"]}
            />
            {Object.keys(labradorArticles).length > 0 &&
                Object.values(labradorArticles)
                    .sort(
                        (a, b) => new Date(b.published).getTime() - new Date(a.published).getTime()
                    )
                    .map((article, i) => {
                        const image = article?.labrador_image || null;
                        return (
                            <CardContent border="top" key={article.id}>
                                <Row>
                                    <Col span={24}>
                                        <ArticlePreview
                                            article={article}
                                            published={article?.published}
                                            image={image}
                                            text={article?.subtitle}
                                            info={article.hasNotes}
                                            setDetailedArticle={setDetailedLabradorArticle}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <SourceControls
                                            importArticle={importArticle}
                                            newsItem={article}
                                            onClose={onClose}
                                            reloadArticleList={fetchArticleList}
                                        />
                                    </Col>
                                </Row>
                            </CardContent>
                        );
                    })}
            <CardContent>
                <Button
                    fill
                    color="transparent"
                    icon="arrow-down"
                    iconColor="primary"
                    busy={busyLoading}
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                >
                    Last inn flere
                </Button>
            </CardContent>
            <ArticlePopup
                detailedArticle={detailedLabradorArticle}
                setDetailedArticle={setDetailedLabradorArticle}
            />

            {/* Importing */}
            {busyImporting && <PopupLoader label="Importerer Labrador-artikkel..." />}
        </React.Fragment>
    );
};
