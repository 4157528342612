import React, { useContext, useEffect, useState } from "react";
import { Text, Row, Callout, Col, OnOffSwitch, Toast } from "@tv2no/ui-react";
import { updateFeed } from "../../../data/feed/feed";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";

export const AutomatePublication = ({ ...props }) => {
    const { portal, feed } = props;
    const { reloadFeeds } = useContext(GlobalAppContext);
    const [autoPublish, setAutoPublish] = useState(false);

    useEffect(() => {
        if (feed?.autoPortals) {
            let portalIds = Array.from(new Set([...feed?.autoPortals.map((p) => p.id)]));
            setAutoPublish(portalIds?.indexOf(portal.id) > -1 || false);
        }
    }, [portal.id, feed]);

    const handleAutomation = (value) => {
        let newAutoPortals = Array.from(new Set([...feed.autoPortals.map((p) => p.id)]));
        if (value) {
            if (newAutoPortals.indexOf(portal.id) === -1) {
                newAutoPortals = [...newAutoPortals, portal.id];
            }
        } else {
            if (newAutoPortals.indexOf(portal.id) > -1) {
                const index = newAutoPortals?.indexOf(portal.id);
                newAutoPortals?.splice(index, 1);
            }
        }

        updateFeed(feed.id, {
            ...feed,
            autoPortals: newAutoPortals
        })
            .then((response) => {
                reloadFeeds();
            })
            .catch((err) => {
                Toast.error("Det oppstod en feil ved lagring");
            });
    };

    return (
        <Row>
            <Col span={24}>
                <Callout>
                    <Text block size="small">
                        Aktiver/deaktiver automatisk publisering fra{" "}
                        <Text weight="bold">&quot;{feed?.name}&quot;</Text> til{" "}
                        <Text weight="bold">&quot;{portal?.name}&quot;</Text> portalen.
                    </Text>
                    <Text size="small">
                        Du finner en oversikt over alle portaler koblet til denne feeden under{" "}
                        <Text weight="bold">Feed innstillinger</Text>.
                    </Text>
                </Callout>
            </Col>
            <Col span={24}>
                <OnOffSwitch value={autoPublish} onChange={(val) => handleAutomation(val)}>
                    <Text block>
                        Automatisk publisering til{" "}
                        <Text weight="bold">&quot;{portal?.name}&quot;</Text> portalen er{" "}
                        <Text weight="bold" color="info">
                            {autoPublish ? "aktivert" : "deaktivert"}
                        </Text>
                        .
                    </Text>
                </OnOffSwitch>
            </Col>
        </Row>
    );
};
