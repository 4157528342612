import React, { useContext } from "react";
import { CardContent, Text } from "@tv2no/ui-react";
import { PostEditContext } from "../../../../contexts/PostEditContext";
import { formatPostTimestamp } from "../../../../utils/dateUtils";

export const PostDetails = () => {
    const { post } = useContext(PostEditContext);
    return post?.id ? (
        <CardContent background="muted" border="top">
            <Text block size="small" opacity={40}>
                Opprettet av {post.createdBy?.fullName}, {formatPostTimestamp(post.createdAt)}{" "}
                {!!post.publishedAt &&
                    ` - Publisert av ${post.publishedBy?.fullName}, ${formatPostTimestamp(
                        post.publishedAt
                    )}`}
            </Text>

            {post.updatedAt && post.updatedBy && (
                <Text block size="small" opacity={40}>
                    Sist oppdatert av {post.updatedBy?.fullName},{" "}
                    {formatPostTimestamp(post.updatedAt)}
                </Text>
            )}
        </CardContent>
    ) : null;
};
