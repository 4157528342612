import slug from "slug";
import sanitizeHtml from "sanitize-html";

export const randomString = (length: number) => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
    var result = "";
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};

export const slugify = (value: string, withSpecials = false) => {
    return slug(value, {
        lower: true,
        trim: true,
        ...(withSpecials && {
            charmap: {
                æ: "æ",
                ø: "ø",
                å: "å"
            }
        })
    });
};

export const isValidSlug = (slug: string) => {
    const slugified = slugify(slug);
    return slugified === slug;
};

export const isValidEmail = (value: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
};

export const validateBaseUrl = (baseUrl: string) => {
    try {
        const url = new URL(baseUrl);

        if (
            baseUrl.endsWith("/") ||
            (baseUrl !== `${url.origin}${url.pathname}` &&
                baseUrl !== `${url.origin}${url.pathname}${url.hash || "#"}`)
        ) {
            return "Base URL må oppgis uten query parameters, trailing slash, osv. F.eks: https://www.tv2.no/direkte";
        } else {
            return null;
        }
    } catch {
        return "Ikke en gyldig Base URL";
    }
};

export const decodeHtml = (text: string) =>
    text ? text.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">") : "";

export const stripHtml = (htmlString: string) => sanitizeHtml(htmlString, { allowedTags: [] });

export const formatTitle = (string: string, limit: number | undefined) =>
    stripHtml(string)
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/^"/gm, "«")
        .replace(/--/gm, "–")
        .replace(/ "/gm, " «")
        .replace(/»"/m, "»«")
        .replace(/"/gm, "»")
        .substring(0, limit);
