import { Button, CardContent, Toast, Col, Row, PopupLoader } from "@tv2no/ui-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostListContext } from "../../contexts/PostListContext";
import { createPostFromArticle, getArticleList } from "../../data/external/ntb";
import { PortalEntryContext } from "../../routes/portals/entry/PortalEntry";
import { ArticlePopup } from "./parts/ArticlePopup";
import { SourceCategorySelector } from "./parts/SourceCategorySelector";
import { SourceControls } from "./parts/SourceControls";
import { ArticlePreview } from "./parts/ArticlePreview";

export const NtbSource = ({ onClose }) => {
    const navigate = useNavigate();
    const { portal } = useContext(PortalEntryContext);
    const { loadPostList } = useContext(PostListContext);
    const [category, setCategory] = useState(portal.category === "SPORT" ? "SPORT" : "");
    const [articleList, setArticleList] = useState({});
    const [detailedNewsItem, setDetailedNewsItem] = useState(null);
    const [page, setPage] = useState(0);
    const [busyLoading, setBusyLoading] = useState(false);
    const [busyImporting, setBusyImporting] = useState(false);

    const updateNews = useCallback(
        ({ limit, page }) => {
            setBusyLoading(true);
            getArticleList({ limit, page, category, portal: portal?.id }).then((response) => {
                setArticleList((prevList) =>
                    response.reduce((prev, curr) => ({ ...prev, [curr.ntbId]: curr }), prevList)
                );
                setBusyLoading(false);
            });
        },
        [category, portal.id]
    );

    const reloadArticleList = () =>
        updateNews({ page: 0, limit: Object.keys(articleList).length + 10 });

    useEffect(() => {
        updateNews({ page, limit: 30 });
        const pollNews = setInterval(() => {
            updateNews({ page: 0, limit: 30 });
        }, 20000);

        return () => {
            clearInterval(pollNews);
        };
    }, [updateNews, page, category]);

    const onChangeCategory = (category) => {
        setArticleList({});
        setCategory(category);
        setPage(0);
    };

    const importArticle = async (ntbData, publish) => {
        setBusyImporting(true);
        try {
            const newPost = await createPostFromArticle({
                ntbArticleId: ntbData.id,
                portalId: portal.id,
                publish
            });
            reloadArticleList();
            loadPostList({
                page: 1,
                published: publish
            });
            onClose && onClose();
            navigate(`/portals/${portal.id}?postId=${newPost.id}`);
            publish && Toast.success("Meldingen ble publisert");
        } catch (e) {
            console.error(e);
            Toast.error("Feilet å hente ut innhold fra NTB artikkelen for redigering");
        }
        setBusyImporting(false);
    };

    return (
        <React.Fragment>
            <SourceCategorySelector
                category={category}
                onChangeCategory={onChangeCategory}
                values={["Innenriks", "Utenriks", "Sport"]}
            />

            {Object.keys(articleList).length > 0 &&
                Object.values(articleList)
                    .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
                    .map((newsItem, i) => {
                        const image = newsItem.mediaList
                            ?.flat()
                            ?.find(({ mediaType }) => mediaType === "image");

                        return (
                            <CardContent border="top" key={newsItem.id}>
                                <Row>
                                    <Col span={24}>
                                        <ArticlePreview
                                            article={newsItem}
                                            published={newsItem.time}
                                            image={image}
                                            text={
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: newsItem.content
                                                    }}
                                                />
                                            }
                                            fade
                                            info={newsItem?.editorialInfo}
                                            setDetailedArticle={setDetailedNewsItem}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <SourceControls
                                            newsItem={newsItem}
                                            importArticle={importArticle}
                                            onClose={onClose}
                                            reloadArticleList={reloadArticleList}
                                        />
                                    </Col>
                                </Row>
                            </CardContent>
                        );
                    })}

            {/* Load more */}
            <CardContent>
                <Button
                    fill
                    color="transparent"
                    icon="arrow-down"
                    iconColor="primary"
                    busy={busyLoading}
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                >
                    Last inn flere
                </Button>
            </CardContent>
            <ArticlePopup
                detailedArticle={detailedNewsItem}
                setDetailedArticle={setDetailedNewsItem}
            />

            {/* Importing */}
            {busyImporting && <PopupLoader label="Importerer NTB-artikkel..." />}
        </React.Fragment>
    );
};
