import { Button, CardContent, Toast, Col, Row, PopupLoader, Select, Option } from "@tv2no/ui-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostListContext } from "../../contexts/PostListContext";
import { GlobalAppContext } from "../../contexts/GlobalAppContext";
import { createPostFromArticle, getPosts } from "../../data/external/norkon-feeds";
import { PortalEntryContext } from "../../routes/portals/entry/PortalEntry";
import { ArticlePopup } from "./parts/ArticlePopup";
import { SourceControls } from "./parts/SourceControls";
import { ArticlePreview } from "./parts/ArticlePreview";
import { AutomatePublication } from "./parts/AutomatePublication";

export const LiveFeedSource = ({ onClose }) => {
    const navigate = useNavigate();
    const { portal } = useContext(PortalEntryContext);
    const { loadPostList } = useContext(PostListContext);
    const { feedList } = useContext(GlobalAppContext);

    const [feed, setFeed] = useState(null);

    const [postList, setPostList] = useState({});
    const [detailedNewsItem, setDetailedNewsItem] = useState(null);
    const [lastChangesId, setLastChangesId] = useState(null);
    const [lastPostId, setLastPostId] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);
    const [busyImporting, setBusyImporting] = useState(false);

    useEffect(() => {
        if (feedList) {
            const selectedFeed = feed
                ? feedList.docs.find((f) => f.id === feed.id)
                : feedList?.docs?.[0];
            setFeed(selectedFeed);
        }
    }, [feed, feedList]);

    const updateChannel = useCallback(
        ({ lastPostId }) => {
            setBusyLoading(true);
            if (feed) {
                const channelId = feed?.channelId;
                const tenantKey = feed?.tenantKey?.toLowerCase();

                getPosts({
                    tenantKey,
                    channelId,
                    portal: portal?.id,
                    lastPostId,
                    lastChangesId
                }).then((response) => {
                    setPostList((prevList) =>
                        response.posts.reduce(
                            (prev, curr) => ({ ...prev, [curr.id]: curr }),
                            prevList
                        )
                    );
                    response.lastChangesId && setLastChangesId(response.lastChangesId);
                    setLastPostId(null);
                    setBusyLoading(false);
                });
            }
        },
        [feed, lastChangesId, portal?.id]
    );

    useEffect(() => {
        updateChannel({ lastPostId });
        const pollNews = setInterval(() => {
            updateChannel({ lastPostId: null });
        }, 20000);

        return () => {
            clearInterval(pollNews);
        };
    }, [updateChannel, lastPostId, feed]);

    const reloadArticleList = () => updateChannel({ lastPostId: null });

    const importArticle = async (postData, publish) => {
        setBusyImporting(true);
        try {
            const newPost = await createPostFromArticle({
                livecenterPost: postData,
                portalId: portal.id,
                publish
            });
            reloadArticleList();
            loadPostList({
                page: 1,
                published: publish
            });
            onClose && onClose();
            navigate(`/portals/${portal.id}?postId=${newPost.id}`);
            publish && Toast.success("Meldingen ble publisert");
        } catch (e) {
            console.error(e);
            Toast.error("Feilet å hente ut innhold fra Livecenter artikkelen for redigering");
        }
        setBusyImporting(false);
    };

    const onChangeFeed = (slug) => {
        setPostList({});
        const feed = feedList.docs.find((f) => f.slug === slug);
        setFeed(feed);
        setLastPostId(null);
        setLastChangesId(null);
    };

    const parseImage = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const ncpostContent = doc.querySelector("ncpost-content");
        if (ncpostContent && ncpostContent.getAttribute("data-type") === "BILDE") {
            return {
                url: ncpostContent.getAttribute("data-src"),
                caption: ncpostContent.getAttribute("data-caption"),
                source: ncpostContent.getAttribute("data-source"),
                width: ncpostContent.getAttribute("data-width"),
                height: ncpostContent.getAttribute("data-height")
            };
        }
        return null;
    };

    return (
        <React.Fragment>
            <CardContent>
                <Row>
                    <Col span={24}>
                        {feed?.slug && feedList?.docs && (
                            <CardContent border="top">
                                <Select value={feed.slug} onChange={onChangeFeed}>
                                    {feedList.docs.map((feedItem, i) => (
                                        <Option key={i} value={feedItem.slug}>
                                            {feedItem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </CardContent>
                        )}
                    </Col>
                    <Col span={24}>
                        <AutomatePublication portal={portal} feed={feed} />
                    </Col>
                </Row>
            </CardContent>
            {Object.keys(postList).length > 0 &&
                Object.values(postList)
                    .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                    .map((post, i) => {
                        const image = parseImage(post.content.html);
                        post.content.image = image;
                        return (
                            <CardContent border="top" key={post.id}>
                                <Row>
                                    <Col span={24}>
                                        <ArticlePreview
                                            article={post}
                                            published={post.created * 1000}
                                            image={image}
                                            text={
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: post.content.html
                                                    }}
                                                />
                                            }
                                            fade
                                            info={post?.authorName}
                                            setDetailedArticle={setDetailedNewsItem}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <SourceControls
                                            newsItem={post}
                                            importArticle={importArticle}
                                            onClose={onClose}
                                            reloadArticleList={reloadArticleList}
                                        />
                                    </Col>
                                </Row>
                            </CardContent>
                        );
                    })}

            {/* Load more */}
            <CardContent>
                <Button
                    fill
                    color="transparent"
                    icon="arrow-down"
                    iconColor="primary"
                    busy={busyLoading}
                    onClick={() => setLastPostId(Object.keys(postList).at(-1))}
                >
                    Last inn flere
                </Button>
            </CardContent>
            <ArticlePopup
                detailedArticle={
                    detailedNewsItem
                        ? {
                              title: detailedNewsItem.title,
                              content: detailedNewsItem.content.html,
                              image: detailedNewsItem.content.image
                          }
                        : null
                }
                setDetailedArticle={setDetailedNewsItem}
            />
            {/* Importing */}
            {busyImporting && <PopupLoader label="Importerer Livecenter-artikkel..." />}
        </React.Fragment>
    );
};
