import { useEffect, useRef } from "react";

const useDebounce = (callback, values, {delay = 500, runOnMount = true}) => {
    const firstRun = useRef(true);
    const callbackTimeout = useRef();

    useEffect(() => {
        if(firstRun.current){
            firstRun.current = false;
            if (!runOnMount) return;
        }

        if (callback) {
            clearTimeout(callbackTimeout.current);
            callbackTimeout.current = setTimeout(() => {
                callback();
            }, delay || 500);
        }

        return () => {
            clearTimeout(callbackTimeout.current);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, values);
};

export default useDebounce;
