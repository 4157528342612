import { Input, Col, Row, Spinner, Text } from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { PostEditContext } from "../../../contexts/PostEditContext";

const Base = styled.div`
    position: relative;
`;

const Wrapper = styled.div`
    .twitter-tweet {
        margin: 0 !important;
    }

    iframe {
        width: auto !important;
    }
`;

export const TwitterContent = ({ entry, onChange }) => {
    const { addContentError, removeContentError, contentErrors } = useContext(PostEditContext);
    const [tweetId, setTweetId] = useState(entry?.data?.tweetId || "");
    const error = contentErrors[entry._tempId || entry.id];

    const handleChange = (value) => {
        removeContentError(entry);

        setTweetId(value);

        if (!value) {
            onChange({ data: null });
        }
    };

    const handleTweetLoaded = (element) => {
        if (element) {
            if (tweetId !== entry?.data?.tweetId) {
                onChange({
                    data: { tweetId }
                });
            }
        } else {
            addContentError({ entry, message: "Kunne ikke hente Tweet" });
        }
    };

    return (
        <Base>
            <Row gutter={["tiny", "tiny"]}>
                {tweetId && (
                    <Col span="24">
                        <Wrapper>
                            <TwitterTweetEmbed
                                key={tweetId}
                                placeholder={<Spinner />}
                                tweetId={tweetId}
                                onLoad={handleTweetLoaded}
                            />
                        </Wrapper>
                    </Col>
                )}
                <Col span={24}>
                    <Input
                        value={tweetId}
                        type="number"
                        error={!!error}
                        size="small"
                        icon="twitter"
                        min={0}
                        placeholder="Tweet-ID (f.eks 1673379530700996619)"
                        onChange={handleChange}
                    />
                    {error ? <Text color="error">{error}</Text> : null}
                </Col>
            </Row>
        </Base>
    );
};
