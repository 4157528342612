import React, { useState, useEffect, useContext } from "react";
import { Col, Image, ImageEditor, ResponsiveImage, Row, Text } from "@tv2no/ui-react";
import { MediaSearchPopup } from "../MediaSearchPopup";
import { randomString, stripHtml } from "../../../utils/stringUtils";
import useDebounce from "../../../hooks/useDebounce";
import { UploadContext } from "../../../contexts/UploadContext";
import { handleImageClick } from "./helpers";

export const FileDataEntry = ({
    fileData,
    onChange,
    disableCaption,
    disableAttribution,
    editMode,
    setEditMode
}) => {
    const { uploadMap } = useContext(UploadContext);
    const [ref, setRef] = useState(fileData?._ref || randomString(10));
    const [src, setSrc] = useState(null);
    const [caption, setCaption] = useState(fileData?.caption || "");
    const [attribution, setAttribution] = useState(fileData?.attribution || "");
    const [showBrowser, setShowBrowser] = useState(false);
    const [focalPoint, setFocalPoint] = useState(
        fileData?.focalPoint?.x
            ? {
                  x: fileData.focalPoint?.x,
                  y: fileData.focalPoint?.y
              }
            : null
    );

    useEffect(() => {
        setSrc(fileData?._previewData || fileData?.url || null);
        if (!!fileData?._ref && fileData?._ref !== ref) {
            setCaption(fileData?.caption || "");
            setAttribution(fileData?.attribution || "");
            setRef(fileData?._ref || randomString(10));
            setFocalPoint(fileData?.focalPoint ?? null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileData]);

    const editImage = (file) => {
        handleImageClick({
            file,
            focalPoint,
            attribution: file.attribution ?? fileData?.attribution,
            caption: file?.caption ?? fileData?.caption,
            onClose: () => setShowBrowser(false),
            onImageClicked: onChange
        });
    };

    useDebounce(
        () => {
            onChange({
                ...fileData,
                attribution,
                focalPoint,
                caption,
                _ref: ref,
                _meta: true
            });
        },
        [caption, attribution],
        { delay: 300, runOnMount: false }
    );

    return (
        <React.Fragment>
            <Row gutter={["tiny", "tiny"]}>
                <Col span={24}>
                    {editMode ? (
                        <ImageEditor
                            url={src}
                            focusPoint={focalPoint ?? fileData?.focalPoint}
                            onFocusPointChange={(point) => setFocalPoint(point)}
                            onSave={(file) => {
                                editImage(file);
                                setEditMode(false);
                            }}
                            onClose={() => setEditMode(false)}
                        />
                    ) : (
                        React.createElement(fileData?.id ? ResponsiveImage : Image, {
                            src: src,
                            scale: !src ? "16:9" : null,
                            onClick: () => setShowBrowser(true),
                            onFileSelect: (file) => {
                                setFocalPoint(null);
                                handleImageClick({
                                    file,
                                    attribution: file?.attribution,
                                    caption: file?.caption,
                                    onClose: () => setShowBrowser(false),
                                    onImageClicked: onChange
                                });
                            },
                            percent: uploadMap[ref]?.progress,
                            busy: uploadMap[ref]?.processing,
                            placeholderText: "Trykk for å velge bilde"
                        })
                    )}
                </Col>

                {src && (!disableCaption || !disableAttribution) && (
                    <Col span={24}>
                        {!disableCaption && (
                            <span style={{ marginRight: "0.8rem" }}>
                                <Text
                                    size="small"
                                    value={caption}
                                    opacity={50}
                                    placeholder="Skriv en bildetekst her..."
                                    onChange={(val) => setCaption(stripHtml(val))}
                                />
                            </span>
                        )}

                        {!disableAttribution && (
                            <React.Fragment>
                                <span
                                    style={{ marginRight: "0.3rem", verticalAlign: "text-bottom" }}
                                >
                                    <Text size="small" opacity={50} strikeThrough={!attribution}>
                                        Foto:
                                    </Text>
                                </span>

                                <Text
                                    size="small"
                                    value={attribution}
                                    opacity={50}
                                    placeholder="Ola Nordmann"
                                    onChange={(val) => setAttribution(stripHtml(val))}
                                />
                            </React.Fragment>
                        )}
                    </Col>
                )}
            </Row>

            {showBrowser && (
                <MediaSearchPopup
                    onClose={() => {
                        setShowBrowser(false);
                    }}
                    onImageClicked={(file) => onChange(file)}
                />
            )}
        </React.Fragment>
    );
};

FileDataEntry.defaultProps = {
    disableCaption: false,
    disableAttribution: false
};
