import React, { useState } from "react";
import { CardContent, Row, Col, PopOver, Menu, MenuItem, Button } from "@tv2no/ui-react";
import { MediaSearchPopup } from "../../../media/MediaSearchPopup";
import useKeyPress from "../../../../hooks/useKeyPress";
import { VideoSearchPopup } from "../../../portal/VideoSearchPopup";
import { POST_CONTENT_TYPES } from "../types";

export const PostContentAdder = ({ onAddContent }) => {
    const [showAddContent, setShowAddContent] = useState(false);
    const [showMediaBrowser, setShowMediaBrowser] = useState(false);
    const [showVideoBrowser, setShowVideoBrowser] = useState(false);

    useKeyPress({
        key: "m",
        action: () => setShowMediaBrowser(true)
    });
    useKeyPress({
        key: "t",
        action: () => onAddContent("MARKUP")
    });

    const handleAddType = (type) => {
        setShowAddContent(false);
        switch (type) {
            case "PICTURES":
                setShowMediaBrowser(true);
                break;
            case "VIDEO":
                setShowVideoBrowser(true);
                break;
            default:
                onAddContent(type);
        }
    };

    return (
        <CardContent>
            <Row>
                <Col span={24} align={["center", "center"]}>
                    <PopOver
                        visible={showAddContent}
                        onClose={() => setShowAddContent(false)}
                        padding="regular"
                        content={
                            <Menu>
                                {Object.entries(POST_CONTENT_TYPES).map(
                                    ([type, { icon, label }]) => (
                                        <MenuItem
                                            key={type}
                                            icon={icon}
                                            label={label}
                                            onClick={() => handleAddType(type)}
                                        />
                                    )
                                )}
                            </Menu>
                        }
                    >
                        <Button
                            pill
                            size="small"
                            icon="plus-small"
                            iconColor="primary"
                            onClick={() => setShowAddContent(!showAddContent)}
                        >
                            Legg til...
                        </Button>
                    </PopOver>
                </Col>
            </Row>

            {/* PROMPT: Media browser */}
            {showMediaBrowser && (
                <MediaSearchPopup
                    onClose={() => {
                        setShowMediaBrowser(false);
                    }}
                    onImageClicked={(fileData) =>
                        setShowMediaBrowser(false) & onAddContent("PICTURES", { files: [fileData] })
                    }
                />
            )}

            {/* PROMPT: Video browser */}
            {showVideoBrowser && (
                <VideoSearchPopup
                    onClose={() => setShowVideoBrowser(false)}
                    onAssetClick={(data) =>
                        setShowVideoBrowser(false) & onAddContent("VIDEO", { data })
                    }
                    defaultTab="SEARCH"
                />
            )}
        </CardContent>
    );
};
