import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Col,
    Input,
    Row,
    Spinner,
    Text
} from "@tv2no/ui-react";
import React, { useContext } from "react";
import { PostListEntry } from ".";
import { PostEditContext } from "../../../contexts/PostEditContext/PostEditContext";
import { PostListContext } from "../../../contexts/PostListContext";
import { SearchResults } from "./search/SearchResults";

export const PostList = ({ hideSourceList }) => {
    const {
        data: postList,
        query: listQuery,
        loading,
        loadPostList,
        searchResults,
        loadAdditionalSearchResults,
        hasMoreSearchResults,
        SEARCH_QUERY_LENGTH_THRESHOLD,
        SEARCH_QUERY_MAX_LENGTH
    } = useContext(PostListContext);
    const { selectPost, closePost, post, query: postQuery } = useContext(PostEditContext);

    const handleClickEntry = (postEntry) => {
        hideSourceList();
        if (post.id === postEntry.id) {
            closePost();
        } else {
            selectPost(postEntry.id);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col span={24}>
                    {/* Heading */}
                    <Row gutter={["small", "small"]}>
                        <Col xs={24} md={12} align={["left", "center"]}>
                            <Text size="large" weight="bold">
                                Alle meldinger
                            </Text>
                        </Col>
                        <Col xs={24} md={12}>
                            <ButtonGroup fill>
                                <Button
                                    size="small"
                                    active={!listQuery.published}
                                    color={!listQuery.published ? "primary" : "silent"}
                                    onClick={() =>
                                        loadPostList({
                                            page: 1,
                                            published: false
                                        })
                                    }
                                >
                                    Upubliserte
                                </Button>
                                <Button
                                    size="small"
                                    active={listQuery.published}
                                    color={listQuery.published ? "primary" : "silent"}
                                    onClick={() =>
                                        loadPostList({
                                            page: 1,
                                            published: true
                                        })
                                    }
                                >
                                    Publiserte
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col span={24}>
                            <Input
                                placeholder="Søk etter melding"
                                value={listQuery.search}
                                maxLength={SEARCH_QUERY_MAX_LENGTH}
                                onChange={(input) => loadPostList({ search: input, page: 1 })}
                            />
                        </Col>
                    </Row>
                </Col>

                {listQuery?.search && (
                    <Col span="24">
                        <SearchResults
                            searchResults={searchResults}
                            loading={loading}
                            listQuery={listQuery}
                            queryLengthThreshold={SEARCH_QUERY_LENGTH_THRESHOLD}
                            hasMoreSearchResults={hasMoreSearchResults}
                            onEntryClick={(post) => handleClickEntry(post)}
                            onLoadMoreClick={() => loadAdditionalSearchResults()}
                        />
                    </Col>
                )}

                {!listQuery.search && (
                    <Col span={24}>
                        <Row gutter={["small", "small"]}>
                            {postList?.docs?.map((p) => (
                                <Col span={24} key={p.id}>
                                    <PostListEntry
                                        entry={p}
                                        selected={postQuery.postId === p.id}
                                        onClick={() => handleClickEntry(p)}
                                    />
                                </Col>
                            ))}

                            {/* Empty list */}
                            {loading ? (
                                <Col span={24}>
                                    <Card>
                                        <CardContent background="muted">
                                            <Spinner />
                                        </CardContent>
                                    </Card>
                                </Col>
                            ) : postList?.totalDocs === 0 ? (
                                <Col span={24}>
                                    <Card>
                                        <CardContent background="muted">
                                            <Text opacity={40}>Ingen nyhetsmeldinger</Text>
                                        </CardContent>
                                    </Card>
                                </Col>
                            ) : null}

                            {/* Load more */}
                            {postList?.hasNextPage && !loading ? (
                                <Col span={24} align={["center"]}>
                                    <Button
                                        fill
                                        color="transparent"
                                        icon="arrow-down"
                                        iconColor="primary"
                                        onClick={() =>
                                            loadPostList({
                                                page: postList.page + 1
                                            })
                                        }
                                    >
                                        Last inn eldre
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
};
