import styled from "styled-components";
import dayjs from "dayjs";
import {
    Button,
    ButtonRow,
    Col,
    ConfirmActionButton,
    FormItem,
    Input,
    Popup,
    PopupContent,
    PopupFooter,
    PopupTitle,
    Row,
    SingleDatePicker,
    Textarea,
    TimePicker
} from "@tv2no/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { PostEditContext } from "../../../../contexts/PostEditContext";
import { formatTitle } from "../../../../utils/stringUtils";

export const PromptShortMessage = ({ onClose }) => {
    const { post, changePost } = useContext(PostEditContext);
    const [short, setShort] = useState({
        title: post?.short?.title || post?.title,
        message:
            post?.short?.message ||
            formatTitle(post?.content?.find((content) => content.type === "MARKUP")?.data),
        expirationTime: post?.short?.expirationTime
            ? new Date(post?.short?.expirationTime)
            : new Date(Date.now() + 12 * 60 * 60 * 1000)
    });
    const [validationError, setValidationError] = useState({ title: false, message: false });

    useEffect(() => {
        setValidationError({
            title: short.title?.trim().length > 100 || short.title?.trim().length === 0,
            message: short.message?.trim().length > 180 || short.message?.trim().length === 0,
            expirationTime: dayjs().isAfter(short.expirationTime)
        });
    }, [short]);

    const handleChangeValue = (key, value) => {
        setShort({ ...short, [key]: value });
    };

    const handleSaveShort = () => {
        changePost({ short });
        onClose();
    };

    const deleteShort = () => {
        changePost({ short: null });
        onClose();
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle>{post?.short ? "Oppdater" : "Opprett"} short</PopupTitle>
            <PopupContent>
                <Row spacing="large" gutter={["small", "small"]}>
                    <Col span={24}>
                        <FormItem label={"Tittel"}>
                            <Input
                                placeholder="Tittel for short"
                                value={short.title}
                                error={validationError.title}
                                onChange={(val) => {
                                    handleChangeValue("title", formatTitle(val));
                                }}
                            />
                            <Counter
                                length={short.title.length}
                                maxLength={100}
                                error={validationError.title}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={"Melding"}>
                            <Textarea
                                placeholder="Tekst i short"
                                rows={10}
                                value={short.message}
                                error={validationError.message}
                                onChange={(val) => {
                                    handleChangeValue("message", formatTitle(val));
                                }}
                            />
                            <Counter
                                length={short.message.length}
                                maxLength={180}
                                error={validationError.message}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Sett utløpstidspunkt"}
                            info="Shorts utgår som standard etter en uke. Endre utløpstidspunkt dersom short ikke er relevant etter et spesifikt tidspunkt. Eks. short for fotballkamp kl 19."
                        >
                            <Row>
                                <Col span={12}>
                                    <SingleDatePicker
                                        value={short.expirationTime}
                                        onChange={(val) =>
                                            handleChangeValue("expirationTime", new Date(val))
                                        }
                                        isDayBlocked={(date) =>
                                            dayjs(date).isBefore(
                                                dayjs().set("hour", 0).set("minute", 0)
                                            )
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <TimePicker
                                        value={short.expirationTime}
                                        onChange={(val) =>
                                            handleChangeValue("expirationTime", new Date(val))
                                        }
                                        error={validationError.expirationTime}
                                    />
                                </Col>
                            </Row>
                        </FormItem>
                    </Col>
                </Row>

                {!!post.short && (
                    <ConfirmActionButton
                        label="Slett"
                        question="Er du sikker på at du vil slette short?"
                        onConfirm={deleteShort}
                    ></ConfirmActionButton>
                )}
            </PopupContent>
            <PopupFooter>
                <ButtonRow>
                    <Button
                        color="primary"
                        onClick={handleSaveShort}
                        disabled={
                            validationError.title ||
                            validationError.message ||
                            validationError.expirationTime
                        }
                    >
                        {post?.short ? "Oppdater" : "Opprett"} short
                    </Button>
                    <Button onClick={onClose}>Avbryt</Button>
                </ButtonRow>
            </PopupFooter>
        </Popup>
    );
};

const CounterWrapper = styled.div`
    background-color: ${(props) =>
        props.$error ? "rgba(var(--color_error), 0.03)" : "rgba(var(--color_base), 0.03)"};
    color: ${(props) =>
        props.$error ? "rgba(var(--color_error), 1)" : "rgba(var(--color_base), 0.5)"};
    padding: 0 6px;
    text-align: right;

    span {
        color: ${(props) =>
            props.$error ? "rgba(var(--color_error), 1)" : "rgba(var(--color_base), 1)"};
        font-weight: bold;
    }
`;

const Counter = ({ length = 0, maxLength = 0, error }) => {
    return (
        <CounterWrapper $error={error}>
            <span>{length}</span> / {maxLength}
        </CounterWrapper>
    );
};
