import { randomString } from "../../../utils/stringUtils";

export const handleImageClick = ({
    file,
    caption,
    attribution,
    focalPoint,
    onClose,
    onImageClicked
}) => {
    let fileData = {
        caption,
        attribution,
        focalPoint,
        _ref: randomString(10),
        _meta: true
    };

    // Convert to dataURL before adding
    if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
            const result = event.target.result;
            fileData = {
                ...fileData,
                _fileObject: file,
                _previewData: result
            };
            onClose();
            onImageClicked(fileData);
        });
        reader.readAsDataURL(file);
    } else {
        onImageClicked(fileData);
        onClose();
    }
};
