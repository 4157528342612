import React from "react";
import { MarkupEditor } from "../../editor/MarkupEditor";

export const MarkupContent = ({ entry, onChange }) => {
    return (
        <MarkupEditor
            placeholder="Begynn å skrive brødtekst her..."
            value={entry?.data}
            replaceCharacters={["double-dash", "quotation-marks"]}
            onChange={(val) =>
                onChange({
                    data: val
                })
            }
        />
    );
};
