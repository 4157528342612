import {
    Card,
    CardContent,
    CardTitle,
    Col,
    ConfirmActionButton,
    Row,
    Toast
} from "@tv2no/ui-react";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalAppContext } from "../../../../contexts/GlobalAppContext";
import { deletePortal } from "../../../../data/portal/portal";

export const Delete = ({ portal }) => {
    const navigate = useNavigate();
    const { reloadPortals } = useContext(GlobalAppContext);
    const [busyDeleting, setBusyDeleting] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleDelete = async () => {
        setBusyDeleting(true);
        await deletePortal(portal.id).then(() => {
            Toast.success("Portalen ble slettet");
            reloadPortals();
            navigate("/portals");
        });
        setBusyDeleting(false);
    };

    return (
        <Card>
            <CardTitle
                title="Slett"
                actions={[
                    {
                        icon: expanded ? "caret-up" : "caret-down",
                        onClick: () => setExpanded(!expanded)
                    }
                ]}
            />
            {expanded && (
                <CardContent>
                    <Row gutter={["small", "small"]}>
                        <Col md={12}>
                            <ConfirmActionButton
                                popup
                                fill={false}
                                busy={busyDeleting}
                                label="Slett portalen"
                                question="Er du sikker på at du vil slette denne portalen?"
                                onConfirm={handleDelete}
                            />
                        </Col>
                        <Col md={12}>
                            En portal skal kun slettes hvis den er opprettet ved et uhell og ikke er
                            tatt- eller skal tas i bruk. En portal som ikke lenger er aktiv skal
                            arkiveres.
                        </Col>
                    </Row>
                </CardContent>
            )}
        </Card>
    );
};
