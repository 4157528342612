import { Card, TabPane, Tabs } from "@tv2no/ui-react";
import React, { useState } from "react";
import { LabradorSource } from "../../../../../../components/sources/LabradorSource";
import { NtbSource } from "../../../../../../components/sources/NtbSource";
import { LiveFeedSource } from "../../../../../../components/sources/LiveFeedSource";

export const SourceList = ({ onClose }) => {
    const [source, setSource] = useState("NTB");

    return (
        <Card>
            <Tabs activeKey={source} onChange={setSource}>
                <TabPane tabKey="NTB" label="NTB">
                    <NtbSource onClose={onClose} />
                </TabPane>
                <TabPane tabKey="LABRADOR" label="Labrador">
                    <LabradorSource onClose={onClose} />
                </TabPane>
                <TabPane tabKey="LIVEFEED" label="Live-Feed">
                    <LiveFeedSource onClose={onClose} />
                </TabPane>
            </Tabs>
        </Card>
    );
};
