import axios from "axios";

const client = axios.create();

export const getStillsImages = (search = "", page = 1, limit = 50, signal = null) =>
    client.get(
        `https://contentbox.tv2.no/v2/bynder/?q=${search}&action=search&start=${
            limit * (page - 1)
        }&limit=${limit}`,
        {
            signal
        }
    );

export const downloadStillsImage = (id) =>
    client
        .get(`https://contentbox.tv2.no/v2/bynder/?action=download&id=${id}`, {
            responseType: "blob"
        })
        .then((response) => new File([response.data], `${id}.jpg`, { type: response.data.type }));
