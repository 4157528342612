import { MarkupEditor as TV2MarkupEditor } from "@tv2no/ui-react";
import React from "react";
import styled from "styled-components";

const Base = styled.div`
    position: relative;
    font-size: 16px;

    h3 {
        font-family: var(--fontFamily_primary);
        font-size: 18px;
        margin: 0 0 1em 0;
    }
`;

export const MarkupEditor = (props) => {
    return (
        <Base>
            <TV2MarkupEditor {...props} />
        </Base>
    );
};

MarkupEditor.defaultProps = {
    toolbarTrigger: "selection",
    silent: true,
    actions: [
        [
            "H3",
            "link",
            "bold",
            "italic",
            "underline",
            "unordered-list",
            "ordered-list",
            "insert-symbol"
        ]
    ]
};
