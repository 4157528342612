import React, { useState } from "react";
import { fetch } from "../data/fetch";
import axios from "axios";
import { getResizedUrl } from "../utils/urlUtils";
export const UploadContext = React.createContext({});

const client = axios.create();

export const UploadContextProvider = ({ children }) => {
    const [uploadMap, setUploadMap] = useState({});

    const updateUploadEntry = (ref, changes) => {
        setUploadMap((map) => {
            return {
                ...map,
                [ref]: {
                    ...map[ref],
                    ...changes
                }
            };
        });
    };

    const handleSaveFileData = async ({ fileData, endpoint }) => {
        const { _ref, _meta, _fileObject, caption, attribution, focalPoint } = fileData;

        // Check if changes
        if (!_ref || (!_meta && !_fileObject)) {
            return null;
        }

        // Upload entry
        updateUploadEntry(_ref, {
            processing: true
        });

        // Parameters
        const parameters = {
            ...(_meta && {
                caption,
                attribution,
                focalPoint
            }),
            ...(_fileObject && {
                contentType: _fileObject.type,
                filename: _fileObject.name,
                fileSize: _fileObject.size
            })
        };

        // Make request
        return await fetch
            .post(endpoint, parameters)
            .then(async (response) => {
                if (_fileObject) {
                    // Get upload URL
                    const uploadUrl = response.headers.location;
                    if (!uploadUrl) {
                        console.warn("Request did not return an upload URL.", endpoint);
                        return updateUploadEntry(_ref, {
                            processing: false,
                            error: "Request did not return an upload URL."
                        });
                    }

                    await uploadFile(uploadUrl, _fileObject, _ref);
                } else if (_meta) {
                    updateUploadEntry(_ref, {
                        processing: false
                    });
                }

                return response;
            })
            .then((response) => {
                // Prerender image for Social media
                if (response.data.contentType === "image/jpeg") {
                    client.get(getResizedUrl(response.data, "1500x0"));
                }
                return response;
            })
            .catch(function (error) {
                updateUploadEntry(_ref, {
                    processing: false,
                    error
                });
            });
    };

    const uploadFile = async (uploadUrl, fileObject, ref) => {
        await client
            .put(uploadUrl, fileObject, {
                timeout: 1000 * 60 * 15, // 15 minutes
                headers: {
                    "Content-Type": fileObject.type
                },
                onUploadProgress: function (progressEvent) {
                    updateUploadEntry(ref, {
                        progress: Math.round((progressEvent.loaded / progressEvent.total) * 100)
                    });
                }
            })
            .then(function () {
                updateUploadEntry(ref, {
                    processing: false,
                    progress: null
                });
            })
            .catch(function (error) {
                updateUploadEntry(ref, {
                    processing: false,
                    progress: null,
                    error
                });
            });
    };

    return (
        <UploadContext.Provider
            value={{
                uploadMap,
                createOrUpdateFileData: handleSaveFileData
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};
