import { Button, Page, PageHeader, PageLoader } from "@tv2no/ui-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { NavButton, PageNav } from "../../../components/page/PageNav";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";
import { getPortalById } from "../../../data/portal/portal";
import { Settings } from "../settings/Settings";
import { Messages } from "./sections/Messages/Messages";
import { Video } from "./sections/Video/Video";
import { Layout } from "./sections/Layout/Layout";

export const PortalEntryContext = createContext({});

export const PortalEntry = () => {
    const { portalId } = useParams();
    const [portal, setPortal] = useState(null);
    const { setPageTitle } = useContext(GlobalAppContext);
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
        getPortalById(portalId).then(setPortal).catch(setError);
    }, [portalId, setPortal]);

    useEffect(() => {
        setPageTitle(portal?.name);
        return () => {
            setPageTitle(null);
        };
    }, [portal, setPageTitle]);

    return (
        <PortalEntryContext.Provider value={{ portal, setPortal }}>
            {error ? (
                <Page>
                    <PageHeader title="Finner ikke portal" icon="forbidden" />
                    <Button icon="arrow-left" iconColor="primary" link="/portals">
                        Tilbake til portaler
                    </Button>
                </Page>
            ) : !portal ? (
                <PageLoader />
            ) : (
                <React.Fragment>
                    <Page>
                        <PageNav>
                            <NavButton
                                icon="align-left"
                                label="Meldinger"
                                link={`/portals/${portal.id}`}
                                exact
                            />
                            <NavButton
                                icon="video"
                                label="Video"
                                link={`/portals/${portal.id}/video`}
                            />
                            <NavButton
                                icon="structure"
                                label="Layout"
                                link={`/portals/${portal.id}/layout`}
                            />
                            <NavButton
                                icon="gear"
                                label="Innstillinger"
                                link={`/portals/${portal.id}/settings`}
                            />
                        </PageNav>
                        <Routes>
                            <Route path="/" element={<Messages />} />
                            <Route path="/video" element={<Video />} />
                            <Route path="/layout" element={<Layout />} />
                            <Route path="/settings" element={<Settings />} />
                        </Routes>
                    </Page>
                </React.Fragment>
            )}
        </PortalEntryContext.Provider>
    );
};
