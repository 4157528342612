import React from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import "@tv2no/ui-react/dist/styles.css";
import { setHeader } from "./data/fetch";
import { App } from "./routes/base/App";
import config from "./config";
import { AppContainer, AuthProvider, AuthRestrict } from "@tv2no/ui-react";
import { GlobalAppContextProvider } from "./contexts/GlobalAppContext";

const Root = () => {
    const handleLogin = (token, tokenData) => {
        // Update fetch header with auth token
        setHeader("Authorization", `Bearer ${token}`);

        // Update axios header with auth token
        axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    };

    return (
        <AuthProvider onLogin={handleLogin} loginUrl={config.authLoginUrl} appId="BREAKING">
            <Routes>
                <Route
                    path="/*"
                    element={
                        <AuthRestrict>
                            <AppContainer>
                                <GlobalAppContextProvider>
                                    <App />
                                </GlobalAppContextProvider>
                            </AppContainer>
                        </AuthRestrict>
                    }
                />
            </Routes>
        </AuthProvider>
    );
};

export default Root;
