import {
    Button,
    Card,
    CardContent,
    CardTitle,
    Col,
    Form,
    FormItem,
    Option,
    Row,
    Select,
    Toast
} from "@tv2no/ui-react";
import React, { useEffect, useState } from "react";
import { POST_CATEGORIES } from "../../../../constants";
import { updatePortal } from "../../../../data/portal/portal";

export const Appearance = ({ onUpdatePortal, ...props }) => {
    const [portal, setPortal] = useState(props.portal);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [busySaving, setBusySaving] = useState(false);

    useEffect(() => {
        setPortal(props.portal);
        setUnsavedChanges(false);
    }, [props.portal]);

    const handleSave = async () => {
        setBusySaving(true);

        updatePortal(portal.id, {
            theme: portal.theme,
            kicker: portal.kicker
        })
            .then((response) => {
                onUpdatePortal(response);
                setUnsavedChanges(false);
                setBusySaving(false);
                Toast.success("Portalen ble lagret");
            })
            .catch((err) => {
                setBusySaving(false);
                Toast.error(err);
            });
    };

    const handleChangeProperty = (key, value) => {
        setUnsavedChanges(true);
        setPortal({
            ...portal,
            [key]: value
        });
    };

    return (
        <React.Fragment>
            <Card>
                <CardTitle title="Utseende" />
                <CardContent>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            label="Fargetema"
                                            info="Krise er kun for større kriser som ukrainakrigen. Sorg er kun for portaler som omfatter en persons (eks. kongens) bortgang."
                                        >
                                            <Select
                                                value={
                                                    portal.theme ? portal.theme : portal.category
                                                }
                                                onChange={(val) =>
                                                    handleChangeProperty("theme", val)
                                                }
                                            >
                                                {POST_CATEGORIES[portal.category].themes.map(
                                                    (theme) => (
                                                        <Option key={theme.key} value={theme.key}>
                                                            {theme.label}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </CardContent>
                <CardContent border="top">
                    <Button
                        color="primary"
                        disabled={!unsavedChanges}
                        onClick={handleSave}
                        busy={busySaving}
                    >
                        Lagre endringene
                    </Button>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};
