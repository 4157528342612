import { Image, Popup, PopupContent, PopupTitle, Text } from "@tv2no/ui-react";
import React from "react";
import styled from "styled-components";

const StyledDetailedTextContent = styled.div`
    margin: 12px 0;
`;

export const ArticlePopup = ({ detailedArticle, setDetailedArticle }) => {
    return (
        <Popup visible={!!detailedArticle} size="medium" onClose={() => setDetailedArticle(null)}>
            <PopupTitle>{detailedArticle?.title}</PopupTitle>
            <PopupContent>
                {detailedArticle?.image?.url && (
                    <>
                        <Image src={detailedArticle.image.url} />
                        <Text size="small" opacity={50}>
                            {detailedArticle.image?.caption ?? ""}
                        </Text>
                    </>
                )}
                <StyledDetailedTextContent
                    dangerouslySetInnerHTML={{
                        __html: detailedArticle?.bodytext || detailedArticle?.content
                    }}
                ></StyledDetailedTextContent>
            </PopupContent>
        </Popup>
    );
};
