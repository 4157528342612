import {
    Badge,
    Button,
    ButtonRow,
    Callout,
    Col,
    FormItem,
    Input,
    Popup,
    PopupContent,
    PopupFooter,
    PopupTitle,
    Row,
    Textarea,
    Toast,
    Select,
    Option
} from "@tv2no/ui-react";
import React, { useState } from "react";
import config from "../../../../config";
import { POST_CATEGORIES } from "../../../../constants";
import { createPushMessage } from "../../../../data/post/push";
import { getPushUrl } from "../../../../utils/urlUtils";

export const PromptPushMessage = ({ portal, post, onClose, onComplete }) => {
    const [busySending, setBusySending] = useState(false);
    const [errorSending, setErrorSending] = useState(false);
    const [pushMessage, setPushMessage] = useState({
        title: POST_CATEGORIES[portal.category].label + ":",
        message: post.title,
        postId: post.id,
        category: portal.category,
        url: getPushUrl({ portal, post })
    });

    const onSendPush = async () => {
        setErrorSending(false);
        setBusySending(true);
        createPushMessage(pushMessage)
            .then(() => {
                Toast.success("Pushmelding er sendt!");
                onComplete();
            })
            .catch(() => {
                setBusySending(false);
                setErrorSending(true);
            });
    };

    const setCategory = (category) =>
        setPushMessage((prev) => ({
            ...prev,
            title: POST_CATEGORIES[category].label + ":",
            category
        }));

    return (
        <Popup onClose={onClose}>
            <PopupTitle description="Send ut en pushmelding til alle brukere av en mobilapp">
                Send push
            </PopupTitle>
            <PopupContent>
                <Row>
                    <Col span={24}>
                        <FormItem label="Topic">
                            <Select
                                value={pushMessage.category}
                                onChange={setCategory}
                                placeholder="Velg topic"
                            >
                                {Object.keys(POST_CATEGORIES).map((key) => (
                                    <Option key={key} value={key}>
                                        {POST_CATEGORIES[key].label}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        <FormItem label="App">
                            <Row gutter={["tiny", "tiny"]}>
                                <Col>
                                    <Badge
                                        fill
                                        customColor={POST_CATEGORIES[pushMessage.category].color}
                                    >
                                        {POST_CATEGORIES[pushMessage.category].mobileApp?.name}
                                    </Badge>
                                </Col>
                                {config.environment !== "production" ? (
                                    <Col>
                                        <Badge color="silent" fill>
                                            TEST
                                        </Badge>
                                    </Col>
                                ) : null}
                            </Row>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={"Tittel"}>
                            <Input
                                placeholder="Tittel i pushmelding"
                                value={pushMessage.title}
                                maxLength={65}
                                onChange={(val) => {
                                    setPushMessage((prev) => ({ ...prev, title: val }));
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={"Melding"}>
                            <Textarea
                                placeholder="Brødtekst i pushmelding"
                                value={pushMessage.message}
                                maxLength={178}
                                counter
                                onChange={(val) => {
                                    setPushMessage((prev) => ({ ...prev, message: val }));
                                }}
                            />
                        </FormItem>
                    </Col>
                    {errorSending ? (
                        <Col span={24}>
                            <Callout color="error">
                                Det oppstod en feil ved sending av push-meldingen.
                            </Callout>
                        </Col>
                    ) : null}
                </Row>
            </PopupContent>
            <PopupFooter>
                <ButtonRow>
                    <Button
                        color="primary"
                        icon="bell"
                        busy={busySending}
                        onClick={onSendPush}
                        disabled={
                            !pushMessage.title?.trim().length || !pushMessage.message?.trim().length
                        }
                    >
                        Send push nå
                    </Button>
                    <Button onClick={onClose}>Avbryt</Button>
                </ButtonRow>
            </PopupFooter>
        </Popup>
    );
};
