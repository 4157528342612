import React, { useState, useContext, useEffect } from "react";
import {
    Button,
    Checkbox,
    Col,
    InfiniteScroll,
    ItemList,
    PopOver,
    PopupYesNo,
    Row,
    ScrollableArea,
    Spinner,
    Text,
    Toast
} from "@tv2no/ui-react";
import { updateFeed } from "../../../data/feed/feed";
import { GlobalAppContext } from "../../../contexts/GlobalAppContext";
import { getPortalList } from "../../../data/portal/portal";
import { useIsMounted } from "../../../hooks/useIsMounted";

export const FeedPortals = ({ feed }) => {
    const [currentFeed, setCurrentFeed] = useState(feed);
    const [showSelector, setShowSelector] = useState(false);
    const [portalList, setPortalList] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const { reloadFeeds } = useContext(GlobalAppContext);
    const [confirmRemovePortal, setConfirmRemovePortal] = useState(null);
    const isMountedRef = useIsMounted();

    useEffect(() => {
        setBusyLoading(true);
        getPortalList({ page: pageNumber, limit: 10 }).then((response) => {
            if (!isMountedRef.current) {
                return;
            }
            setPortalList((list) => {
                if (response.page > 1) {
                    response.docs = list.docs.concat(response.docs);
                }
                return response;
            });
            setBusyLoading(false);
        });
    }, [pageNumber, isMountedRef]);

    const togglePortalSelection = (portal) => {
        let newAutoPortals = Array.from(new Set([...currentFeed.autoPortals.map((p) => p.id)]));

        const portalIndex = newAutoPortals?.findIndex((portalId) => portalId === portal.id);

        if (portalIndex >= 0) {
            newAutoPortals?.splice(portalIndex, 1);
        } else {
            newAutoPortals = [...newAutoPortals, portal.id];
        }

        updateFeed(currentFeed.id, {
            ...currentFeed,
            autoPortals: newAutoPortals
        })
            .then((response) => {
                setCurrentFeed(response);
                reloadFeeds();
            })
            .catch((err) => {
                Toast.error("Det oppstod en feil ved lagring");
            });
    };

    return (
        <React.Fragment>
            <Row gutter={["tiny", "tiny"]}>
                {/* Add button */}
                <Col>
                    <PopOver
                        visible={showSelector}
                        onClose={() => setShowSelector(false)}
                        padding="regular"
                        content={
                            <div style={{ height: "20rem" }}>
                                <ScrollableArea suppressScrollX>
                                    <Row>
                                        <Col span={24}>
                                            <ItemList onItemClick={togglePortalSelection}>
                                                {portalList?.docs?.map((portal, idx) => (
                                                    <ItemList.Item
                                                        key={portal.id}
                                                        index={idx}
                                                        value={portal}
                                                        prepend={
                                                            <div
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <Checkbox
                                                                    embedded
                                                                    checked={
                                                                        !!currentFeed?.autoPortals?.find(
                                                                            (autoPortal) =>
                                                                                autoPortal.id ===
                                                                                portal.id
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    >
                                                        {portal.name}
                                                    </ItemList.Item>
                                                ))}
                                            </ItemList>
                                        </Col>
                                        {busyLoading && <Spinner />}
                                    </Row>
                                    {portalList?.totalPages > 1 && portalList?.hasNextPage && (
                                        <InfiniteScroll
                                            onLoadMore={() => setPageNumber(pageNumber + 1)}
                                        />
                                    )}
                                </ScrollableArea>
                            </div>
                        }
                    >
                        <Button
                            title="Legg til portaler"
                            icon="plus"
                            iconColor="primary"
                            size="small"
                            color="silent"
                            square
                            onClick={() => setShowSelector(!showSelector)}
                        />
                    </PopOver>
                </Col>

                {/* All portals */}
                {portalList?.docs &&
                    currentFeed?.autoPortals.map((portal) => {
                        return (
                            <Col key={portal.id} shrink>
                                <Button
                                    icon="structure"
                                    color="silent"
                                    size="small"
                                    onClick={() => setConfirmRemovePortal(portal)}
                                >
                                    {portal.name}
                                </Button>
                            </Col>
                        );
                    })}

                {/* PROMPT: Confirm remove portal */}
                {confirmRemovePortal && (
                    <PopupYesNo
                        title="Deaktiver automatisk publisering?"
                        onYes={() =>
                            togglePortalSelection(confirmRemovePortal) &
                            setConfirmRemovePortal(null)
                        }
                        onNo={() => setConfirmRemovePortal(null)}
                    >
                        Er du sikker på at du vil deaktivere automatisk publisering til portalen:{" "}
                        <Text weight="medium">{confirmRemovePortal.name}</Text>?
                    </PopupYesNo>
                )}
            </Row>
        </React.Fragment>
    );
};
