const { ENV, API_URL, BASE_PATH, AUTH_APP_ID, AUTH_LOGIN_URL, AUTH_API_URL } = window._env_;

// Properties
const config = {
    environment: ENV,
    apiUrl: API_URL,
    basePath: BASE_PATH,
    authAppId: AUTH_APP_ID,
    authLoginUrl: AUTH_LOGIN_URL,
    authApiUrl: AUTH_API_URL,

    ...(ENV === "development" && {
        frontAppUrl: "https://www.stage.tv2.no/direkte",
        pushAdminUrl: "https://push-admin.stage-alpha.tv2.no",
        aiToolsUrl: "https://ai-tools-api.stage-alpha.tv2.no"
    }),

    ...(ENV === "stage" && {
        frontAppUrl: "https://www.stage.tv2.no/direkte",
        pushAdminUrl: "https://push-admin.stage-alpha.tv2.no",
        aiToolsUrl: "https://ai-tools-api.stage-alpha.tv2.no"
    }),

    ...(ENV === "production" && {
        frontAppUrl: "https://www.tv2.no/direkte",
        pushAdminUrl: "https://push-admin.alpha.tv2.no",
        aiToolsUrl: "https://ai-tools-api.alpha.tv2.no"
    })
};

export default config;
