import React from "react";
import styled from "styled-components";

const Base = styled.div`
    position: relative;
`;

export const PostContentList = ({children}) => {
    return (
        <Base>
            {children}
        </Base>
    )
}
